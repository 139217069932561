import { ObjectTS } from '@foxeet/utils/functions/javascript.functions';

export enum LicenseeUserManagerRole {
  ProcessManager = 'LicenseeUserRole_ProcessManager', //
  TechnicalManager = 'LicenseeUserRole_TechnicalManager', //
  CommercialManager = 'LicenseeUserRole_CommercialManager', //
  Appraiser = 'LicenseeUserRole_Professional', //
  CustomerBasic = 'LicenseeUserRole_CustomerBasic', //
  CustomerAdvanced = 'LicenseeUserRole_CustomerAdvanced', //
  Root = 'LicenseeUserRole_Root', //
  ExternalManager = 'LicenseeUserRole_ExternalTechnicalManager', //
}

export const ALL_MANAGER_ROLES = ObjectTS.values(LicenseeUserManagerRole);

export const authoritiesDefinition = {
  '': {
    enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
    access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
  },
  entities: {
    enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
    access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
    pages: {
      access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      list: {
        access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      },
      add: {
        access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        form: {
          showAuthomaticInvoicingCheckbox: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
      },
      detail: {
        access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        actions: {
          canEditImage: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
        profile: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          form: {
            showAuthomaticInvoicingCheckbox: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
          },
        },
        'allowed-roles': {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
        'billing-info': {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        deliverables: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        appraisals: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
        collaborator: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        users: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canAddNewUser: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canEditUsers: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canChangeUsersPassword: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
      },
    },
  },
  'account-management': {
    access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
    enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
    pages: {
      access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      detail: {
        access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        fastActionButtons: {
          canAddPaymentMethod: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
        payment: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canEditBillingData: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canDeletePaymentMethod: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canSetDefaultPaymentMethod: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        invoices: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canDownload: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        license: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
        'report-customization': {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
      },
    },
  },
  orders: {
    access: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_CustomerBasic',
      'LicenseeUserRole_CustomerAdvanced',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
    enable: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_CustomerBasic',
      'LicenseeUserRole_CustomerAdvanced',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
    'simple-notes': {
      access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      pages: {
        access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        list: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
        add: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
        detail: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          fastActions: {
            canSentToRegister: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canSetSuccessReceivedFromRegister: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            canSetDeliveredToClient: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          form: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          documents: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            add: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        create: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
      },
    },
    requests: {
      access: [
        'LicenseeUserRole_Root',
        'LicenseeUserRole_CustomerAdvanced',
        'LicenseeUserRole_ProcessManager',
        'LicenseeUserRole_TechnicalManager',
        'LicenseeUserRole_CommercialManager',
      ],
      enable: [
        'LicenseeUserRole_Root',
        'LicenseeUserRole_CustomerAdvanced',
        'LicenseeUserRole_ProcessManager',
        'LicenseeUserRole_TechnicalManager',
        'LicenseeUserRole_CommercialManager',
      ],
      pages: {
        access: [
          'LicenseeUserRole_Root',
          'LicenseeUserRole_CustomerAdvanced',
          'LicenseeUserRole_ProcessManager',
          'LicenseeUserRole_TechnicalManager',
          'LicenseeUserRole_CommercialManager',
        ],
        enable: [
          'LicenseeUserRole_Root',
          'LicenseeUserRole_CustomerAdvanced',
          'LicenseeUserRole_ProcessManager',
          'LicenseeUserRole_TechnicalManager',
          'LicenseeUserRole_CommercialManager',
        ],
        dashboard: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_CustomerAdvanced'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_CustomerAdvanced'],
          fileVolume: ['LicenseeUserRole_Root', 'LicenseeUserRole_CustomerAdvanced'],
          fileAverageTime: [],
          summaryOfUnfinishedItems: [],
          filters: {
            orderTypes: ['LicenseeUserRole_Root', 'LicenseeUserRole_CustomerAdvanced'],
            purpose: ['LicenseeUserRole_Root', 'LicenseeUserRole_CustomerAdvanced'],
            customer: [],
            supplierCompany: ['LicenseeUserRole_Root', 'LicenseeUserRole_CustomerAdvanced'],
            prescriber: [],
            portfolios: [],
            advanced: ['LicenseeUserRole_Root', 'LicenseeUserRole_CustomerAdvanced'],
            hasVinculation: [],
            vinculations: [],
          },
          clickStepper: [],
        },
        list: {
          access: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
          ],
          enable: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
          ],
          filters: {
            paymentFilters: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager'],
          },
          topButtonActions: {
            canAddRequestButton: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            canDownloadExcel: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        add: {
          access: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
          ],
          enable: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
          ],
          // COSAS
          addAsset: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            enable: [],
          },
        },
        detail: {
          access: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
          ],
          enable: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
          ],
          // COSAS
          addAsset: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            enable: [],
          },
          fastActions: {
            canConfirmExpenses: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canCancel: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canCopy: [],
            canResendEmail: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAcceptManually: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canCreateAppraisal: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAddInvoice: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canSendToClient: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          actions: {
            canAssignResolverUser: [],
            canShowResolverUser: [],
          },
          detail: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canShowCopySelector: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
              ],
              canEditBillingData: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
            externalReference: [],
            paymentOption: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_CustomerAdvanced',
            ],
            feeOption: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            incomeOutComeProvision: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            selectPrescriber: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            selectCustomer: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            addManualCustomer: ['LicenseeUserRole_CustomerAdvanced'],
            visitContactData: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_CustomerAdvanced',
            ],
            exchange: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            conditionsCheck: ['LicenseeUserRole_CustomerAdvanced'],
          },
          information: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
          },
          documents: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            actions: {
              canDownload: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
              ],
            },
          },
          'payment-history': {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
          },
          'message-board': {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            actions: {
              canAdd: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canReply: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canEdit: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canDelete: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          history: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            records: {
              access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
            communications: {
              access: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
              ],
              enable: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
              ],
            },
          },
          appraisals: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
          },
          'management-orders': {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
          },
          billing: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        massive: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          topButtonActions: {
            canAddMassiveLoadButton: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          detail: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canDownloadFile: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          add: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          list: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        communications: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
      },
    },
    'quality-control': {
      access: [
        'LicenseeUserRole_Root',
        'LicenseeUserRole_CustomerAdvanced',
        'LicenseeUserRole_ProcessManager',
        'LicenseeUserRole_TechnicalManager',
        'LicenseeUserRole_CommercialManager',
      ],
      enable: [
        'LicenseeUserRole_Root',
        'LicenseeUserRole_CustomerAdvanced',
        'LicenseeUserRole_ProcessManager',
        'LicenseeUserRole_TechnicalManager',
        'LicenseeUserRole_CommercialManager',
      ],
      pages: {
        access: [
          'LicenseeUserRole_Root',
          'LicenseeUserRole_CustomerAdvanced',
          'LicenseeUserRole_ProcessManager',
          'LicenseeUserRole_TechnicalManager',
          'LicenseeUserRole_CommercialManager',
        ],
        enable: [
          'LicenseeUserRole_Root',
          'LicenseeUserRole_CustomerAdvanced',
          'LicenseeUserRole_ProcessManager',
          'LicenseeUserRole_TechnicalManager',
          'LicenseeUserRole_CommercialManager',
        ],
        dashboard: {
          access: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          enable: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          fileVolume: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          fileAverageTime: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          summaryOfUnfinishedItems: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          filters: {
            orderTypes: [],
            purpose: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            customer: [],
            advanced: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            supplierCompany: [],
            supplierCompanyCustomerAdvanced: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            prescriber: [],
            portfolios: [],
          },
          clickStepper: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
        },
        list: {
          access: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
          ],
          enable: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
          ],
          actions: {
            canCreateOrder: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_CustomerAdvanced',
            ],
            canShowTopFilterBar: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_CustomerAdvanced',
            ],
          },
        },
        add: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          canshowPrescriber: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
        massive: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
        detail: {
          access: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
          ],
          enable: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
          ],
          actions: {
            canAssignManagerProcessUser: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAssignTechnicalUser: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAssignManagerTechnicalUser: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canShowResolverUser: ['LicenseeUserRole_Root', 'LicenseeUserRole_CustomerAdvanced'],
          },
          fastActions: {
            canCancel: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canFinishValidation: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canStartValidation: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canReject: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canMarkAsDelivered: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          detail: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          documents: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            actions: {
              canDownload: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
              ],
              canUpload: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          'registral-plat': {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canSave: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canSaveAndFinish: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
        },
      },
    },
    appraisals: {
      access: [
        'LicenseeUserRole_Root',
        'LicenseeUserRole_CustomerBasic',
        'LicenseeUserRole_CustomerAdvanced',
        'LicenseeUserRole_ProcessManager',
        'LicenseeUserRole_TechnicalManager',
        'LicenseeUserRole_CommercialManager',
        'LicenseeUserRole_ExternalTechnicalManager',
      ],
      enable: [
        'LicenseeUserRole_Root',
        'LicenseeUserRole_CustomerBasic',
        'LicenseeUserRole_CustomerAdvanced',
        'LicenseeUserRole_ProcessManager',
        'LicenseeUserRole_TechnicalManager',
        'LicenseeUserRole_CommercialManager',
        'LicenseeUserRole_ExternalTechnicalManager',
      ],
      pages: {
        access: [
          'LicenseeUserRole_Root',
          'LicenseeUserRole_CustomerBasic',
          'LicenseeUserRole_CustomerAdvanced',
          'LicenseeUserRole_ProcessManager',
          'LicenseeUserRole_TechnicalManager',
          'LicenseeUserRole_CommercialManager',
          'LicenseeUserRole_ExternalTechnicalManager',
        ],
        enable: [
          'LicenseeUserRole_Root',
          'LicenseeUserRole_CustomerBasic',
          'LicenseeUserRole_CustomerAdvanced',
          'LicenseeUserRole_ProcessManager',
          'LicenseeUserRole_TechnicalManager',
          'LicenseeUserRole_CommercialManager',
          'LicenseeUserRole_ExternalTechnicalManager',
        ],
        dashboard: {
          access: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          enable: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          fileVolume: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          fileAverageTime: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          summaryOfUnfinishedItems: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          filters: {
            orderTypes: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            purpose: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            customer: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            supplierCompany: [],
            prescriber: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            portfolios: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            advanced: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            hasVinculation: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            vinculations: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          clickStepper: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
        },
        list: {
          access: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerBasic',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          enable: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerBasic',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          actions: {
            canDownloadExcel: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            canFilter: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            canShowTopFilterBar: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAssignMultipleProcessManagers: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            canAssignMultipleTechnicalManagers: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
          },
        },
        add: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          addAsset: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            enable: [],
          },
        },
        massive: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          pages: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            list: {
              access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              actions: {
                canFilter: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              },
            },
            add: {
              access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
            detail: {
              access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              actions: {
                canDownloadFile: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              },
            },
          },
        },
        portfolios: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          list: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canFilter: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          add: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          detail: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canDeleteValuations: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canAddValuations: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
        },
        incidents: {
          access: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
          ],
          enable: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
          ],
          actions: {
            canDownloadExcel: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canFilter: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
          },
        },
        claims: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canFilter: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        communications: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
        comparables: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          list: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canFilter: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          detail: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            detail: {
              access: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerBasic',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerBasic',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
            'market-studies': {
              access: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerBasic',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerBasic',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
        },
        detail: {
          access: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerBasic',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          enable: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerBasic',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          addAsset: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            enable: [],
          },
          actions: {
            canAssignManagerProcessUser: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAssignTechnicalUser: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAssignManagerTechnicalUser: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canShowManagerProcessUser: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            canShowTechnicalUser: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            canShowManagerTechnicalUser: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          fastActions: {
            canCancel: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canDuplicate: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canStartValidation: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            canMarkAsDelivered: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canValidate: ['LicenseeUserRole_Root', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager', 'LicenseeUserRole_ExternalTechnicalManager'],
            canReject: ['LicenseeUserRole_Root', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager', 'LicenseeUserRole_ExternalTechnicalManager'],
            canSendToClient: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canMarkAsDeliveredToClient: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canValidateExpenses: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAdvanceVisit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAddBdeData: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canRemoveBdeData: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          detail: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canShowBtnViewCustomer: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
            references: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            request: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            showRequestDataBtn: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            requestData: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            customer: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            prescriber: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            orderFeatures: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            relatedAppraisals: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            incomeExpensesProvision: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            incomeExpensesConfirmed: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            exchange: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          information: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          documents: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canAdd: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerBasic',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canDelete: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerBasic',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canEdit: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerBasic',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canSetAsPrintable: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerBasic',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canDownload: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerBasic',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
            appraisalDocTable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            appraisalAssetDocTable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          incidents: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canAdd: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canResolve: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          revisions: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          claims: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canAdd: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canResolve: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          history: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            records: {
              access: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
            shipments: {
              access: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
            communications: {
              access: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          'message-board': {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canAdd: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canReply: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canEdit: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canDelete: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          invoicing: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          'appraisal-relationship': {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          report: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            list: {
              access: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              actions: {
                canGenerateDraft: [
                  'LicenseeUserRole_Root',
                  'LicenseeUserRole_ProcessManager',
                  'LicenseeUserRole_TechnicalManager',
                  'LicenseeUserRole_CommercialManager',
                  'LicenseeUserRole_ExternalTechnicalManager',
                ],
                canSetDeliverables: [
                  'LicenseeUserRole_Root',
                  'LicenseeUserRole_ProcessManager',
                  'LicenseeUserRole_TechnicalManager',
                  'LicenseeUserRole_CommercialManager',
                  'LicenseeUserRole_ExternalTechnicalManager',
                ],
                canImportReport: [
                  'LicenseeUserRole_Root',
                  'LicenseeUserRole_ProcessManager',
                  'LicenseeUserRole_TechnicalManager',
                  'LicenseeUserRole_CommercialManager',
                  'LicenseeUserRole_ExternalTechnicalManager',
                ],
              },
            },
            detail: {
              access: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              addAsset: {
                access: [
                  'LicenseeUserRole_Root',
                  'LicenseeUserRole_ProcessManager',
                  'LicenseeUserRole_TechnicalManager',
                  'LicenseeUserRole_CommercialManager',
                  'LicenseeUserRole_ExternalTechnicalManager',
                ],
                enable: [],
              },
              commonAssets: {
                access: [
                  'LicenseeUserRole_Root',
                  'LicenseeUserRole_ProcessManager',
                  'LicenseeUserRole_TechnicalManager',
                  'LicenseeUserRole_CommercialManager',
                  'LicenseeUserRole_ExternalTechnicalManager',
                ],
                enable: [
                  'LicenseeUserRole_Root',
                  'LicenseeUserRole_ProcessManager',
                  'LicenseeUserRole_TechnicalManager',
                  'LicenseeUserRole_CommercialManager',
                  'LicenseeUserRole_ExternalTechnicalManager',
                ],
                locationAndSurroundings: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    canGetAutomaticData: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                'market-studies': {
                  access: [
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                  detail: {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    actions: {
                      canEdit: [
                        'LicenseeUserRole_Root',
                        'LicenseeUserRole_ProcessManager',
                        'LicenseeUserRole_TechnicalManager',
                        'LicenseeUserRole_ExternalTechnicalManager',
                        'LicenseeUserRole_CommercialManager',
                      ],
                    },
                    createWitness: {
                      access: [
                        'LicenseeUserRole_ProcessManager',
                        'LicenseeUserRole_TechnicalManager',
                        'LicenseeUserRole_ExternalTechnicalManager',
                        'LicenseeUserRole_CommercialManager',
                      ],
                      enable: [
                        'LicenseeUserRole_ProcessManager',
                        'LicenseeUserRole_TechnicalManager',
                        'LicenseeUserRole_ExternalTechnicalManager',
                        'LicenseeUserRole_CommercialManager',
                      ],
                      createExternSourceWitness: {
                        access: [
                          'LicenseeUserRole_Root',
                          null,
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                        enable: [
                          'LicenseeUserRole_Root',
                          null,
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'LicenseeUserRole_Root',
                          null,
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                        enable: [
                          'LicenseeUserRole_Root',
                          null,
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                      },
                    },
                  },
                  list: {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    actions: {
                      canEdit: [
                        'LicenseeUserRole_Root',
                        'LicenseeUserRole_ProcessManager',
                        'LicenseeUserRole_TechnicalManager',
                        'LicenseeUserRole_ExternalTechnicalManager',
                        'LicenseeUserRole_CommercialManager',
                      ],
                    },
                  },
                },
                terrainFeatures: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                landDescription: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                'exploitation-description': {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                buildingInfo: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                urbanisticSituation: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                urbanisticSituationTerrain: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                checkValidations: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                conditionings: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                buildingState: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                'rustic-estate-features': {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                valuations: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canAdd: [],
                    canEdit: [],
                    canDelete: [],
                    canRecalculate: [],
                  },
                  'comparison-method': {
                    access: [],
                    enable: [],
                    createWitness: {
                      access: [],
                      enable: [],
                      createExternSourceWitness: {
                        access: [
                          'LicenseeUserRole_Root',
                          null,
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                        enable: [
                          'LicenseeUserRole_Root',
                          null,
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'LicenseeUserRole_Root',
                          null,
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                        enable: [
                          'LicenseeUserRole_Root',
                          null,
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                      },
                    },
                  },
                  'cost-method': {
                    access: [],
                    enable: [],
                  },
                  'discount-cashflow-method': {
                    access: [],
                    enable: [],
                    createWitness: {
                      access: [],
                      enable: [],
                      createExternSourceWitness: {
                        access: [
                          'LicenseeUserRole_Root',
                          null,
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                        enable: [
                          'LicenseeUserRole_Root',
                          null,
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'LicenseeUserRole_Root',
                          null,
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                        enable: [
                          'LicenseeUserRole_Root',
                          null,
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                      },
                    },
                  },
                  'contract-cashflow-method': {
                    access: [],
                    enable: [],
                  },
                  'legal-maximum-method': {
                    access: [],
                    enable: [],
                  },
                  'other-method': {
                    access: [],
                    enable: [],
                  },
                  'dynamic-residual-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    createWitness: {
                      access: [],
                      enable: [],
                      createExternSourceWitness: {
                        access: [
                          'LicenseeUserRole_Root',
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                        enable: [],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'LicenseeUserRole_Root',
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                        enable: [],
                      },
                    },
                  },
                  'static-residual-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    createWitness: {
                      access: [],
                      enable: [],
                      createExternSourceWitness: {
                        access: [
                          'LicenseeUserRole_Root',
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                        enable: [],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'LicenseeUserRole_Root',
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                        enable: [],
                      },
                    },
                  },
                  'crawled-method': {
                    access: [],
                    enable: [],
                  },
                  'economic-exploitation-method': {
                    access: [],
                    enable: [],
                  },
                  'rustic-estate-exploitation-method': {
                    access: [],
                    enable: [],
                  },
                },
              },
              assets: {
                access: [
                  'LicenseeUserRole_Root',
                  'LicenseeUserRole_ProcessManager',
                  'LicenseeUserRole_TechnicalManager',
                  'LicenseeUserRole_CommercialManager',
                  'LicenseeUserRole_ExternalTechnicalManager',
                ],
                enable: [
                  'LicenseeUserRole_Root',
                  'LicenseeUserRole_ProcessManager',
                  'LicenseeUserRole_TechnicalManager',
                  'LicenseeUserRole_CommercialManager',
                  'LicenseeUserRole_ExternalTechnicalManager',
                ],
                actions: {
                  canMultipleEdit: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  canCreateCluster: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  canValuateTerrains: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  canModifyStructure: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  canImportFromRequest: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  canMultipleRecalculate: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  canAdd: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  canAddMassive: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  canDelete: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  canDuplicate: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  canAddAnnex: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  canMakeMainAsset: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  canExtractAsset: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                },
                identification: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                location: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                typology: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                surfaces: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                locationAndSurroundings: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    canGetAutomaticData: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                landDescription: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                'exploitation-description': {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                'rustic-estate-features': {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                buildingInfo: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                propertyCharacteristics: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                terrainFeatures: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                urbanisticSituation: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                urbanisticSituationTerrain: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                tenures: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                checkValidations: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
                  },
                },
                conditionings: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                photos: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    canSetAsPrintable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    canDelete: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    canMakeItMain: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    canMultipleSelect: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    canAdd: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                documents: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canSetAsPrintable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    canDownload: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    canAdd: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    canDelete: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                appraisalAssetLogChanges: {
                  access: [
                    'LicenseeUserRole_Root',
                    null,
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    null,
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                buildingState: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                assetExtraInformation: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                valuations: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canAdd: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    canDelete: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    canRecalculate: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                  'comparison-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                  'cost-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                  'discount-cashflow-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                  'contract-cashflow-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                  'legal-maximum-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                  'other-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                  'dynamic-residual-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    createWitness: {
                      access: [],
                      enable: [],
                      createExternSourceWitness: {
                        access: [
                          'LicenseeUserRole_Root',
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                        enable: [],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'LicenseeUserRole_Root',
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                        enable: [],
                      },
                    },
                  },
                  'static-residual-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    createWitness: {
                      access: [],
                      enable: [],
                      createExternSourceWitness: {
                        access: [
                          'LicenseeUserRole_Root',
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                        enable: [],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'LicenseeUserRole_Root',
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                        enable: [],
                      },
                    },
                  },
                  'crawled-method': {
                    access: [],
                    enable: [],
                  },
                  'economic-exploitation-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                  'rustic-estate-exploitation-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
              },
              clusters: {
                access: [
                  'LicenseeUserRole_Root',
                  'LicenseeUserRole_ProcessManager',
                  'LicenseeUserRole_TechnicalManager',
                  'LicenseeUserRole_CommercialManager',
                  'LicenseeUserRole_ExternalTechnicalManager',
                ],
                enable: [
                  'LicenseeUserRole_Root',
                  'LicenseeUserRole_ProcessManager',
                  'LicenseeUserRole_TechnicalManager',
                  'LicenseeUserRole_CommercialManager',
                  'LicenseeUserRole_ExternalTechnicalManager',
                ],
                actions: {
                  canDelete: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                },
                pages: {
                  detail: {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    actions: {
                      canEdit: [
                        'LicenseeUserRole_Root',
                        'LicenseeUserRole_ProcessManager',
                        'LicenseeUserRole_TechnicalManager',
                        'LicenseeUserRole_ExternalTechnicalManager',
                        'LicenseeUserRole_CommercialManager',
                      ],
                    },
                  },
                },
                buildingState: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                detail: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
                valuations: {
                  access: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  enable: [
                    'LicenseeUserRole_Root',
                    'LicenseeUserRole_ProcessManager',
                    'LicenseeUserRole_TechnicalManager',
                    'LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_CommercialManager',
                  ],
                  actions: {
                    canAdd: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    canEdit: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    canDelete: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    canRecalculate: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                  'comparison-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                  'cost-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                  'discount-cashflow-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                  'contract-cashflow-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                  'legal-maximum-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                  'other-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                  'dynamic-residual-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    createWitness: {
                      access: [],
                      enable: [],
                      createExternSourceWitness: {
                        access: [
                          'LicenseeUserRole_Root',
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                        enable: [],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'LicenseeUserRole_Root',
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                        enable: [],
                      },
                    },
                  },
                  'static-residual-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    createWitness: {
                      access: [],
                      enable: [],
                      createExternSourceWitness: {
                        access: [
                          'LicenseeUserRole_Root',
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                        enable: [],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'LicenseeUserRole_Root',
                          'LicenseeUserRole_ProcessManager',
                          'LicenseeUserRole_TechnicalManager',
                          'LicenseeUserRole_ExternalTechnicalManager',
                          'LicenseeUserRole_CommercialManager',
                        ],
                        enable: [],
                      },
                    },
                  },
                  'crawled-method': {
                    access: [],
                    enable: [],
                  },
                  'economic-exploitation-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                  'rustic-estate-exploitation-method': {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                  },
                },
              },
            },
          },
          precedents: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          'bde-data': {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canEdit: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canAdd: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canRemove: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
        },
      },
    },
    'management-orders': {
      access: [
        'LicenseeUserRole_Root',
        'LicenseeUserRole_CustomerBasic',
        'LicenseeUserRole_CustomerAdvanced',
        'LicenseeUserRole_ProcessManager',
        'LicenseeUserRole_TechnicalManager',
        'LicenseeUserRole_CommercialManager',
        'LicenseeUserRole_ExternalTechnicalManager',
      ],
      enable: [
        'LicenseeUserRole_Root',
        'LicenseeUserRole_CustomerBasic',
        'LicenseeUserRole_CustomerAdvanced',
        'LicenseeUserRole_ProcessManager',
        'LicenseeUserRole_TechnicalManager',
        'LicenseeUserRole_CommercialManager',
        'LicenseeUserRole_ExternalTechnicalManager',
      ],
      pages: {
        access: [
          'LicenseeUserRole_Root',
          'LicenseeUserRole_CustomerBasic',
          'LicenseeUserRole_CustomerAdvanced',
          'LicenseeUserRole_ProcessManager',
          'LicenseeUserRole_TechnicalManager',
          'LicenseeUserRole_CommercialManager',
          'LicenseeUserRole_ExternalTechnicalManager',
        ],
        enable: [
          'LicenseeUserRole_Root',
          'LicenseeUserRole_CustomerBasic',
          'LicenseeUserRole_CustomerAdvanced',
          'LicenseeUserRole_ProcessManager',
          'LicenseeUserRole_TechnicalManager',
          'LicenseeUserRole_CommercialManager',
          'LicenseeUserRole_ExternalTechnicalManager',
        ],
        dashboard: {
          access: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          enable: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          fileVolume: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          fileAverageTime: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          summaryOfUnfinishedItems: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          filters: {
            orderTypes: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            purpose: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            customer: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            supplierCompany: [],
            prescriber: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            portfolios: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            advanced: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            hasVinculation: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            vinculations: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          clickStepper: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
        },
        list: {
          access: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerBasic',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          enable: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerBasic',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          actions: {
            canDownloadExcel: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            canFilter: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            canShowTopFilterBar: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAssignMultipleProcessManagers: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            canAssignMultipleTechnicalManagers: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
          },
        },
        add: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          addAsset: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            enable: [],
          },
        },
        massive: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          pages: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            list: {
              access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              actions: {
                canFilter: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              },
            },
            add: {
              access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
            detail: {
              access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              actions: {
                canDownloadFile: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              },
            },
          },
        },
        portfolios: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          list: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canFilter: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          add: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          detail: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canDeleteValuations: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canAddValuations: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
        },
        incidents: {
          access: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
          ],
          enable: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
          ],
          actions: {
            canDownloadExcel: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canFilter: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
          },
        },
        claims: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canFilter: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        detail: {
          access: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerBasic',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          enable: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_CustomerBasic',
            'LicenseeUserRole_CustomerAdvanced',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          addAsset: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            enable: [],
          },
          actions: {
            canAssignManagerProcessUser: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAssignTechnicalUser: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAssignManagerTechnicalUser: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canShowManagerProcessUser: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            canShowTechnicalUser: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            canShowManagerTechnicalUser: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          fastActions: {
            canCancel: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canDuplicate: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canStartValidation: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            canMarkAsDelivered: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canValidate: ['LicenseeUserRole_Root', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager', 'LicenseeUserRole_ExternalTechnicalManager'],
            canReject: ['LicenseeUserRole_Root', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager', 'LicenseeUserRole_ExternalTechnicalManager'],
            canSendToClient: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canMarkAsDeliveredToClient: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canValidateExpenses: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAdvanceVisit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAddBdeData: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canRemoveBdeData: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          detail: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canShowBtnViewCustomer: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
            references: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            request: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            showRequestDataBtn: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            requestData: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            customer: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
            prescriber: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            orderFeatures: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            relatedAppraisals: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            incomeExpensesProvision: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            incomeExpensesConfirmed: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            exchange: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          information: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          documents: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canAdd: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerBasic',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canDelete: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerBasic',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canEdit: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerBasic',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canSetAsPrintable: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerBasic',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canDownload: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_CustomerBasic',
                'LicenseeUserRole_CustomerAdvanced',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
            appraisalDocTable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            appraisalAssetDocTable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          incidents: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_CustomerBasic',
              'LicenseeUserRole_CustomerAdvanced',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canAdd: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canResolve: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          revisions: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          claims: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canAdd: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canResolve: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          history: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            records: {
              access: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
            shipments: {
              access: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
            communications: {
              access: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          'message-board': {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canAdd: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canReply: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canEdit: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canDelete: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          invoicing: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          'appraisal-relationship': {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          precedents: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          'bde-data': {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canEdit: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canAdd: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canRemove: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
        },
      },
    },
  },
  financial: {
    access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
    enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
    'payment-summary': {
      access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      pages: {
        access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        list: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canDownloadInvoices: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAskForConfirmation: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canResetInvoices: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        detail: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          fastActions: {
            canAskForConfirmation: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canDownloadDraft: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canResetInvoice: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          detail: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
      },
    },
    'code-management': {
      access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      pages: {
        access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        list: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            addNewCode: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        detail: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            edit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          form: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        add: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
      },
    },
  },
  participants: {
    access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
    enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
    customers: {
      access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      pages: {
        access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        list: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canAddNewClient: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        add: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
        detail: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            addProfilePhoto: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          profile: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          'billing-info': {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          appraisals: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          users: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canAddNewUser: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canEditUser: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canEditPassword: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          deliverables: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
        },
      },
    },
    professionals: {
      access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      pages: {
        access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        list: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            addNewProfessional: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        add: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
        detail: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            addProfilePhoto: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          fastActions: {
            change_password: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            addIncompatibilities: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          profile: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          'billing-info': {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          collaborator: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          appraisals: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          invoices: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          schedule: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          incompatibilities: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canDelete: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          preferences: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canSetDistance: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          documents: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
        },
      },
    },
    'requester-companies': {
      access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      pages: {
        access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        list: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canAddNewPrescriptorComapy: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        add: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
        detail: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canEditImage: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          profile: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          'billing-info': {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          appraisals: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          users: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canAddNewUser: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canEditUsers: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canChangeUsersPassword: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          deliverables: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          collaborator: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
        },
      },
    },
  },
  'users-credentials': {
    access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
    enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
    'process-manager-user': {
      access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      pages: {
        access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        list: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canAddNewUser: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        add: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
        detail: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canChangeImage: [
              'LicenseeUserRole_ExternalTechnicalManager',
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
            ],
          },
          fastActions: {
            canChange_password: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAssignRoles: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAddIncompatibilities: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          profile: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canEdit: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          incompatibilities: {
            access: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canEdit: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
              canDelete: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
        },
      },
    },
    'technical-manager-user': {
      access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      pages: {
        access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        list: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canAddNewUser: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        add: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
        detail: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canChangeImage: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          fastActions: {
            canChange_password: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAssignRoles: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAddIncompatibilities: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          profile: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          incompatibilities: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canDelete: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
        },
      },
    },
    'commercial-manager-user': {
      access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      pages: {
        access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        list: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canAddNewUser: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        add: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
        detail: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canChangeImage: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          fastActions: {
            canChange_password: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAssignRoles: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAddIncompatibilities: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          profile: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: [
                'LicenseeUserRole_Root',
                'LicenseeUserRole_ProcessManager',
                'LicenseeUserRole_TechnicalManager',
                'LicenseeUserRole_CommercialManager',
                'LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          incompatibilities: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canDelete: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
        },
      },
    },
    users: {
      access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      pages: {
        access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        list: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canAddNewUser: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
        add: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          showWarningPopup: [],
        },
        detail: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          actions: {
            canChangeImage: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          fastActions: {
            canChange_password: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            canAddIncompatibilities: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
          profile: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          incompatibilities: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canDelete: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          preferences: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canSetDistance: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          documents: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canDelete: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canDownload: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          collaborator: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canDelete: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          appraisals: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canDelete: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          invoices: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canDelete: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          schedule: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canDelete: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          history: {
            access: [],
            enable: [],
          },
          'billing-info': {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
          training: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            actions: {
              canEdit: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
              canDelete: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            },
          },
        },
      },
    },
    incompatibilities: {
      access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
      enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
    },
  },
  actives: {
    access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
    enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
  },
  wiki: {
    access: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
    enable: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
  },
  'my-profile': {
    access: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
    enable: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
    pages: {
      access: [
        'LicenseeUserRole_Root',
        'LicenseeUserRole_ProcessManager',
        'LicenseeUserRole_TechnicalManager',
        'LicenseeUserRole_CommercialManager',
        'LicenseeUserRole_ExternalTechnicalManager',
      ],
      enable: [
        'LicenseeUserRole_Root',
        'LicenseeUserRole_ProcessManager',
        'LicenseeUserRole_TechnicalManager',
        'LicenseeUserRole_CommercialManager',
        'LicenseeUserRole_ExternalTechnicalManager',
      ],
      detail: {
        access: [
          'LicenseeUserRole_Root',
          'LicenseeUserRole_ProcessManager',
          'LicenseeUserRole_TechnicalManager',
          'LicenseeUserRole_CommercialManager',
          'LicenseeUserRole_ExternalTechnicalManager',
        ],
        enable: [
          'LicenseeUserRole_Root',
          'LicenseeUserRole_ProcessManager',
          'LicenseeUserRole_TechnicalManager',
          'LicenseeUserRole_CommercialManager',
          'LicenseeUserRole_ExternalTechnicalManager',
        ],
        fastActions: {
          canChange_password: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          canAddIncompatibilities: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
        },
        profile: {
          access: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          enable: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
        },
        incompatibilities: {
          access: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          enable: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          actions: {
            canEdit: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
            canDelete: [
              'LicenseeUserRole_Root',
              'LicenseeUserRole_ProcessManager',
              'LicenseeUserRole_TechnicalManager',
              'LicenseeUserRole_CommercialManager',
              'LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
        },
        'billing-info': {
          access: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          enable: [
            'LicenseeUserRole_Root',
            'LicenseeUserRole_ProcessManager',
            'LicenseeUserRole_TechnicalManager',
            'LicenseeUserRole_CommercialManager',
            'LicenseeUserRole_ExternalTechnicalManager',
          ],
          canGetAutomaticData: {
            access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
            enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          },
        },
      },
    },
  },
  academy: {
    access: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
    enable: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
  },
  'global-search': {
    access: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
    enable: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
  },
  ClusterTypes: {
    FinishedElements: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
    UnfinishedElements: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
    Terrains: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
    EconomicExploitation: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
  },
  AssetUses: {
    Residential: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_CustomerBasic',
      'LicenseeUserRole_CustomerAdvanced',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
    Commercial: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_CustomerBasic',
      'LicenseeUserRole_CustomerAdvanced',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
    Tertiary: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_CustomerBasic',
      'LicenseeUserRole_CustomerAdvanced',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
    Industrial: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_CustomerBasic',
      'LicenseeUserRole_CustomerAdvanced',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
    Annexed: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_CustomerBasic',
      'LicenseeUserRole_CustomerAdvanced',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
    RusticEstate: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_CustomerBasic',
      'LicenseeUserRole_CustomerAdvanced',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
    Terrain: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_CustomerBasic',
      'LicenseeUserRole_CustomerAdvanced',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
    Dotational: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_CustomerBasic',
      'LicenseeUserRole_CustomerAdvanced',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
  },
  AssetStates: {
    Finished: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_CustomerBasic',
      'LicenseeUserRole_CustomerAdvanced',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
    Unfinished: [
      'LicenseeUserRole_Root',
      'LicenseeUserRole_ProcessManager',
      'LicenseeUserRole_TechnicalManager',
      'LicenseeUserRole_CommercialManager',
      'LicenseeUserRole_CustomerBasic',
      'LicenseeUserRole_CustomerAdvanced',
      'LicenseeUserRole_ExternalTechnicalManager',
    ],
  },
  isEconomicExploitationCheckbox: [
    'LicenseeUserRole_Root',
    'LicenseeUserRole_ProcessManager',
    'LicenseeUserRole_TechnicalManager',
    'LicenseeUserRole_CommercialManager',
    'LicenseeUserRole_ExternalTechnicalManager',
  ],
};
