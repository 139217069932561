import { OrNull } from '../interfaces';
import {
  AssetDataSourceTypes,
  AssetStates,
  AssetSubTypologies,
  AssetSurfaceIds,
  AssetTypologies,
  AssetUses,
  AssetWorkStates,
  BuildingUses,
  ClusterTypes,
  FloorTypes,
  OcupationStates,
  OcupationTypes,
  ResidenceTypes,
  StreetTypes,
  TenureTypes,
  ValorationMethods,
} from '../enums';
import { AssetLocalizationInformationDto, AssetLocalizationInformationModel } from './asset-localization.model';
import { AssetMultipleRecalculationModel, AssetSurfacesInformationDto, AssetTabConfiguration, AssetTypologyInformationDto, LogItemModel } from './assets.model';
import { AssetIdentificationDataModel } from './new-tab-models';
import { ApiPropertyRegister } from './register.models';

export interface ApprRepAssetTenureInformation {
  Tenures: ApprRepAssetTenure[];
  OcupationState: OcupationStates;
  OcupationType: OcupationTypes;
  HasContract: boolean;
  HasNonexistenceLeasesDocument: boolean;
  TenuresDataSource: AssetDataSourceTypes;
}

export interface ApprRepAssetTenure {
  TenureId: number;
  AssetId: number;
  WorkflowItemAssetId: number;
  Name: string;
  NationalIdentityDocument: string;
  Percentage: number;
  TenureType: TenureTypes;
}

export interface AppraisalRepAssetToAddInCluster {
  id: number;
  assetUse: AssetUses;
  assetTypology: AssetTypologies;
  registeredPropertyNumber: string;
}

export interface AppraisalReportAssetClusterDetailModel {
  WorkflowItemAssetId: number;
  ValorationMethodAdopted: ValorationMethods;
  ValorationValue: number;
  ValorationUnitValue: number;
  ValorationTotalAdoptedSurface: number;
  ValorationAdoptedSurfaceId: AssetSurfaceIds;
  AssetTypology: AssetTypologies;
  AssetUse: AssetUses;
}

export interface AppraisalAssetStructureModel {
  AssetId?: number;
  AppraisalAssetId?: number;
  AppraisalAssetForm?: AppraisalAssetStructureForm;
  AppraisalAssetChildrenForms?: AppraisalAssetStructureForm[];
  AppraisalAssetChildrenIds?: number[];
  AssetChildrenIds?: number[];
}

export interface AppraisalAssetStructureForm {
  TemporalId: string;
  WorkflowItemAssetCreateModel?: WorkflowItemAssetCreateModel;
}

/////////////////////////////////////
// Nuevos modelos
export interface AppraisalReportAssetClusterIndexModel {
  id: number;
  name: string;
  totalAdoptedSurface: number;
  buildingConstructionYear: number;
  buildingLastRehabilitationYear: number;
  buildingConstructionYearToUse: number;
  buildingUse: BuildingUses;
  workflowItemAssetIds: number[];
  latitude: number;
  longitude: number;
  use: AssetUses;
  typology: AssetTypologies;
  subTypology: AssetSubTypologies;
  censusSectionName: string;
  reformLastYear: number;
  isTerrainCluster: boolean;
  valorationValue: number;
  valorationTotalAdoptedSurface: number;
  valorationUnitValue: number;
  isClusterRecalculationNeeded: boolean;
  isRevalorationNeeded: boolean;
  future_IsRevalorationNeeded: boolean;
  isWorkPlanStateRecalculationNeeded: boolean;
}

export interface ClusterInitialDetailModel {
  name: string;
  isTerrainCluster: boolean;
  isClusterRecalculationNeeded: boolean;
  isRevalorationNeeded: boolean;
  hasAssetsWithoutSurfaces: boolean;
  hasAssetsWithoutSelectedSurfaces: boolean;
  tabConfigurations: AssetTabConfiguration[];
  isEconomicExploitation: boolean;
  isWorkPlanStateRecalculationNeeded: boolean;
}

export interface WorkflowItemReportAssetClusterModel {
  id: number;
  clusterType: ClusterTypes;
  name: string;
  totalAdoptedSurface: number;
  buildingConstructionYear: number;
  buildingLastRehabilitationYear: number;
  buildingConstructionYearToUse: number;
  buildingUse: BuildingUses;
  workflowItemAssetIds: number[];
  latitude: number;
  longitude: number;
  use: AssetUses;
  typology: AssetTypologies;
  subTypology: AssetSubTypologies;
  residenceType: ResidenceTypes;
  censusSectionName: string;
  isTerrainCluster: boolean;
  assets: WorkflowItemReportAssetClusterAssetModel[];
  observations: string;
  constructionYearWightedObservations: string;
  workState: AssetWorkStates;
  state: AssetStates;
}

export interface WorkflowItemReportAssetClusterAssetEditModel {
  id: number;
  clusterAdoptedSurfaceId: AssetSurfaceIds;
}

export interface WorkflowItemReportAssetClusterEditModel {
  id: number;
  clusterType: ClusterTypes;
  name: string;
  observations: string;
  constructionYearWightedObservations: string;
  workflowItemAssetIds: number[];
  buildingConstructionYear: number;
  buildingLastRehabilitationYear: number;
  buildingUse: BuildingUses;
  use: AssetUses;
  typology: AssetTypologies;
  subTypology: AssetSubTypologies;
  residenceType: ResidenceTypes;
  assetSurfaces: WorkflowItemReportAssetClusterAssetEditModel[];
}

export interface WorkflowItemReportAssetClusterAssetModel {
  id: number | null | undefined;
  reportId: number;
  clusterId: number;
  clusterAdoptedSurfaceId: number;
  assetIdentificationCadastralReference: string;
  assetIdentificationIdufir: string;
  assetIdentificationRegisteredPropertyNumber: string;
  assetIdentificationPropertyRegisterGeoSmartaId: string;
  assetIdentificationPropertyRegisterName: string;
  use: AssetUses;
  typology: AssetTypologies;
  subTypology: AssetSubTypologies;
  surfacesInformation: AssetSurfacesInformationDto;
  isParentOrChild: boolean;
  children: WorkflowItemReportAssetClusterAssetModel[];
}

export interface ClusterValorationSummary {
  assets: WorkflowItemReportAssetClusterValorationSummary[];
  otherMethodTotal: number;
  staticResidualMethodTotal: number;
  comparisonMethodTotal: number;
  dynamicResidualMethodTotal: number;
}

export interface WorkflowItemReportAssetClusterValorationSummary {
  name: string;
  registeredPropertyNumber: string;
  comparisonMethodValue: number;
  otherMethodValue: number;
  staticResidualMethodValue: number;
  dynamicResidualMethodValue: number;
}

export interface WorkflowItemAssetClusterEditModel {
  buildingUse: BuildingUses;
  censusSectionName: string;
  use: AssetUses;
  typology: AssetTypologies;
  subTypology: AssetSubTypologies; // Add to Front (?)
  name: string;
  reformLastYear: number;
  totalAdoptedSurface: number;
  buildingConstructionYearToUse: number; // Esto va al Model ? no se puede editar
  id: number; // TODO: review id (?)
  // TODO: remove or move
  isTerrainCluster: boolean;
  valorationMethodAdopted: ValorationMethods;
  workflowItemAssetIds: number[];
  // Added
  constructionYear: number;
}

export interface AssetTreeNodeExtendedIndexModel {
  assetIdentificationPropertyRegisterName: string;
  addressGeneratedName: string;
  assetIdentificationCadastralReference: string;
  assetIdentificationIdufir: string;
  assetIdentificationRegisteredPropertyNumber: string;
  assetRequiredCompletedPercent: number;
  assetTotalCompletedPercent: number;
  block: string;
  censusSectionName: string;
  children: AssetTreeNodeExtendedIndexModel[]; // TODO. back model?
  clusterId: number;
  clusterName: string;
  country: string;
  countryThreeLetterISORegionName: string;
  dgcCodVia: number;
  floorType: FloorTypes;
  fullAddressGeneratedName: string;
  gate: string;
  hasChangesWithRelatedAsset: boolean;
  id: number;
  ineCodLocality: number;
  ineCodLocalityStr: string;
  ineCodMun: number;
  ineCodMunStr: string;
  ineCodProv: number;
  ineCodProvStr: string;
  ineCodVia: string;
  isAllAssetCompleted: boolean;
  isAllRequiredAssetCompleted: boolean;
  isRevalorationNeeded: boolean;
  locality: string;
  name: string;
  // nameGeneratedByAddress: string;
  // nameGeneratedByUseTypologyAndSubTypology: string;
  parentId: number;
  portal: string;
  postalCode: string;
  province: string;
  reportsUniqueId: number;
  stairs: string;
  state: AssetStates;
  streetName: string;
  streetNumber: string;
  streetType: StreetTypes;
  streetNotExists: boolean;
  subTypology: AssetSubTypologies;
  typology: AssetTypologies;
  use: AssetUses;
  visitForm_EnvironmentAndBuildingPercent: number;
  visitForm_IsAllCompleted: boolean;
  visitForm_IsAllRequiredCompleted: boolean;
  visitForm_SurfacesAndFacilitiesAndStaysPercent: number;
  visitForm_TypologyAndStatePercent: number;
  // Added
  isClustered: boolean;
  // isEditable: boolean;
  isWorkPlanStateRecalculationNeeded: boolean;
  hasValorations: boolean;

  // Validations
  errorCount?: number;
  warningCount?: number;
  infoCount?: number;

  reportsUniqueIncrementalId: number;
}

/**@todo revisar con alberto */
export interface WorkflowItemAssetCreateModel {
  parentId: number | null;
  reportId: OrNull<number>;
  assetIdentification: AssetIdentificationDataModel;
  assetLocalization: AssetLocalizationInformationModel;
  assetTypologies: AssetTypologyInformationDto;
  assetSurfaces: AssetSurfacesInformationDto;
}

export interface AppraisalReportAssetCreateResponseModel {
  id: number;
  addeddToClusterSuccessfully: boolean;
  addeddToClusterMessage: string;
}

export interface AppraisalReportAssetClusterEditModel {
  id: number;
  name: string;
  totalAdoptedSurface: number;
  buildingConstructionYearToUse: number;
  buildingUse: BuildingUses;
  workflowItemAssetIds: number[];
  use: AssetUses;
  typology: AssetTypologies;
  subTypology: AssetSubTypologies;
  censusSectionName: string;
  reformLastYear: number;
  isTerrainCluster: boolean;
  valorationMethodAdopted: ValorationMethods;
}

export interface AppraisalAssetLogItemIndexModel {
  id: number;
  assetId: number;
  fieldChanges: LogItemModel[];
  addedDateTime: string;
  addedUserId: number;
  addedUserFullName: string;
}

export interface WorkflowItemAssetClusterEditModel {
  id: number;
  name: string;
  workflowItemAssetIds: number[];
  buildingConstructionYear: number;
  buildingLastRehabilitationYear: number;
  buildingUse: BuildingUses;
  use: AssetUses;
  typology: AssetTypologies;
  subTypology: AssetSubTypologies;
  reformLastYear: number;
  observations: string;
}

export interface AssetSummaryForClusterIndexModel {
  id: number;
  streetType: StreetTypes;
  streetName: string;
  streetNumber: string;
  block: string;
  stairs: string;
  portal: string;
  floorType: FloorTypes;
  gate: string;
  locality: string;
  province: string;
  postalCode: string;
  country: string;
  countryThreeLetterISORegionName: string;
  ineCodProv: number;
  ineCodMun: number;
  dgcCodVia: number;
  ineCodVia: string;
  censusSectionName: string;
  assetIdentificationCadastralReference: string;
  assetIdentificationIdufir: string;
  assetIdentificationRegisteredPropertyNumber: string;
  assetIdentificationPropertyRegisterGeoSmartaId: string;
  assetIdentificationPropertyRegisterName: string;
  subTypology: AssetSubTypologies;
  typology: AssetTypologies;
  use: AssetUses;
  buildingInformationConstructionYear: number;
  buildingInformationLastRehabilitationYear: number;
  reformLastYear: number;
}

export interface AppraisalReportAssetFilterModel {
  id: number;
  ids: number;
  reportId: number;
  reportIds: number;
  clusterId: number;
  clusterIds: number;
  isCommonAssetData: boolean;
  assetIdentificationDataSource: AssetDataSourceTypes;
}

export interface AssetSummaryForClusterIndexModel {
  id: number;
  streetType: StreetTypes;
  streetName: string;
  streetNumber: string;
  block: string;
  stairs: string;
  portal: string;
  floorType: FloorTypes;
  gate: string;
  locality: string;
  province: string;
  postalCode: string;
  country: string;
  countryThreeLetterISORegionName: string;
  ineCodProv: number;
  ineCodMun: number;
  dgcCodVia: number;
  ineCodVia: string;
  censusSectionName: string;
  assetIdentificationCadastralReference: string;
  assetIdentificationIdufir: string;
  assetIdentificationRegisteredPropertyNumber: string;
  assetIdentificationPropertyRegisterGeoSmartaId: string;
  assetIdentificationPropertyRegisterName: string;
  subTypology: AssetSubTypologies;
  typology: AssetTypologies;
  use: AssetUses;
  buildingInformationBuildingUse: BuildingUses;
  buildingInformationConstructionYear: number;
  buildingInformationLastRehabilitationYear: number;
  reformLastYear: number;
}

export interface AssetTreeNodeExtendedIndexModel {
  assetIdentificationPropertyRegisterName: string;
  addressGeneratedName: string;
  assetIdentificationCadastralReference: string;
  assetIdentificationIdufir: string;
  assetIdentificationRegisteredPropertyNumber: string;
  assetRequiredCompletedPercent: number;
  assetTotalCompletedPercent: number;
  block: string;
  censusSectionName: string;
  children: AssetTreeNodeExtendedIndexModel[]; // TODO. back model?
  clusterId: number;
  clusterName: string;
  country: string;
  countryThreeLetterISORegionName: string;
  dgcCodVia: number;
  floorType: FloorTypes;
  fullAddressGeneratedName: string;
  gate: string;
  hasChangesWithRelatedAsset: boolean;
  id: number;
  ineCodLocality: number;
  ineCodLocalityStr: string;
  ineCodMun: number;
  ineCodMunStr: string;
  ineCodProv: number;
  ineCodProvStr: string;
  ineCodVia: string;
  isAllAssetCompleted: boolean;
  isAllRequiredAssetCompleted: boolean;
  isRevalorationNeeded: boolean;
  locality: string;
  name: string;
  // nameGeneratedByAddress: string;
  // nameGeneratedByUseTypologyAndSubTypology: string;
  parentId: number;
  portal: string;
  postalCode: string;
  province: string;
  reportsUniqueId: number;
  stairs: string;
  state: AssetStates;
  streetName: string;
  streetNumber: string;
  streetType: StreetTypes;
  subTypology: AssetSubTypologies;
  typology: AssetTypologies;
  use: AssetUses;
  visitForm_EnvironmentAndBuildingPercent: number;
  visitForm_IsAllCompleted: boolean;
  visitForm_IsAllRequiredCompleted: boolean;
  visitForm_SurfacesAndFacilitiesAndStaysPercent: number;
  visitForm_TypologyAndStatePercent: number;
  // Added
  isClustered: boolean;
  // isEditable: boolean;
  isWorkPlanStateRecalculationNeeded: boolean;
  hasValorations: boolean;

  reportsUniqueIncrementalId: number;
}

export interface WorkflowItemReportAssetClusterAssetsCreateModel {
  assetIds: number[];
}

export interface MultipleAssetRecalculationReportDataModel {
  data: AssetMultipleRecalculationModel[];
  succeeded: boolean;
  errors: any;
}

export interface WorkflowItemReportAssetClusterTreeModel {
  id: number;
  name: string;
  state: AssetStates;
  isEconomicExploit: boolean;
  isClickable: boolean;
  isVisible: boolean;
  errorMessage: string;
  children: WorkflowItemReportAssetClusterTreeModel[];
}

export interface AppraisalReportAssetIdentificationDataModel {
  book: string | null;
  cadastralReference: string | null;
  cadastralReferenceContainerBuilding: string | null;
  idufir: string | null;
  inscription: string | null;
  observations: string | null;
  paper: string | null;
  propertyRegister: ApiPropertyRegister;
  propertyRegisterGeoSmartaId: string | null;
  propertyRegisterName: string | null;
  registeredPropertyNumber: string | null;
  section: string | null;
  totalCompletedPercentage: number;
  totalRequiredCompletedPercentage: number;
  volume: string | null;
}

export interface AppraisalReportAssetCreateModel {
  parentId: number | null;
  reportId: number;
  assetIdentification: AppraisalReportAssetIdentificationDataModel;
  assetLocalization: AssetLocalizationInformationDto;
  assetTypologies: AssetTypologyInformationDto;
  assetSurfaces: AssetSurfacesInformationDto;
}
