import {
  AssetStates,
  AssetSubTypologies,
  AssetSurfaceIds,
  AssetTypologies,
  AssetUses,
  AssetWorkStates,
  BorrowersHabitualResidence,
  BuildingInformationDeckTypes,
  BuildingInformationEnclosureTypes,
  BuildingInformationFoundationTypes,
  BuildingInformationStructureTypes,
  BuildingQualityTypes,
  BuildingUses,
  CommercialConditioningType,
  CommercialLocationStreetType,
  ConservationStateTypes,
  EnergyCertificationGrades,
  EnergyCertificationLetters,
  EnergyCertificationNormatives,
  EnvironmentOptions,
  EnvironmentSignificance,
  ExternalWoodworksTypes,
  FloorTypes,
  GroundLevelTypes,
  GroundQualifications,
  HeatingTypes,
  HotelCategory,
  HotWaterTypes,
  IndustrialEnvironments,
  IndustrialLocationTypes,
  InteriorExteriorTypes,
  LegalSituationTypes,
  LicenseTypes,
  LocationComparedToSameBuildingElements,
  LocationComparedToSameMunicipality,
  LocationComparedToSameZone,
  ManagementPhases,
  ManagementSystemTypes,
  OrientationTypes,
  PolivalentUses,
  ProductToDevelopTypes,
  PropertySizes,
  PropertyStates,
  QuantifyOptions,
  ResidenceTypes,
  SocioEconomicLevels,
  StreetCategoryTypes,
  SurveillanceTypes,
  TargetTypes,
  TerrainPlanningDevelopmentTypes,
  UrbanCoreProximityTypes,
  YesNoNoApplies,
} from '../enums';
import { AssetStayModel } from './asset-stays.model';
import { AssetSurfaceData, AssetTabModel } from './assets.model';
import { ApiPropertyRegister } from './register.models';

/**
 * Edición masiva
 */
export interface AssetTabMassiveResultRowModel {
  columnName: string | undefined;
  error: string | undefined;
}

export interface AssetTabMassiveResultModel {
  assetId: number;
  isSuccessfulResult: boolean;
  generalErrors: string[];
  errors: AssetTabMassiveResultRowModel[];
}

/**
 * Se utiliza tanto para el GET como para el PUT precedido de AssetTabModel<AssetIdentificationDataModel>
 */
export interface AssetIdentificationDataModel {
  book: string;
  cadastralReference: string;
  cadastralReferenceContainerBuilding: string;
  idufir: string;
  inscription: string;
  observations: string;
  paper: string;
  propertyRegister: ApiPropertyRegister;
  propertyRegisterGeoSmartaId: string;
  propertyRegisterName: string;
  registeredPropertyNumber: string;
  section: string;
  totalCompletedPercentage: number;
  totalRequiredCompletedPercentage: number;
  volume: string;
}

export interface AssetTypologyAndStateInformationDto {
  state: AssetStates;
  workState: AssetWorkStates;
  use: AssetUses;
  typology: AssetTypologies;
  subTypology: AssetSubTypologies;
  isPlot: boolean;
  reformLastYear: number;
  hasEnergeticCertificate: boolean;
  energeticCertificateGrade: EnergyCertificationGrades;
  legalSituation: LegalSituationTypes;
  qualityOfBuilding: BuildingQualityTypes;
  conservationState: ConservationStateTypes;
  currentStateOfAsset: string;
  borrowersHabitualResidence: BorrowersHabitualResidence;
  residenceType: ResidenceTypes;
  expedient: string;
  provisionalQualificationDateTime: string;
  definitiveQualificationDateTime: string;
  protectionTimeLimit: number;
  bathroomCount: number;
  bedroomCount: number;
  isEconomicExploitation: boolean;
  hasActiveCultivation: boolean;

  CEECalifEmi?: EnergyCertificationGrades;
  CEECalifConsumo?: EnergyCertificationGrades;

  CEEConsuEner?: number;
  CEENormatiAnroConst?: EnergyCertificationNormatives;

  CEECatAnter?: EnergyCertificationGrades;
  CEEFchRecCertif?: any;
  CEEFchLimValid?: any;
  CEEFchEmisionCertif?: any;
  CEEMejoraCalAntCEE?: YesNoNoApplies;
  CEEPorceMejora?: number;

  CEEDemCalefEmi?: number;
  CEEDemCalefLetEmi?: EnergyCertificationLetters;
  CEEDemCalefConsu?: number;
  CEEDemCalefLetConsu?: EnergyCertificationLetters;

  CEEDemRefriEmi?: number;
  CEEDemRefriLetEmi?: EnergyCertificationLetters;
  CEEDemRefriConsu?: number;
  CEEDemRefriLetConsu?: EnergyCertificationLetters;

  CEEDemACSEmi?: number;
  CEEDemACSLetEmi?: EnergyCertificationLetters;
  CEEDemACSConsu?: number;
  CEEDemACSLetConsu?: EnergyCertificationLetters;

  CEEDemIlumEmi?: number;
  CEEDemIlumLetEmi?: EnergyCertificationGrades;
  CEEDemIlumConsu?: number;
  CEEDemIlumLetConsu?: EnergyCertificationLetters;
  ceeFchLimValid?: any;
  ceeFchRecCertif?: any;
  ceeFchEmisionCertif?: any;
}

export interface AssetTypologyAndStateInformationEditModelBase {
  provisionalQualificationDateTime: string;
  expedient: string;
  residenceType: ResidenceTypes;
  currentStateOfAsset: string;
  conservationState: ConservationStateTypes;
  qualityOfBuilding: BuildingQualityTypes;
  legalSituation: LegalSituationTypes;
  definitiveQualificationDateTime: string;
  reformLastYear: number;
  isPlot: boolean;
  subTypology: AssetSubTypologies;
  typology: AssetTypologies;
  use: AssetUses;
  state: AssetStates;
  workState: AssetWorkStates;
  hasEnergeticCertificate: boolean;
  protectionTimeLimit: number;
  borrowersHabitualResidence: BorrowersHabitualResidence;
  hasActiveCultivation: boolean;

  CEECalifEmi?: EnergyCertificationGrades;

  CEEConsuEner?: number;
  CEENormatiAnroConst?: EnergyCertificationNormatives;

  CEECatAnter?: EnergyCertificationGrades;
  CEEFchRecCertif?: any;
  CEEFchLimValid?: any;
  CEEMejoraCalAntCEE?: YesNoNoApplies;
  CEEPorceMejora?: number;

  CEEDemCalefEmi?: number;
  CEEDemCalefLetEmi?: EnergyCertificationLetters;
  CEEDemCalefConsu?: number;
  CEEDemCalefLetConsu?: EnergyCertificationLetters;

  CEEDemRefriEmi?: number;
  CEEDemRefriLetEmi?: EnergyCertificationLetters;
  CEEDemRefriConsu?: number;
  CEEDemRefriLetConsu?: EnergyCertificationLetters;

  CEEDemACSEmi?: number;
  CEEDemACSLetEmi?: EnergyCertificationLetters;
  CEEDemACSConsu?: number;
  CEEDemACSLetConsu?: EnergyCertificationLetters;

  CEEDemIlumEmi?: number;
  CEEDemIlumLetEmi?: EnergyCertificationGrades;
  CEEDemIlumConsu?: number;
  CEEDemIlumLetConsu?: EnergyCertificationLetters;

  // TODO: Ver con back porque estos campos no aparecen en el PUT pero si en el GET
  // bathroomCount: number;
  // bedroomCount: number;
  // isEconomicExploitation: boolean;
}

/**
 * Se utiliza para el GET /{lang}/AppraisalReportAsset/{id}/UrbanisticSituation
 */
export interface AssetUrbanisticSituationModel {
  description: string;
  isAdequatedToCurrentPlanning: boolean;
  observations: string;
  urbanisticParameters: string;
  urbanisticManagement: string;
  groundQualification: GroundQualifications;
  subjectCorrespondingManagementInitiative: string;
  modeAndManagementSystem: string;
  urbanBurdens: string;
  pendingProcedures: string;
  executionDeadlinesAndConsequences: string;
  expropiation: string;
  buildability: string;
  caracteristicUse: string;
  compatibleUses: string;
  prohibitedUses: string;
  maxHeight: string;
  ocupation: string;
  setBacks: string;
  minimunPlot: string;
  exploitation: string;
  scopeSurface: string;
}

/**
 * Se utiliza para el PUT /{lang}/AppraisalReportAsset/{id}/UrbanisticSituation  IGUAL QUE EL GET SÓLO AÑADE IS TERRAIN
 */
export interface AssetUrbanisticSituationInformationEditModel {
  description: string;
  isAdequatedToCurrentPlanning: boolean;
  observations: string;
  urbanisticManagement: string;
  groundQualification: GroundQualifications;
  urbanisticParameters: string;
  subjectCorrespondingManagementInitiative: string;
  modeAndManagementSystem: string;
  urbanBurdens: string;
  pendingProcedures: string;
  executionDeadlinesAndConsequences: string;
  expropiation: string;
  buildability: string;
  caracteristicUse: string;
  compatibleUses: string;
  prohibitedUses: string;
  maxHeight: string;
  ocupation: string;
  setBacks: string;
  minimunPlot: string;
  exploitation: string;
  scopeSurface: string;
  isTerrain: boolean; // TODO: debería incluirse en este modelo ? antes estaba en el padre AssetUrbanisticSituationEditModel
}

/**
 * Se usa en el PUT
 */
export interface AppraisalAssetEnvironmentInformationModel {
  environmentPersonalOpinion: string;
  market: AssetMarketDto;
  environment: AssetEnvironmentDto;
  isEditableLocalityAndCensusSetionInformation: boolean;
  localityAndCensusSectionInformation: LocalityAndCensusSectionInformationDto;
}

/**
 * Se utiliza en el PUT
 */

export interface AssetTerrainInformationDTO {
  currentInfraestructure: string;
  pendingInfraestructure: string;
  urbanizationExecutedPercent: number;
  urbanizationCostsAndPendingCharges: string;
  existingEdifications: string;
  surfaceToDemolish: number;
  accesibility: string;
  groundLevel: GroundLevelTypes;
  planningDevelopment: TerrainPlanningDevelopmentTypes;
  managementSystem: ManagementSystemTypes;
  managementPhase: ManagementPhases;
  urbanCoreProximity: UrbanCoreProximityTypes;
  productToDevelopTypes: ProductToDevelopTypes;
  percentageOfValuedArea: number;
  notStartedStoppedUrbanization: boolean;
}

/**
 * GET
 */

export interface AssetStayAndFacilityInformationModel {
  stays: AssetStayModel[];
  distribution: string;
  hasAirConditioning: boolean;
  hasGas: boolean;
  hasPlumbingAndSanitaryEquipment: boolean;
  hasIntercom: boolean;
  hasPhone: boolean;
  hasSanitation: boolean;
  externalWoodWork: ExternalWoodworksTypes;
  facilityObservations: string;
  hasEquippedKitchen: boolean;
  hasBuiltInWardrobes: boolean;
  hasAlarmSystem: boolean;
  hasClosedSecurityCircuit: boolean;
  hotWaterType: HotWaterTypes;
  heatingType: HeatingTypes;
  propertySize: PropertySizes;
  license: LicenseTypes;
  polivalentUse: PolivalentUses;
  facade: number;
  depth: number;
  clearanceHeight: number;
  hasClothesLine: boolean;
  hasSecurityDoor: boolean;

  groundFloorSurface: number;
  basementSurface: number;
  mezzanineSurface: number;
  loftSurface: number;
  otherSurface: number;
  coefTableBackground: number;
  divisibility: boolean;
  locationStreetType: CommercialLocationStreetType;
  conditioning: CommercialConditioningType;

  raisedFloorOrFalseCeiling: boolean;
  predominantOfficeUse: boolean;

  openSpace: boolean;
  basicFacilities: boolean;
  structureLight: number;
  locationIndustrialShip: IndustrialLocationTypes;

  category: HotelCategory;
  targetType: TargetTypes;
  restaurant: boolean;
  meetingRoom: boolean;
  sportsFacilities: boolean;
  coveredTerraceArea: number;
  openTerraceArea: number;
  propertyState: PropertyStates;
  interiorExteriorType: InteriorExteriorTypes;
  orientation: OrientationTypes;
}

export interface AssetVisitFormSurfacesFacilitiesAndStaysBase {
  id: number;
  checkedSurfaces: AssetCheckedSurface;
  surfacesObservations: string;
  facilityInformation: IFacility;
  stayInformation: AssetStayInformationDto;
}

export interface AppraisalAssetVisitFormSurfacesFacilitiesAndStaysModel {
  externalWoodWork: ExternalWoodworksTypes;
  hasSanitation: boolean;
  hasPhone: boolean;
  hasSecurity: boolean;
  hasIntercom: boolean;
  hasPlumbingAndSanitaryEquipment: boolean;
  facilityObservations: string;
  hasGas: boolean;
  hasHeating: boolean;
  hasHotWater: boolean;
  stays: AssetStayModel[];
  surfacesObservations: string;
  checkedSurfaces: AssetCheckedSurface;
  id: number;
  hasAirConditioning: boolean;
  distribution: string;
}

export interface AssetCheckedSurface {
  totalPlotSurfaceId: AssetSurfaceIds;
  totalUsefulSurfaceId: AssetSurfaceIds;
  totalBuiltSurfaceWithoutCommonSurfacesId: AssetSurfaceIds;
  totalBuiltSurfaceWithCommonSurfacesId: AssetSurfaceIds;
  totalCoveredTerraceSurfaceId: AssetSurfaceIds;
  totalUncoveredTerraceSurfaceId: AssetSurfaceIds;
  totalPlotSurface: number;
  totalUsefulSurface: number;
  totalBuiltSurfaceWithoutCommonSurfaces: number;
  totalBuiltSurfaceWithCommonSurfaces: number;
  totalCoveredTerraceSurface: number;
  totalUncoveredTerraceSurface: number;
  allFields: string[];
  totalCompletedPercentage: number;
  totalRequiredCompletedPercentage: number;
}

export interface IFacility {
  hasAirConditioning: boolean;
  hasGas: boolean;
  hasPlumbingAndSanitaryEquipment: boolean;
  hasIntercom: boolean;
  hasPhone: boolean;
  hasSanitation: boolean;
  externalWoodWork: ExternalWoodworksTypes;
  facilityObservations: string;
  hasEquippedKitchen: boolean;
  hasBuiltInWardrobes: boolean;
  hasAlarmSystem: boolean;
  hasClosedSecurityCircuit: boolean;
  hotWaterType: HotWaterTypes;
  heatingType: HeatingTypes;
  propertySize: PropertySizes;
  license: LicenseTypes;
  polivalentUse: PolivalentUses;
  facade: number;
  depth: number;
  clearanceHeight: number;
  assetId: number;
  // asset:	IAsset{...}
  id: number;
}

export interface AssetStayInformationDto {
  stays: AssetStayModel[];
  distribution: string;
}

export interface AssetVisitFormBuildingInformationModel {
  surveillance: SurveillanceTypes;
  streetCategory: StreetCategoryTypes;
  hasSportsZone: boolean;
  hasSwimmingPool: boolean;
  hasGreenArea: boolean;
  hasElevator: boolean;
  buildingUse: BuildingUses;
  numberOfBuildingAccesses: number;
  totalFloorsAboveGround: number;
  totalFloorsBellowGround: number;
  conservationState: ConservationStateTypes;
  qualityOfBuilding: BuildingQualityTypes;
  lastRehabilitationYear: number;
  constructionYear: number;
  mainElements_Foundation: BuildingInformationFoundationTypes;
  mainElements_Enclosure: BuildingInformationEnclosureTypes;
  mainElements_Deck: BuildingInformationDeckTypes;
  mainElements_Structure: BuildingInformationStructureTypes;
  observations: string;
  // id:	number // TODO: esto debería ir al padre AssetTabModel
}

export interface AssetVisitFormEnvironmentModel {
  environmentPersonalOpinion: string;
}

export interface AssetExploitationDescriptionModel {
  exploitationDescription: string;
  cultivationPlantingFramework: string;
  cultivationAge: string;
  vegetativeState: string;
  managementTypology: string;
  actualProductionsInLastThreeYears: string;
  editActualProductionsInLastThreeYears: boolean;
  buildingsOwnEconomicActivity: string;
  otherBuildings: string;
  species: string;
  compositionAndSize: string;
  productionDestination: string;
  builtSurfaceOfHouseholds: number;
  builtSurfaceForAgriculturalUses: number;
}

////////////////////////////////////
// MODELOS EXTRAS - Modelos dentro
/////////////////////////////////////

export interface AssetMarketDto {
  featuresMarket: string;
  offer: string;
  demand: string;
  demandRevaluationExpectations: string;
}

export interface AssetEnvironmentDto {
  jobOccupation: string;
  characteristicAntiquity: string;
  architecturalHomogeneity: string;
  infrastructures: string;
  equipmentAndServices: string;
  communications: string;
  parkings: QuantifyOptions;
  buildingDevelopment: string;
  renovation: string;
  environmentSignificance: EnvironmentSignificance;
  locationComparedToSameMunicipality: LocationComparedToSameMunicipality;
  locationComparedToSameZone: LocationComparedToSameZone;
  locationComparedToSameBuildingElements: LocationComparedToSameBuildingElements;
  socioEconomicLevel: SocioEconomicLevels;
  pendingInfrastructuresPercentage: number;
  environmentConsolidationDegreePercentage: number;

  publicTransportation: QuantifyOptions;
  equipmentEndowments: QuantifyOptions;
  businessEnvironment: EnvironmentOptions;
  tertiaryEnvironment: EnvironmentOptions;
  industrialEnvironment: IndustrialEnvironments;
  marketingPropertyDifficulties: boolean;
}

export interface LocalityAndCensusSectionInformationDto {
  localityTypeOfCore: string;
  localityPopulation: number;
  localityPopulationEvolution: number;
  localityUnemploymentRate: number;
  localityAvgAnnualIncome: number;
  censusSectionTypeOfCore: string;
}

export interface ILocalityAndCensusSectionInformation {
  localityTypeOfCore: string;
  localityPopulation: number;
  localityPopulationEvolution: number;
  localityUnemploymentRate: number;
  localityAvgAnnualIncome: number;
  censusSectionTypeOfCore: string;
}

export interface AssetUniqueNameModel {
  uniqueAssetName: string;
  cadastralReference: string;
  idufir: string;
  registeredPropertyNumber: string;
  streetName: string;
  streetNumber: string;
  portal: string;
  stairs: string;
  floor: FloorTypes;	
  gate: string;
  cadastralSurfaces: AssetSurfaceData;
  registeredSurfaces: AssetSurfaceData;
  checkedSurfaces: AssetSurfaceData;
}
