export const enLang = {
  languageKey: 'en',
  translates: {
    primeng: {
      startsWith: 'Starts with',
      contains: 'Contains',
      notContains: 'Does not contain',
      endsWith: 'Ends with',
      equals: 'Is equal to',
      notEquals: 'Is not equal to',
      noFilter: 'No Filter',
      lt: 'Less than',
      lte: 'Less than or equal to',
      gt: 'More than',
      gte: 'More than or equal to',
      is: 'Is',
      isNot: 'Is not',
      before: 'Before',
      after: 'After',
      clear: 'Delete',
      apply: 'Apply',
      matchAll: 'Match All',
      matchAny: 'Match Any',
      addRule: 'Add rule',
      removeRule: 'Delete rule',
      accept: 'Yes',
      reject: 'No',
      choose: 'Select',
      upload: 'Upload',
      cancel_1: 'Cancel',
      dayNames: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
      dayNamesShort: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
      dayNamesMin: ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'],
      monthNames: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
      monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
      today: 'Today',
      weekHeader: 'Wk',
      weak: 'Week',
      medium: 'Average',
      strong: 'Strong',
      passwordPrompt: 'Introduce password',
      emptyMessage: 'No results',
      emptyFilterMessage: 'No results',
    },
    aggrid: {
      // Set Filter
      selectAll: '(Select All)',
      selectAllSearchResults: '(Select All Search Results)',
      searchOoo: 'Search...',
      blanks: '(Blanks)',
      noMatches: 'No matches',

      // Number Filter & Text Filter
      filterOoo: 'Filter...',
      equals: 'Equals',
      notEqual: 'Not equal',
      blank: 'Blank',
      notBlank: 'Not blank',
      empty: 'Choose One',

      // Number Filter
      lessThan: 'Less than',
      greaterThan: 'Greater than',
      lessThanOrEqual: 'Less than or equal',
      greaterThanOrEqual: 'Greater than or equal',
      inRange: 'In range',
      inRangeStart: 'from',
      inRangeEnd: 'to',

      // Text Filter
      contains: 'Contains',
      notContains: 'Not contains',
      startsWith: 'Starts with',
      endsWith: 'Ends with',

      // Date Filter
      dateFormatOoo: 'yyyy-mm-dd',

      // Filter Conditions
      andCondition: 'AND',
      orCondition: 'OR',

      // Filter Buttons
      applyFilter: 'Apply',
      resetFilter: 'Reset',
      clearFilter: 'Clear',
      cancelFilter: 'Cancel',

      // Filter Titles
      textFilter: 'Text Filter',
      numberFilter: 'Number Filter',
      dateFilter: 'Date Filter',
      setFilter: 'Set Filter',

      // Side Bar
      columns: 'Columns',
      filters: 'Filters',

      // columns tool panel
      pivotMode: 'Pivot Mode',
      groups: 'Row Groups',
      rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
      values: 'Values',
      valueColumnsEmptyMessage: 'Drag here to aggregate',
      pivots: 'Column Labels',
      pivotColumnsEmptyMessage: 'Drag here to set column labels',

      // Header of the Default Group Column
      group: 'Group',

      // Row Drag
      rowDragRows: 'rows',

      // Other
      loadingOoo: 'Loading...',
      noRowsToShow: 'No Rows To Show',
      enabled: 'Enabled',

      // Menu
      pinColumn: 'Pin Column',
      pinLeft: 'Pin Left',
      pinRight: 'Pin Right',
      noPin: 'No Pin',
      valueAggregation: 'Value Aggregation',
      autosizeThiscolumn: 'Autosize This Column',
      autosizeAllColumns: 'Autosize All Columns',
      groupBy: 'Group by',
      ungroupBy: 'Un-Group by',
      addToValues: 'Add ${variable} to values',
      removeFromValues: 'Remove ${variable} from values',
      addToLabels: 'Add ${variable} to labels',
      removeFromLabels: 'Remove ${variable} from labels',
      resetColumns: 'Reset Columns',
      expandAll: 'Expand All',
      collapseAll: 'Close All',
      copy: 'Copy',
      ctrlC: 'Ctrl+C',
      copyWithHeaders: 'Copy With Headers',
      copyWithGroupHeaders: 'Copy with Group Headers',
      paste: 'Paste',
      ctrlV: 'Ctrl+V',
      export: 'Export',
      csvExport: 'CSV Export',
      excelExport: 'Excel Export',

      // Enterprise Menu Aggregation and Status Bar
      sum: 'Sum',
      min: 'Min',
      max: 'Max',
      none: 'None',
      count: 'Count',
      avg: 'Average',
      filteredRows: 'Filtered',
      selectedRows: 'Selected',
      totalRows: 'Total Rows',
      totalAndFilteredRows: 'Rows',
      more: 'More',
      to: 'to',
      of: 'of',
      page: 'Page',
      nextPage: 'Next Page',
      lastPage: 'Last Page',
      firstPage: 'First Page',
      previousPage: 'Previous Page',

      // Pivoting
      pivotColumnGroupTotals: 'Total',

      // Enterprise Menu (Charts)
      pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
      pivotChart: 'Pivot Chart',
      chartRange: 'Chart Range',

      columnChart: 'Column',
      groupedColumn: 'Grouped',
      stackedColumn: 'Stacked',
      normalizedColumn: '100% Stacked',

      barChart: 'Bar',
      groupedBar: 'Grouped',
      stackedBar: 'Stacked',
      normalizedBar: '100% Stacked',

      pieChart: 'Pie',
      pie: 'Pie',
      doughnut: 'Doughnut',

      line: 'Line',

      xyChart: 'X Y (Scatter)',
      scatter: 'Scatter',
      bubble: 'Bubble',

      areaChart: 'Area',
      area: 'Area',
      stackedArea: 'Stacked',
      normalizedArea: '100% Stacked',

      histogramChart: 'Histogram',

      combinationChart: 'Combination',
      columnLineCombo: 'Column & Line',
      AreaColumnCombo: 'Area & Column',

      // Charts
      pivotChartTitle: 'Pivot Chart',
      rangeChartTitle: 'Range Chart',
      settings: 'Settings',
      data: 'Data',
      format: 'Format',
      categories: 'Categories',
      defaultCategory: '(None)',
      series: 'Series',
      xyValues: 'X Y Values',
      paired: 'Paired Mode',
      axis: 'Axis',
      navigator: 'Navigator',
      color: 'Color',
      thickness: 'Thickness',
      xType: 'X Type',
      automatic: 'Automatic',
      category: 'Category',
      number: 'Number',
      time: 'Time',
      autoRotate: 'Auto Rotate',
      xRotation: 'X Rotation',
      yRotation: 'Y Rotation',
      ticks: 'Ticks',
      width: 'Width',
      height: 'Height',
      length: 'Length',
      padding: 'Padding',
      spacing: 'Spacing',
      chart: 'Chart',
      title: 'Title',
      titlePlaceholder: 'Chart title - double click to edit',
      background: 'Background',
      font: 'Font',
      top: 'Top',
      right: 'Right',
      bottom: 'Bottom',
      left: 'Left',
      labels: 'Labels',
      size: 'Size',
      minSize: 'Minimum Size',
      maxSize: 'Maximum Size',
      legend: 'Legend',
      position: 'Position',
      markerSize: 'Marker Size',
      markerStroke: 'Marker Stroke',
      markerPadding: 'Marker Padding',
      itemSpacing: 'Item Spacing',
      itemPaddingX: 'Item Padding X',
      itemPaddingY: 'Item Padding Y',
      layoutHorizontalSpacing: 'Horizontal Spacing',
      layoutVerticalSpacing: 'Vertical Spacing',
      strokeWidth: 'Stroke Width',
      lineDash: 'Line Dash',
      offset: 'Offset',
      offsets: 'Offsets',
      tooltips: 'Tooltips',
      callout: 'Callout',
      markers: 'Markers',
      shadow: 'Shadow',
      blur: 'Blur',
      xOffset: 'X Offset',
      yOffset: 'Y Offset',
      lineWidth: 'Line Width',
      normal: 'Normal',
      bold: 'Bold',
      italic: 'Italic',
      boldItalic: 'Bold Italic',
      predefined: 'Predefined',
      fillOpacity: 'Fill Opacity',
      strokeOpacity: 'Line Opacity',
      histogramBinCount: 'Bin count',
      columnGroup: 'Column',
      barGroup: 'Bar',
      pieGroup: 'Pie',
      lineGroup: 'Line',
      scatterGroup: 'X Y (Scatter)',
      areaGroup: 'Area',
      histogramGroup: 'Histogram',
      combinationGroup: 'Combination',
      groupedColumnTooltip: 'Grouped',
      stackedColumnTooltip: 'Stacked',
      normalizedColumnTooltip: '100% Stacked',
      groupedBarTooltip: 'Grouped',
      stackedBarTooltip: 'Stacked',
      normalizedBarTooltip: '100% Stacked',
      pieTooltip: 'Pie',
      doughnutTooltip: 'Doughnut',
      lineTooltip: 'Line',
      groupedAreaTooltip: 'Area',
      stackedAreaTooltip: 'Stacked',
      normalizedAreaTooltip: '100% Stacked',
      scatterTooltip: 'Scatter',
      bubbleTooltip: 'Bubble',
      histogramTooltip: 'Histogram',
      columnLineComboTooltip: 'Column & Line',
      areaColumnComboTooltip: 'Area & Column',
      customComboTooltip: 'Custom Combination',
      noDataToChart: 'No data available to be charted.',
      pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
      chartSettingsToolbarTooltip: 'Menu',
      chartLinkToolbarTooltip: 'Linked to Grid',
      chartUnlinkToolbarTooltip: 'Unlinked from Grid',
      chartDownloadToolbarTooltip: 'Download Chart',
      seriesChartType: 'Series Chart Type',
      seriesType: 'Series Type',
      secondaryAxis: 'Secondary Axis',

      // ARIA
      ariaChecked: 'checked',
      ariaColumn: 'Column',
      ariaColumnGroup: 'Column Group',
      ariaColumnList: 'Column List',
      ariaColumnSelectAll: 'Toggle Select All Columns',
      ariaDateFilterInput: 'Date Filter Input',
      ariaDefaultListName: 'List',
      ariaFilterColumnsInput: 'Filter Columns Input',
      ariaFilterFromValue: 'Filter from value',
      ariaFilterInput: 'Filter Input',
      ariaFilterList: 'Filter List',
      ariaFilterToValue: 'Filter to value',
      ariaFilterValue: 'Filter Value',
      ariaFilteringOperator: 'Filtering Operator',
      ariaHidden: 'hidden',
      ariaIndeterminate: 'indeterminate',
      ariaInputEditor: 'Input Editor',
      ariaMenuColumn: 'Press CTRL ENTER to open column menu.',
      ariaRowDeselect: 'Press SPACE to deselect this row',
      ariaRowSelectAll: 'Press Space to toggle all rows selection',
      ariaRowToggleSelection: 'Press Space to toggle row selection',
      ariaRowSelect: 'Press SPACE to select this row',
      ariaSearch: 'Search',
      ariaSortableColumn: 'Press ENTER to sort',
      ariaToggleVisibility: 'Press SPACE to toggle visibility',
      ariaUnchecked: 'unchecked',
      ariaVisible: 'visible',
      ariaSearchFilterValues: 'Search filter values',

      // ARIA Labels for Drop Zones

      ariaRowGroupDropZonePanelLabel: 'Row Groups',
      ariaValuesDropZonePanelLabel: 'Values',
      ariaPivotDropZonePanelLabel: 'Column Labels',
      ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
      ariaDropZoneColumnValueItemDescription: 'Press ENTER to change the aggregation type',
      ariaDropZoneColumnGroupItemDescription: 'Press ENTER to sort',
      // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
      ariaDropZoneColumnComponentAggFuncSeperator: ' of ',
      ariaDropZoneColumnComponentSortAscending: 'ascending',
      ariaDropZoneColumnComponentSortDescending: 'descending',

      // ARIA Labels for Dialogs
      ariaLabelColumnMenu: 'Column Menu',
      ariaLabelCellEditor: 'Cell Editor',
      ariaLabelDialog: 'Dialog',
      ariaLabelSelectField: 'Select Field',
      ariaLabelTooltip: 'Tooltip',
      ariaLabelContextMenu: 'Context Menu',
      ariaLabelSubMenu: 'SubMenu',
      ariaLabelAggregationFunction: 'Aggregation Function',

      // Number Format (Status Bar, Pagination Panel)
      thousandSeparator: ',',
      decimalSeparator: '.',
    },
    /**
     * ORDER ASCENDING FROM HERE
     */
    'bde-filter': 'BoS',
    'billing-info': 'Billing information',
    'code-management': 'Code management',
    'commercial-manager-user': 'Commercial manager user',
    'delivered-to-client': 'Delivered to the client',
    'payment-summary': 'Payment summary',
    'process-manager-user': 'Process manager user',
    'quality-control': 'Quality control',
    'recived-from-register': 'Received from property register',
    'requester-companies': 'Prescribers',
    'sent-to-register': 'Sent to property register',
    'simple-notes': 'Simple notes',
    'other-orders': 'Other works',
    'other-works': 'Other works',
    avm: 'AVM',
    'supplier-companies': 'Appraisal companies',
    'technical-manager-user': 'Appraiser manager user',
    'users-credentials': 'Users with credentials',
    'validation-finished': 'Validation finished',
    academy: 'Academy',
    acceptancePending: 'Pending acceptance',
    AccessFloor: 'Building accesses',
    ACCOUNT_MANAGEMENT_accountManagement: 'License settings',
    ACCOUNT_MANAGEMENT_active: 'Active',
    ACCOUNT_MANAGEMENT_activePlan: 'Active plan',
    ACCOUNT_MANAGEMENT_availableContractPlans: 'Do you want to contract a plan? Check out our available plans!',
    ACCOUNT_MANAGEMENT_availablePlans: 'Available plans',
    ACCOUNT_MANAGEMENT_billingData: 'Billing info',
    ACCOUNT_MANAGEMENT_buyLicense: 'Contract',
    ACCOUNT_MANAGEMENT_cancelLicense: 'Cancel licence',
    ACCOUNT_MANAGEMENT_cancelLicenseDowngrade: 'Cancel update',
    ACCOUNT_MANAGEMENT_changePlanMessage: 'Your licence will be updated. The charge will be made as per the payment method indicated in your profile.',
    ACCOUNT_MANAGEMENT_connectionsNetwork: 'My network of connections',
    ACCOUNT_MANAGEMENT_contractPlanMessage: 'Are you sure you want to contract this plan?',
    ACCOUNT_MANAGEMENT_data: 'Data',
    ACCOUNT_MANAGEMENT_expired: 'Expired',
    ACCOUNT_MANAGEMENT_finished: 'It ended on',
    ACCOUNT_MANAGEMENT_invoices: 'Billing history',
    ACCOUNT_MANAGEMENT_invoicesHistory: 'Invoice history',
    ACCOUNT_MANAGEMENT_licenseDetail: 'Licence details',
    ACCOUNT_MANAGEMENT_monthActiveUsers: 'active users this month',
    ACCOUNT_MANAGEMENT_moreInfo: 'Further information',
    ACCOUNT_MANAGEMENT_nextBilling: 'Next invoice to be issued on',
    ACCOUNT_MANAGEMENT_nextBillingEstimation: 'Next payment estimate',
    ACCOUNT_MANAGEMENT_paymentHistory: 'Payment history',
    ACCOUNT_MANAGEMENT_paymentMethods: 'Payment method',
    ACCOUNT_MANAGEMENT_paymentPlan: 'Payment plan',
    ACCOUNT_MANAGEMENT_payMethodToAddAppraisalMessage: 'Remember that to register a new assessment you must have entered a payment method.',
    ACCOUNT_MANAGEMENT_priceNotIncludeVAT: '*The price does not include VAT.',
    ACCOUNT_MANAGEMENT_profile: 'Profile Settings',
    ACCOUNT_MANAGEMENT_reportCustomization: 'Report settings',
    ACCOUNT_MANAGEMENT_rollbackPlanMessage: 'Are you sure you want to cancel the plan update?',
    ACCOUNT_MANAGEMENT_totalWithoutTaxes: 'Total Without Taxes',
    ACCOUNT_MANAGEMENT_totalWithTaxes: 'Total with Taxes',
    ACCOUNT_MANAGEMENT_trialPeriod: 'Trial period',
    ACCOUNT_MANAGEMENT_trialPeriodIsActive: 'The licence trial period is active.',
    ACCOUNT_MANAGEMENT_trialPeriodIsExpired: 'Your trial licence has expired, please introduce a valid payment method and contract one of your plans to continue using Tasafy.',
    ACCOUNT_MANAGEMENT_willEnd: 'It ends on',
    ACCOUNT_MANAGEMENT_willNotLongerStayActive: 'Will become inactive on',
    ACCOUNT_MANAGEMENT_willStartActive: 'Will come into force on',
    account_number_for_payments: 'Bank Account Number for payment',
    ActiveLicensePlan: 'Current plan',
    actives: 'Assets',
    ADD_ASSET_addAsset: 'Add asset',
    ADD_ASSET_addNewAssetToRepositoryOption: 'Select this option if you want to add a non-existing element to the asset repository',
    ADD_ASSET_impossibleToMap: 'The cadastre information could not be mapped, some data must be filled in manually',
    ADD_ASSET_modifyStructure: 'Amend structure of object of the appraisal',
    ADD_ASSET_searchAssetInRepositoryOption: 'Select this option to search for an element in the asset repository',
    ADD_ASSET_selectAsset: 'Select asset',
    add_backtesting: 'Add Backtesting',
    add_batch: 'Add batch',
    ADD_NEW_ASSET_FORM_add: 'Add',
    ADD_NEW_ASSET_FORM_addByCadastralRefError: 'No asset was found',
    ADD_NEW_ASSET_FORM_addByCadastralRefSuccess: 'Register information has been obtained satisfactorily',
    ADD_NEW_ASSET_FORM_addManualy: 'Add manually',
    ADD_NEW_ASSET_FORM_externalSource: 'External source',
    ADD_NEW_ASSET_FORM_Block: 'Block',
    ADD_NEW_ASSET_FORM_book: 'Book',
    ADD_NEW_ASSET_FORM_cadastralReference: 'Land registry reference',
    ADD_NEW_ASSET_FORM_ConstructionYear: 'Age',
    ADD_NEW_ASSET_FORM_coordinatesOption: 'COORDINATES',
    ADD_NEW_ASSET_FORM_Floor: 'Floor',
    ADD_NEW_ASSET_FORM_Gate: 'Door',
    ADD_NEW_ASSET_FORM_Identification: 'Identification',
    ADD_NEW_ASSET_FORM_idufir: 'CRU (IDUFIR)',
    ADD_NEW_ASSET_FORM_industrialEstate: 'Polygon',
    ADD_NEW_ASSET_FORM_inscription: 'Entry',
    ADD_NEW_ASSET_FORM_location: 'Location',
    ADD_NEW_ASSET_FORM_municipality: 'Municipality',
    ADD_NEW_ASSET_FORM_number: 'Number',
    ADD_NEW_ASSET_FORM_paper: 'Sheet',
    ADD_NEW_ASSET_FORM_place: 'Place',
    ADD_NEW_ASSET_FORM_plot: 'Plot',
    ADD_NEW_ASSET_FORM_polygonPlotOption: 'POLYGON/PLOT',
    ADD_NEW_ASSET_FORM_postalCode: 'Post code',
    ADD_NEW_ASSET_FORM_propertyRegister: 'Property register',
    ADD_NEW_ASSET_FORM_propertyRegisterNumber: 'Property register number',
    ADD_NEW_ASSET_FORM_province: 'Province',
    ADD_NEW_ASSET_FORM_realState: 'Estate',
    ADD_NEW_ASSET_FORM_region: 'Region',
    ADD_NEW_ASSET_FORM_Stairs: 'Staircase',
    ADD_NEW_ASSET_FORM_streeDoseNotExist: 'Not on the street map',
    ADD_NEW_ASSET_FORM_streetName: 'Street name',
    ADD_NEW_ASSET_FORM_streetNumberOption: 'STREET/NUMBER',
    ADD_NEW_ASSET_FORM_Surface: 'Surface',
    ADD_NEW_ASSET_FORM_SurfaceAndAntiquity: 'Surface and antiquity',
    ADD_NEW_ASSET_FORM_Typologies: 'Type',
    ADD_NEW_ASSET_FORM_Use: 'Use',
    ADD_NEW_ASSET_FORM_volume: 'Volume',
    add_new_prescriptor: '+ Add new prescriber',
    add_new_professional: '+ Add new professional',
    add_new: 'Add new',
    add_prescriber: 'Add prescriber',
    add: 'Add',
    addAppraisalToInvoiceWarningAutoGlobal: 'The value of the invoice will be indicated after the appraisal is selected.',
    addAppraisalToInvoiceWarningAutoIndividual: 'The value of the invoice will be the sum of the confirmed values of the appraisals selected.',
    addAppraisalToInvoiceWarningManual: 'The invoice details will be included manually after the appraisal is selected.',
    addCustomer: 'Add Customer',
    AddedDateTime: 'Creation date',
    addedDateTime: 'Fecha de creación',
    addedUserFullName: 'Creado por',
    addIncompatibilities: 'Add incompatibilities',
    address: 'Address',
    Address: 'Address',
    AddressString: 'Address',
    addValorationToRequestInvoice: 'Add appraisal to invoice',
    ADMIN_DASHBOARD_activeLicensees: 'Active licenses',
    ADMIN_DASHBOARD_dataReport: 'Data report',
    ADMIN_DASHBOARD_finishedAppraisals: 'Completed appraisals',
    ADMIN_DASHBOARD_inactiveLicensees: 'Inactive licenses',
    ADMIN_DASHBOARD_licensees: 'Total licenses',
    ADMIN_DASHBOARD_licensesPerLicense: 'Licences per contracted plan',
    ADMIN_DASHBOARD_numberOfActiveLicensees: 'No. active licenses',
    ADMIN_DASHBOARD_numberOfInactiveLicensees: 'No. inactive licenses',
    ADMIN_DASHBOARD_numberOfLicensees: 'No. total licenses',
    ADMIN_DASHBOARD_recordsNumber: 'Number of registers',
    ADMIN_DASHBOARD_reportsPerLicense: 'Reports per plan and licence',
    ADMIN_DASHBOARD_startedAppraisals: 'Appraisals started',
    ADMIN_signInDate: 'Sign in date',
    admin: 'Administrator',
    airConditioning: 'Air conditioning',
    alive: 'Active registrations',
    all: 'All',
    All: 'All',
    allInvoiced: 'Invoiced in full',
    allowed_roles: 'Permitted roles',
    allTranslatesEditedCorrectly: 'All transltes edited correctly',
    amount: 'Total',
    amountsSummary: 'Summary of values',
    an_error_has_ocurred: 'An error has occurred',
    APPRAISAL_addNewPortfolio: 'Add portfolio',
    APPRAISAL_allAssets: 'All assets',
    APPRAISAL_appraisalCancelled: 'Assignment cancelled',
    APPRAISAL_appraisalData: 'Appraisal details',
    APPRAISAL_DASHBOARD_advancedFilters: 'Filters',
    APPRAISAL_DASHBOARD_claimsInAppraisalToBeValidated: 'Claims in reports to be validated',
    APPRAISAL_DASHBOARD_claimsInAppraisalToRecive: 'Claims in reports to be received',
    APPRAISAL_DASHBOARD_filesToModifie: 'Cases to be validated',
    APPRAISAL_DASHBOARD_validationTime: 'Validation time',
    APPRAISAL_DASHBOARD_filesToRecive: 'Cases to be received',
    APPRAISAL_DASHBOARD_managementTime: 'Management time',
    APPRAISAL_DASHBOARD_FILTER_advancedFilters: 'Filters',
    APPRAISAL_DASHBOARD_filterBy: 'Filter by:',
    APPRAISAL_DASHBOARD_goToClaims: 'Go to claims',
    APPRAISAL_DASHBOARD_goToIncidents: 'Go to incidents',
    APPRAISAL_DASHBOARD_goToPendingToDeliverReport: 'Go to pending delivery of report',
    APPRAISAL_DASHBOARD_goToPendingToSchedule: 'Go to pending scheduling',
    APPRAISAL_DASHBOARD_greeting: 'Good morning,',
    APPRAISAL_DASHBOARD_incidenceInReportsToBeValidated: 'Incidents in reports to be validated',
    APPRAISAL_DASHBOARD_lateReportDelivery: 'Report pending delivery outside deadline',
    APPRAISAL_DASHBOARD_lateSchedulePending: 'Commissions pending scheduling of visit outside deadline',
    APPRAISAL_DASHBOARD_recordState: 'Current status of cases',
    APPRAISAL_DASHBOARD_reportDeliverPending: 'Pending delivery of report',
    APPRAISAL_DASHBOARD_resolvedByAppraiser: 'resolved by appraiser',
    APPRAISAL_DASHBOARD_reviewsInAppraisalToBeValidated: 'Reviews in reports to be validated',
    APPRAISAL_DASHBOARD_reviewsInAppraisalToRecive: 'Reviews in reports to be received',
    APPRAISAL_DASHBOARD_reviewSummary: 'Reports with reviews',
    APPRAISAL_DASHBOARD_schedulePending: 'Pending scheduling',
    APPRAISAL_DASHBOARD_summaryOfClaims: 'Reports with claims',
    APPRAISAL_DASHBOARD_summaryOfFinishedFiles: 'Summary of complete cases',
    APPRAISAL_DASHBOARD_summaryOfIncidence: 'Reports with incidents',
    APPRAISAL_DASHBOARD_summaryOfUnmodifiedFiles: 'Summary of incomplete cases',
    QUALITY_DASHBOARD_summaryOfUnmodifiedFiles: 'Summary of incomplete cases',
    QUALITY_DASHBOARD_qCPending: 'Pendientes de control de calidad',
    APPRAISAL_DASHBOARD_ToRecive: 'To be received',
    APPRAISAL_DASHBOARD_toResolve: 'To be resolved',
    APPRAISAL_DASHBOARD_totalOrders: 'Total works: ',
    APPRAISAL_DASHBOARD_toValidate: 'To be validated',
    APPRAISAL_DETAILS_orderCanceled: 'Assignment cancelled',
    APPRAISAL_disclaimer: 'Please remember that as the person requesting the appraisal, you are responsible for having informed your customer of the details transferred to Instituto de Valoraciones S.A. with a view to the contracted service being performed.',
    APPRAISAL_lastNotificationError: 'Last submission KO',
    APPRAISAL_lastNotificationSuccesful: 'Last submission OK',
    APPRAISAL_REPORT_createTerrainCluster: 'Create cluster of plots',
    APPRAISAL_REPORT_dateAndTime: 'Date and time',
    APPRAISAL_REPORT_fullName: 'Full name',
    APPRAISAL_REPORT_infoData: 'Report data',
    APPRAISAL_VALUATION_addWitness: 'Add witness',
    APPRAISAL_VALUATION_createWitness: 'Create witness',
    APPRAISAL_VALUATION_addMarketStudy: 'Add market research',
    APPRAISAL_VALUATION_NotMarketStudyList: 'There is no market research to be able to add in this method',
    APPRAISAL_VALUATION_removeWitnessList: 'Remove from list',
    APPRAISAL_VALUATION_totalInfoMessage: 'this value is obtained from the maximum legal method',
    APPRAISAL_VALUATION_marketStudy: 'Market study',
    APPRAISAL_VALUATION_editMarketStudyTitle: 'Market study modification',
    APPRAISAL_VALUATION_editMarketStudySubtitle: 'The modifications made in the method give rise to a new market study. Do you want these modifications to apply to the existing market study or trigger a new one?',
    APPRAISAL_VALUATION_editMarketStudySubtitleOnlyCreate: 'Modifications made to the method result in a new market study.',
    APPRAISAL_VALUATION_editMarketStudyConfirm: 'Modify existing',
    APPRAISAL_VALUATION_editMarketStudyNew: 'Create new',
    APPRAISAL_VALUATION_saveMethodInfo: 'To be able to save the method you must add at least one witness.',
    APPRAISAL_VALUATION_infoMigration: '* This method has been generated from a migration and cannot be edited',
    APPRAISAL_value: 'Appraisal value',
    APPRAISAL_valueWithSuffix: 'Appraisal value ({{currency}})',
    appraisalAverageTimeDashboardTitle: 'Average time between stages in completed cases',
    appraisalInExchange: 'This appraisal is currently in the assignment exchange.',
    APPRAISALS_add_new_appraisal: 'Add new assignment',
    APPRAISALS_addDocument: 'Add document',
    APPRAISALS_addedMaxDateTime: 'End date added',
    APPRAISALS_addedMinDateTime: 'Start date added',
    APPRAISALS_addInvoiceToProfessional: 'Add invoice for specialist',
    APPRAISALS_addMessage: 'Add message',
    APPRAISALS_addNewAsset: '+ Add new asset',
    APPRAISALS_addNewCustomer: '+ Add new customer',
    APPRAISALS_addNewPrescriber: '+ Add new prescriber',
    APPRAISALS_addProcessManager: 'Assign process manager',
    APPRAISALS_address: 'Address',
    APPRAISALS_addTechnicalManager: 'Assign Technical Department Manager',
    APPRAISALS_addVisitDate: 'Add visit date',
    APPRAISALS_allFields: 'All fields filled in to',
    APPRAISALS_amount: 'Amount',
    APPRAISALS_appraisalDocuments: 'Appraisal documents',
    APPRAISALS_appraisalIsInExchange: 'This appraisal is currently in the assignment exchange.',
    APPRAISALS_appraisalStays: 'Rooms as part of the appraisal',
    APPRAISALS_appraiser: 'Appraiser',
    APPRAISALS_appraiserAllDataSentDate: 'Delivery date',
    APPRAISALS_appraiserDatasent: 'Report delivered',
    APPRAISALS_appraiserDataSent: 'Report delivered',
    APPRAISALS_appraiserDataSentDeadline: 'Deadline for delivering report',
    APPRAISALS_appraiserUserFullName: 'Appraiser',
    APPRAISALS_asset: 'Asset',
    APPRAISALS_assetAssign: 'Assign asset',
    APPRAISALS_assetDocuments: 'Asset documents',
    APPRAISALS_importDocuments: 'Select all documentation',
    APPRAISALS_dataDocuments: 'Date of issuen',
    APPRAISALS_orderDocuments: '*It will not be necessary to upload the documents in an orderly manner, since they will have a pre-established order by the system in the report according to the Type of document chosen.',
    APPRAISALS_assetStays: 'Asset rooms',
    APPRAISALS_assignedUser: 'Assigned user',
    APPRAISALS_assignmentBag: 'Assignment exchange',
    APPRAISALS_assignmentBagStatus: 'Status in the exchange',
    APPRAISALS_assignmentBagTimeLeft: 'Remaining time in the exchange',
    APPRAISALS_assignmentBagTimeLeftInMin: 'Remaining time in the exchange (minutes)',
    APPRAISALS_attachDocument: '+ Attach',
    APPRAISALS_attaching: 'Attaching...',
    APPRAISALS_basicFilters: 'Basic filters',
    APPRAISALS_billedAt: 'Invoiced on:',
    APPRAISALS_billFuture: 'To be invoiced on:',
    APPRAISALS_budgetNumber: 'Quote number',
    APPRAISALS_by: 'by',
    APPRAISALS_cancel: 'Cancel',
    APPRAISALS_cancelAppraisal: 'Cancel assignment',
    APPRAISALS_cancelationReason: 'Reason for cancellation',
    APPRAISALS_cancelled: 'Cancelled',
    APPRAISALS_cancelledDate: 'Cancellation date',
    APPRAISALS_changeUser: 'Change user',
    APPRAISALS_chooseAppraiser: 'Select appraiser',
    APPRAISALS_chooseCustomer: 'Select a customer',
    APPRAISALS_chooseExecutionMode: 'Method for performing the appraisal',
    APPRAISALS_chooseProcessManager: 'Choose process manager user',
    APPRAISALS_chooseTechnicalManager: 'Choose appraiser manager user',
    APPRAISALS_claimDate: 'Claim date',
    APPRAISALS_claims: 'Claims',
    APPRAISALS_clientComplaint: 'Customer Claim',
    APPRAISALS_coincidence: 'Match',
    APPRAISALS_comesFromRequest: 'Associated with a request?',
    APPRAISALS_commercialTechnicalsFee: 'Fees charged',
    APPRAISALS_confirm_expenses: 'Confirm costs',
    APPRAISALS_confirmCoeficientChange: 'If you amend the homogenisation type, you will lose the data associated with it to date. Do you want to proceed?',
    APPRAISALS_confirmed_amount: 'Confirmed amounts/costs',
    APPRAISALS_contactVisitData: 'Contact details for the visit',
    APPRAISALS_copyAppraisal: 'Copy assignment',
    APPRAISALS_createdAt: 'Created at',
    APPRAISALS_createIncident: 'Create incident',
    APPRAISALS_creationDate: 'Creation date',
    APPRAISALS_pointerTerm: 'Term note',
    APPRAISALS_executionNote: 'Execution note',
    APPRAISALS_customer: 'Customer',
    APPRAISALS_customerDeliverables: 'Deliverables to customer',
    APPRAISALS_customerName: 'Name/Company name',
    APPRAISALS_date: 'Date',
    APPRAISALS_dateDataSent: 'Report delivered',
    APPRAISALS_dateOfIssue: 'Issue date',
    APPRAISALS_dateSendDataDeadline: 'Deadline for delivering report',
    APPRAISALS_dateVisitScheduleDeadline: 'Scheduling deadline',
    APPRAISALS_deadlineDate: 'Completion date',
    APPRAISALS_delete: 'Delete',
    APPRAISALS_deleteMessage: 'Are you sure you want to delete the message?',
    APPRAISALS_deliverDeadlineToCustomer: 'Customer delivery deadline',
    APPRAISALS_deliveredToClient: 'Delivered to customer',
    APPRAISALS_deliverToCustomer: 'Send to customer',
    APPRAISALS_description: 'Description',
    APPRAISALS_detail: 'Details',
    APPRAISALS_documentAddedTime: 'Added on',
    APPRAISALS_documents: 'Documents',
    APPRAISALS_documentType: 'Document type',
    APPRAISALS_documentTypes: 'Document types',
    APPRAISALS_download: 'Download',
    APPRAISALS_downloadAll: 'Download all',
    APPRAISALS_downloading: 'Downloading',
    APPRAISALS_downloadFileCadastral: 'Download cadastral file',
    APPRAISALS_downloadFileCadastralSuccess: 'The cadastral file has been downloaded and attached to the asset documentation',
    APPRAISALS_downloadFileCadastralError: 'Failed to download and attach the document',
    APPRAISALS_eco8052003: 'Eco 805/2003 compliant',
    APPRAISALS_edit: 'Edit',
    APPRAISALS_editMessage: 'Edit the message',
    APPRAISALS_email: 'Email',
    APPRAISALS_executed_with_tasafy: 'Select this option to perform the appraisal using Tasafy',
    APPRAISALS_expected_billing_date: 'Expected billing date',
    APPRAISALS_externalReference: 'External reference number',
    APPRAISALS_externalTech: 'Appraiser',
    APPRAISALS_fieldName: 'Field name',
    APPRAISALS_fileName: 'Document',
    APPRAISALS_fileNumber: 'Case number',
    APPRAISALS_finished: 'Complete',
    APPRAISALS_finishedDate: 'Completion date',
    APPRAISALS_hasPrescriptor: 'Assigned prescriber',
    APPRAISALS_hasRelationshipWithAppraisal: 'Is there a relationship with another appraisal?',
    APPRAISALS_hasRelationshipWithAppraisalAdditionalOrders: 'Is there a connection with other work?',
    APPRAISALS_hasRelationship: 'Is there a relationship?',
    APPRAISALS_homogeneousCoefficientType: 'Approval type',
    APPRAISALS_importReportFrom: 'Import report from...',
    APPRAISALS_importReportFromSubTitle: 'Only one file can be selected. The asset structure and all data entered in the report, except documents and photos, will be copied.',
    APPRAISALS_importFormDataPick: 'Import from data collection',
    APPRAISALS_infoButtonDataPick: '*The import from data collection button will be enabled when the data collection is validated or finished.',
    APPRAISALS_importStateDataPick: '*When the documentation is imported, the status of the file will automatically change to Visit completed.',
    APPRAISALS_importStateInfo: '*The linked data collection files are not validated or finalized.',
    APPRAISALS_infoNotDocument: '“In the data collection there is no attached documentation.”',
    APPRAISALS_incidenceCreationDate: 'Incident creation date',
    APPRAISALS_incidentCode: 'Incident code',
    APPRAISALS_incidentDate: 'Incident date',
    APPRAISALS_incidentId: 'Incident ID',
    APPRAISALS_incidents: 'Incidents',
    APPRAISALS_includeAppraisalInExchange: 'Include the request in the assignment exchange',
    APPRAISALS_internalReference: 'Internal reference number',
    APPRAISALS_internalReferenceShort: 'Internal ref. number',
    APPRAISALS_INVOICE_commercialTechnicalFee: 'Commercial Fee',
    APPRAISALS_INVOICE_edit: 'Edit invoicing details',
    APPRAISALS_INVOICE_error: 'There was a problem accessing the invoicing details. Please refresh the page and try again.',
    APPRAISALS_INVOICE_generate: 'Generate or add to the invoice',
    APPRAISALS_INVOICE_generateWarning: 'Are you sure you want to generate the invoice?',
    APPRAISALS_INVOICE_see: 'See invoice',
    APPRAISALS_INVOICE_technicalFee: 'Technical fees',
    APPRAISALS_invoice: 'Invoice',
    APPRAISALS_invoicing: 'Invoicing',
    APPRAISALS_isAppraiserAllDataSentDeadlineExpired: 'Report delivered after the deadline',
    APPRAISALS_isCustomerDeadlineExpired: 'Delivered to customer after the deadline',
    APPRAISALS_isExecutedByIvForce: 'Run with Tasafy',
    APPRAISALS_isInConfirmedInvoice: 'Invoice confirmed',
    APPRAISALS_IsInvoicedInOrderRequest: 'Invoiced',
    APPRAISALS_isSolved: 'Resolved',
    APPRAISALS_lastAppraisalDatetime: 'Last appraisal date',
    APPRAISALS_lastCompanyName: 'Appraisal firm',
    APPRAISALS_lastRecordId: 'Previous case number',
    APPRAISALS_lastTotalSurface: 'Surface area adopted',
    APPRAISALS_lastUpdatedUser: 'Last editor',
    APPRAISALS_lastValuation: 'Last appraisal',
    APPRAISALS_lastValuationValue: 'Last appraisal value',
    APPRAISALS_locality: 'Town/City',
    APPRAISALS_markAsDelivered: 'Validate costs',
    APPRAISALS_markAsDeliveredConfirmation: 'Are you sure you want to mark this appraisal as delivered?',
    APPRAISALS_markAsResolved: 'Mark as resolved',
    APPRAISALS_MASSIVE_clear: 'Delete',
    APPRAISALS_MASSIVE_create: 'Create new mass assignment',
    APPRAISALS_MASSIVE_createNew: 'New mass upload',
    APPRAISALS_MASSIVE_createNewValoration: 'Bulk upload of appraisal of the request',
    APPRAISALS_MASSIVE_description: 'Please make sure that you have',
    APPRAISALS_MASSIVE_drag: 'Drag the file here or',
    APPRAISALS_MASSIVE_fileInfo: 'File information processed',
    APPRAISALS_MASSIVE_goToList: 'Go to the list',
    APPRAISALS_MASSIVE_massiveLoadBatches: 'Mass upload batches',
    APPRAISALS_MASSIVE_massiveListBatches: 'Loading of appraisals',
    APPRAISALS_MASSIVE_process: 'Process',
    APPRAISALS_MASSIVE_selectHere: 'Select here',
    APPRAISALS_MASSIVE_templateDownload: 'most recent template version downloaded',
    APPRAISALS_MASSIVE_uploadErrorMsg: 'There was a problem uploading the file. Consult the "File information processed" section.\'',
    APPRAISALS_message: 'Message',
    APPRAISALS_messageBoard: 'Message board',
    APPRAISALS_noClaims: 'No claims',
    APPRAISALS_noDiferenceWithTheOriginalAsset: 'No differences with the original asset.',
    APPRAISALS_noIncidents: 'No incidents',
    APPRAISALS_noMessages: 'No messages',
    APPRAISALS_noRecords: 'No records',
    APPRAISALS_noRevisions: 'No reviews',
    APPRAISALS_noStais: 'No rooms',
    APPRAISALS_notGenerateInvoice: 'Generate individual appraisal invoice',
    APPRAISALS_setDailyInvoiced: 'Include the professional at the end of the day in the automatic payment',
    APPRAISALS_notPrint: 'Do not print',
    APPRAISALS_noValidate: 'Do not validate',
    APPRAISALS_numberBathrooms: 'Number of bathrooms',
    APPRAISALS_numberRooms: 'Number of rooms',
    APPRAISALS_observations: 'Remarks',
    APPRAISALS_justifyObservationsSurface: 'Justify the origin of the surface used and the module considered',
    APPRAISALS_observationsMarketValue: 'Observations of the calculation of the market value',
    APPRAISALS_of: 'of',
    APPRAISALS_openingDate: 'Opening date',
    APPRAISALS_orderFeatures: 'Nature of the assignment',
    APPRAISALS_orderType: 'Type of assignment',
    APPRAISALS_Type: 'Type of appraisal',
    APPRAISALS_informType: 'Report type',
    APPRAISALS_eco: 'ECO Compliant?',
    APPRAISALS_appraisal: 'Appraised value',
    APPRAISALS_visitDate: 'Date of visit',
    APPRAISALS_antecedents: 'Are there antecedents?',
    APPRAISALS_antecedentsInfo: `The list of appraisals in the system that contain assets with features in common with those of this file is shown. Depending on what data is shared, we distinguish three types of background:
    <ol>
      <li class="mt-2">
        Same asset: If the IDUFIR, the cadastral reference or the property and the registry coincide.
      </li>
      <li class="mt-2">
        Same building: If the street and the number or the cadastral reference of the building coincide.
      </li>
      <li class="mt-2">
        Same area: If the Use of the property and the distance between the coordinates are less than 1 km.
      </li>
    </ol>`,
    APPRAISALS_antecedentsInfoAssetCommon: 'The following files, validated in the last 6 months, contain assets in common with this one. review them.',
    APPRAISALS_emit: 'Date of issue',
    APPRAISALS_client: 'Client',
    APPRAISALS_normativeReference: 'Edit text purposes and normative reference',
    APPRAISALS_originData: 'Origin details',
    APPRAISALS_otherExpensesAsociated: 'Associated costs',
    APPRAISALS_pendingClaims: 'Pending claims',
    APPRAISALS_pendingIncidents: 'Pending incidents',
    APPRAISALS_phone: 'Telephone',
    APPRAISALS_portfolios: 'Portfolios',
    APPRAISALS_prescribers: 'Prescribers',
    APPRAISALS_prescriptor: 'Prescriber',
    APPRAISALS_prescriptorOffice: 'Office',
    APPRAISALS_print: 'Print',
    APPRAISALS_processManager: 'Process manager',
    APPRAISALS_propertyData: 'Request details',
    APPRAISALS_province: 'Province',
    APPRAISALS_purpose: 'Purpose',
    APPRAISALS_purposeBaseValue: 'Purpose / Base value',
    APPRAISALS_purposeAppraisal: 'Purpose',
    APPRAISALS_reason: 'Reason',
    APPRAISALS_reasonsComplaint: 'Reason for claim',
    APPRAISALS_reasonToChooseTech: 'Please tell us your reasons for choosing this appraiser:',
    APPRAISALS_recalculateWorkPlan: 'The status of construction needs to be recalculated',
    APPRAISALS_related_expenses: 'Related costs',
    APPRAISALS_relationship: 'Related appraisals',
    APPRAISALS_relationshipAppraisalId: 'Related appraisal',
    APPRAISALS_relationshipType: 'Relationship type',
    APPRAISALS_reply: 'Respond',
    APPRAISALS_replyMessage: 'Respond to the message',
    APPRAISALS_REPORT_add: '+ Add',
    APPRAISALS_REPORT_addAnex: 'Add attachment',
    APPRAISALS_REPORT_addChapter: 'Add chapter',
    APPRAISALS_REPORT_addConstruction: 'Add construction',
    APPRAISALS_REPORT_addExpense: 'Add expense',
    APPRAISALS_REPORT_addIncome: 'Add income',
    APPRAISALS_REPORT_addNewExpense: 'Add new expense',
    APPRAISALS_REPORT_addNewIncome: 'Add new income',
    APPRAISALS_REPORT_addPhoto: 'Add photo',
    APPRAISALS_REPORT_addPossession: 'Add ownership',
    APPRAISALS_REPORT_address: 'Address',
    APPRAISALS_REPORT_addValuation: 'Add appraisal',
    APPRAISALS_REPORT_adoptedSurface: 'Surface area adopted ({{surfaceUnit}})',
    APPRAISALS_REPORT_adoptedSurfaceType: 'Surface area adopted type',
    APPRAISALS_REPORT_adoptedSurfaceHeaderType: 'Global adopted surface type',
    APPRAISALS_REPORT_application: 'Application',
    APPRAISALS_REPORT_appraisalAssetBuildingState: 'Construction status',
    APPRAISALS_REPORT_appraisalAssetLogChanges: 'Change log',
    APPRAISALS_REPORT_appraisalCommonData: 'Common report details',
    APPRAISALS_REPORT_appraisalObject: 'Object of the appraisal',
    APPRAISALS_REPORT_appraise: 'Appraise',
    APPRAISALS_REPORT_assetDeleteWarning: 'Are you sure you want to delete this cluster element?',
    APPRAISALS_REPORT_assigned: 'Assigned ({{currency}})',
    APPRAISALS_REPORT_assignedValue: 'Value assigned ({{currency}})',
    APPRAISALS_REPORT_automaticInfoWarning: 'By saving the form, the details highlighted in orange will be retrieved base on location, with the details edited manually being lost.',
    APPRAISALS_REPORT_buildability: 'Development potential',
    APPRAISALS_REPORT_buildabilityM2: 'Development potential ({{surfaceUnit}})',
    APPRAISALS_REPORT_buildable: 'Development potential',
    APPRAISALS_REPORT_building_caracteristics_economic_message: 'The property has exhausted its useful life, therefore, it is not likely to generate any economic activity and the ILEE Update method will be eliminated.',
    APPRAISALS_REPORT_buildingCharacteristics: 'Nature of the building',
    APPRAISALS_REPORT_buildingElementWarning: 'The estimated parameters of the element to be constructed are going to be recalculated as part of the market value calculation. This needs to be revised.',
    APPRAISALS_REPORT_builtWithCommonAreas: 'Constructed with common areas',
    APPRAISALS_REPORT_builtWithoutCommonAreas: 'Constructed without common areas',
    APPRAISALS_REPORT_cadastralReference: 'Land registry reference',
    APPRAISALS_REPORT_calculateMarketValue: 'Market value calculation',
    APPRAISALS_REPORT_calculationMethod: 'Calculation method',
    APPRAISALS_REPORT_calculations: 'Calculations',
    APPRAISALS_REPORT_cancel: 'Cancel',
    APPRAISALS_REPORT_capitalGain: 'Land capital gain/loss',
    APPRAISALS_REPORT_cashflows_observations: '* It will only be necessary to distribute the Income / Expenses that have been added with the Non-Periodic Typology.',
    APPRAISALS_REPORT_cashflows_tooltip: 'The Cash Flows show the total amounts of Income and Expenses generated by the Economic Exploitation without application of the percentage relative to the Income imputed to the property.',
    APPRAISALS_REPORT_cashflows: 'Cash Flows',
    APPRAISALS_REPORT_checkValidations: 'Checks',
    APPRAISALS_REPORT_cluster: 'Cluster',
    APPRAISALS_REPORT_clusterItems: 'Cluster units',
    APPRAISALS_REPORT_clusters: 'Clusters',
    APPRAISALS_REPORT_comparisonCalculation: 'Comparison calculation',
    APPRAISALS_REPORT_comparisonForConstructionHypotheses: 'Comparison for construction hypothesis',
    APPRAISALS_REPORT_conditioningLabel: 'Conditioning factors, warnings and remarks',
    APPRAISALS_REPORT_constructionCosts: 'Construction costs',
    APPRAISALS_REPORT_constructionCostsWithUnits: 'Construction costs ({{currency_per_surfaceUnit}})',
    APPRAISALS_REPORT_constructionHypothesis: 'Construction hypothesis',
    APPRAISALS_REPORT_contractDocumentation: 'Documentation demonstrating occupancy status',
    APPRAISALS_REPORT_country: 'Country',
    APPRAISALS_REPORT_createdBy: 'Created by',
    APPRAISALS_REPORT_createResidualDynamicMethod: 'Create dynamic residual method',
    APPRAISALS_REPORT_criteria: 'Criteria',
    APPRAISALS_REPORT_currentValuationTotalValue: 'Current total appraisal',
    APPRAISALS_REPORT_dataSourse: 'Source of the data',
    APPRAISALS_REPORT_dateRange: 'Deadline',
    APPRAISALS_REPORT_delete: 'Delete',
    APPRAISALS_REPORT_description: 'Description',
    APPRAISALS_REPORT_dinamicResidualMethodValue: 'Dynamic residual method value',
    APPRAISALS_REPORT_dni: 'ID No.',
    APPRAISALS_REPORT_documents: 'Documentation',
    APPRAISALS_REPORT_doMain: 'Make main image of appraisal',
    APPRAISALS_REPORT_doMainElement: 'Make main element',
    APPRAISALS_REPORT_door: 'Door',
    APPRAISALS_REPORT_duplicate: 'Duplicate',
    APPRAISALS_REPORT_economicData: 'Economic data',
    APPRAISALS_REPORT_economicExploitationData: 'Economic Exploitation Data',
    APPRAISALS_REPORT_justification: 'Justification for extending the end of exploitation date',
    APPRAISALS_REPORT_economicExploitationUpdateDatesMessage: 'Any modification of the dates, once income and expenses have been added, will imply a readjustment of these.',
    APPRAISALS_REPORT_JustificationMessage: 'The reason for extending the end of exploitation date beyond that determined by its useful life must be justified.',
    APPRAISALS_REPORT_economicParameters: 'Economic Parameters',
    APPRAISALS_REPORT_edit: 'Edit',
    APPRAISALS_REPORT_editAutomaticInfo: 'Edit automatic invoicing',
    APPRAISALS_REPORT_editFields: 'Edit fields',
    APPRAISALS_REPORT_editFinisDateTime: 'Edit Finish Date',
    APPRAISALS_REPORT_editOrDelete: 'You can appraise or delete this cluster',
    APPRAISALS_REPORT_editPhoto: 'Edit photo',
    APPRAISALS_REPORT_editStartDateTime: 'Edit start date',
    APPRAISALS_REPORT_elementSurface: "Element's estimated surface area ({{surfaceUnit}})",
    APPRAISALS_REPORT_entrance: 'Entrance',
    APPRAISALS_REPORT_euroValue: 'Value{{currency}}',
    APPRAISALS_REPORT_everyAssetMustHaveAValidSurface: 'Check that the following assets have at least one valid surface',
    APPRAISALS_REPORT_expense: 'Cost',
    APPRAISALS_REPORT_expenses_obserservations: 'Observations on expenses',
    APPRAISALS_REPORT_expenses: 'Costs',
    APPRAISALS_REPORT_exploitationType: 'Type of exploitation',
    APPRAISALS_REPORT_extract: 'Extract',
    APPRAISALS_REPORT_finish: 'Complete',
    APPRAISALS_REPORT_finishDateTime: 'Finish Date',
    APPRAISALS_REPORT_floor: 'Floor',
    APPRAISALS_REPORT_futureValuationTotalValue: 'Total HET appraisal',
    APPRAISALS_REPORT_identification: 'Identification',
    APPRAISALS_REPORT_idufir: 'CRU (IDUFIR)',
    APPRAISALS_REPORT_imputedIncome: 'Imputed Income',
    APPRAISALS_REPORT_imputedIncomePercentage: '% Income imputed to the property',
    APPRAISALS_REPORT_income: 'Income',
    APPRAISALS_REPORT_incomes_obserservations: 'Observations on income',
    APPRAISALS_REPORT_incomes: 'Incomes',
    APPRAISALS_REPORT_incomesExpensesDifference: 'Income - Expenses',
    APPRAISALS_REPORT_incomeType: 'Type of income',
    APPRAISALS_REPORT_initialParameters: 'Initial parameters',
    APPRAISALS_REPORT_insurance: 'Calculation of insurance value as regards:',
    APPRAISALS_REPORT_insuranceByEco: 'insurance as per ECO',
    APPRAISALS_REPORT_insuranceByRoyalDecree: 'insurance as per Royal Decree',
    APPRAISALS_REPORT_isCapitalGainPercentEdited: 'Edit Land Gain/Loss',
    APPRAISALS_REPORT_isIncomeImputedToPropertyPercentEdited: 'Edit % Income imputed to the property',
    APPRAISALS_REPORT_isRecurrent: 'Is it recurring?',
    APPRAISALS_REPORT_isReversionValueEdited: 'Edit reversion value',
    APPRAISALS_REPORT_landDescription: 'Site description and surface area',
    APPRAISALS_REPORT_location: 'Location',
    APPRAISALS_REPORT_locationAndSurroundings: 'Town/city, surroundings and market',
    APPRAISALS_REPORT_marketValue: 'Market value ({{currency_per_surfaceUnit}})',
    APPRAISALS_REPORT_multipleSelection: 'Multiple selection',
    APPRAISALS_REPORT_municipality: 'Municipality',
    APPRAISALS_REPORT_name: 'Name',
    APPRAISALS_REPORT_netExpenses: 'Net costs',
    APPRAISALS_REPORT_netExpensesAssigned: 'Assigned net costs',
    APPRAISALS_REPORT_netIncomensExpensesDiff: 'Incomes - Expenses',
    APPRAISALS_REPORT_netIncomensExpensesDiffAssigned: 'Net Income - Net Assigned Expenses',
    APPRAISALS_REPORT_netIncomes: 'Net income',
    APPRAISALS_REPORT_netIncomesAssigned: 'Assigned net incomes',
    APPRAISALS_REPORT_noDocumentSelected: 'No document selected',
    APPRAISALS_REPORT_non_lease_document: 'No lease document',
    APPRAISALS_REPORT_noPhotos: 'No photos available for this asset',
    APPRAISALS_REPORT_noValorationClusteredAssets: 'This asset cannot be appraised as it belongs to a cluster',
    APPRAISALS_REPORT_numOfElementsToBuild: 'No. elements for construction',
    APPRAISALS_REPORT_objectOfValuation: 'Object of the appraisal',
    APPRAISALS_REPORT_occupationDniNif: 'Tenant DNI / NIF',
    APPRAISALS_REPORT_occupationState: 'Occupancy status',
    APPRAISALS_REPORT_occupationType: 'Occupancy type',
    APPRAISALS_REPORT_otherMethod: 'Other method',
    APPRAISALS_REPORT_parameters: 'Parameters',
    APPRAISALS_REPORT_percentage: 'Percentage',
    APPRAISALS_REPORT_periodicity: 'Periodicity',
    APPRAISALS_REPORT_photos: 'Photos',
    APPRAISALS_REPORT_plotSurfaces: 'Plot surface areas',
    APPRAISALS_REPORT_possesionSituation: 'Ownership',
    APPRAISALS_REPORT_possessionType: 'Ownership type',
    APPRAISALS_REPORT_postalCode: 'Post code',
    APPRAISALS_REPORT_print: 'include in the report',
    APPRAISALS_REPORT_promoterBenefitsPercent: 'Developer profit (%)',
    APPRAISALS_REPORT_propertyCaracteristics: 'Nature of the property',
    APPRAISALS_REPORT_province: 'Province',
    APPRAISALS_REPORT_recalculateMethod: 'Recalculate methods',
    APPRAISALS_REPORT_recalculate: 'Recalculate',
    APPRAISALS_REPORT_recalculating: 'Recalculating',
    APPRAISALS_REPORT_recurrent: 'Recurring',
    APPRAISALS_REPORT_registryNumber: 'Property',
    APPRAISALS_REPORT_remove: 'Delete',
    APPRAISALS_REPORT_reorderPhotos: 'Reorder',
    APPRAISALS_REPORT_repercusion: 'Repercussion ({{currency_per_surfaceUnit}})',
    APPRAISALS_REPORT_requestData: 'Request details',
    APPRAISALS_REPORT_reversalValue: 'Reversion Value',
    APPRAISALS_REPORT_reversionValue: 'Reversion Value',
    APPRAISALS_REPORT_reversionValueUpdated: 'Updated Reversion Value',
    APPRAISALS_REPORT_riskPremium: 'Risk premium',
    APPRAISALS_REPORT_selectAppraisalMethod: 'Select the appraisal method',
    APPRAISALS_REPORT_selectValorationType: 'Select appraisal method',
    APPRAISALS_REPORT_stairs: 'Staircase',
    APPRAISALS_REPORT_startDateTime: 'Start Date',
    APPRAISALS_REPORT_staticResidualMethodValue: 'Static residual method value',
    APPRAISALS_REPORT_status: 'Status',
    APPRAISALS_REPORT_streetName: 'Street name',
    APPRAISALS_REPORT_streetNumber: 'Street number',
    APPRAISALS_REPORT_subType: 'Subtype',
    APPRAISALS_REPORT_sureDeleteCluster: 'Are you sure you want to delete this cluster?',
    APPRAISALS_REPORT_sureRemoveElement: 'Are you sure you want to delete the element?',
    APPRAISALS_REPORT_surfaces: 'Surface areas',
    APPRAISALS_REPORT_surfaceType: 'Surface area type',
    APPRAISALS_REPORT_terrainFeatures: 'Nature of the site',
    APPRAISALS_REPORT_terrainValuation: 'Plots appraisal',
    APPRAISALS_REPORT_totalAndUpdatedValues: 'Total and Updated Values',
    APPRAISALS_REPORT_totalAssigned: 'Total allocated ({{currency}})',
    APPRAISALS_REPORT_totalExpenses: 'Expenses',
    APPRAISALS_REPORT_totalIncomes: 'Total Incomes',
    APPRAISALS_REPORT_totalSummary: 'Total summary',
    APPRAISALS_REPORT_typology: 'Type',
    APPRAISALS_REPORT_units: 'Units',
    APPRAISALS_REPORT_updatedRents: 'Updated Rent',
    APPRAISALS_REPORT_updatedRentsImputed: 'Updated rent imputed to the property',
    APPRAISALS_REPORT_updatedValue: 'Updated ({{currency}})',
    APPRAISALS_REPORT_updatedValueEx: 'Updated Value',
    APPRAISALS_REPORT_urbanisticSituation: 'Town planning status',
    APPRAISALS_REPORT_use: 'Use',
    APPRAISALS_REPORT_useful: 'Useful',
    APPRAISALS_REPORT_valorationMethodChangeWarning: 'If the appraisal method changes, the current calculations will be lost',
    APPRAISALS_REPORT_valorations: 'Appraisals',
    APPRAISALS_REPORT_valuation: 'Appraisal',
    APPRAISALS_REPORT_valuationInfoMessage: 'Only one method per appraisal type is permitted',
    APPRAISALS_REPORT_valuationMethods: 'Appraisal methods',
    APPRAISALS_REPORT_valuations: 'Appraisals',
    APPRAISALS_REPORT_valuationTotalValue: 'Total appraisal',
    APPRAISALS_REPORT_valueByResidualStatic: 'Value using static residual method',
    APPRAISALS_REPORT_view: 'See details',
    APPRAISALS_REPORT_weight: 'Weight (%)',
    APPRAISALS_REPORT_workPlanCreateRDMethodMessage: 'When the dynamic residual is created, it will be generated for the units that are part of the same "State of the work". The total value of the dynamic residual method will be distributed among the different elements. ',
    APPRAISALS_REPORT_workPlanCreateRDMethosAssetsMessage: 'In this case, the units for which the dynamic residual method will be generated together will be the following:',
    APPRAISALS_REPORT_workPlanMessageToRecalculate: 'The recalculation of the Dynamic Residual implies that all those Residuals that were subject to the same budget within the State of the Work will be updated. If you confirm, it will be necessary to recalculate the valuations of each asset later. Do you want to perform the recalculation? ',
    APPRAISALS_REPORT_MARKET_STUDY_CREATE: 'Create market study',
    APPRAISALS_REPORT_MARKET_STUDY_CREATION: 'Creation of market study',
    APPRAISALS_REPORT_MARKET_STUDY_NOT_CREATE: 'No market studies have been created',
    APPRAISALS_REPORT_MARKET_STUDY_ASSET_TYPE: 'Asset type',
    APPRAISALS_report: 'Report',
    APPRAISALS_request: 'Request',
    APPRAISALS_requestNumber: 'Request number',
    APPRAISALS_requiredFields: 'The required fields are filled in to',
    APPRAISALS_resolutionAverageTime: 'Average resolution time',
    APPRAISALS_resolveClaim: 'Resolve claim',
    APPRAISALS_resolvedAt: 'Resolved at',
    APPRAISALS_resolveIncidence: 'Resolve incident',
    APPRAISALS_revalorationIsNeeded: 'The asset must be reappraised',
    APPRAISALS_review: 'Review',
    APPRAISALS_revisionReason: 'Reason for review',
    APPRAISALS_scheduledVisitDate: 'Scheduled visit date',
    APPRAISALS_seeRelatedAppraisal: 'See related appraisal',
    APPRAISALS_selectAppraisal: 'Select appraisal',
    APPRAISALS_selectOrder: 'Select job',
    APPRAISALS_selectPrescriptor: 'Select a prescriber',
    APPRAISALS_selectRelationshipAppraisal: 'Select appraisal',
    APPRAISALS_selectRequest: 'Select request',
    APPRAISALS_selectRequestElements: 'Select request elements',
    APPRAISALS_simpleNote: 'Registry extract',
    APPRAISALS_solvedDate: 'Resolution date',
    APPRAISALS_solvedMaxDateTime: 'Resolution end date',
    APPRAISALS_solvedMinDateTime: 'Resolution start date',
    APPRAISALS_startValidation: 'Start validation',
    APPRAISALS_startValidationConfirmation: 'Are you sure you want to start the validation of this appraisal?',
    APPRAISALS_status: 'Status',
    APPRAISALS_stay: 'Room',
    APPRAISALS_target: 'Purpose',
    APPRAISALS_techManager: 'Appraiser manager',
    APPRAISALS_technicalsFee: 'Technical fees',
    APPRAISALS_thereAreNoItems: '* No elements',
    APPRAISALS_totalClaims: 'Total claims',
    APPRAISALS_totalIncidents: 'Total incidents',
    APPRAISALS_history: 'History',
    APPRAISALS_useAssetRepository: 'Select this option to perform the appraisal using Tasafy',
    APPRAISALS_userType: 'Type of user',
    APPRAISALS_validate: 'Validate',
    APPRAISALS_validateJustification: 'Justification',
    APPRAISALS_validateJustificationInfo: 'There is already a CURRENT appraisal with these registry data. If it does not give the same value, justify it.',
    APPRAISALS_startValidationJustificationInfo: 'There is already a CURRENT appraisal with these registry data. You can check it in background.',
    APPRAISALS_validateAppraisalConfirmation: 'Are you sure you want to validate this appraisal?',
    APPRAISALS_validateAssetsOfAnAppraisal: 'Validate appraisal assets',
    APPRAISALS_resumeValidate: 'Validation Data Summary',
    APPRAISALS_validateExpenses: 'Validate expenses',
    APPRAISALS_validateExpensesConfirmation: 'Please note that once validated, the costs cannot be edited. Are you sure you want to validate the costs?',
    APPRAISALS_validatingExpenses: 'Validating costs',
    APPRAISALS_validation: 'Validation',
    APPRAISALS_validationDatetime: 'Validation date',
    APPRAISALS_validationFinishDatetime: 'Supervision date',
    APPRAISALS_validationFinished: 'Validation complete',
    APPRAISALS_validationStarted: 'Validation started',
    APPRAISALS_valorationTotalValue: 'Appraisal value',
    APPRAISALS_valorationValue: 'Value',
    APPRAISALS_VALUATION_adoptedTotalSurfaceArea: 'Surface area adopted ({{surfaceUnit}})',
    APPRAISALS_VALUATION_atLeastOneSurfaceWithValue: 'At least one surface area',
    APPRAISALS_VALUATION_back: 'Back',
    APPRAISALS_VALUATION_save_selection: 'Save Selection',
    APPRAISALS_VALUATION_buildingPhysicDeprecation: 'Physical depreciation of the building',
    APPRAISALS_VALUATION_buildingUseRequired: 'Nature of the building:  building use',
    APPRAISALS_VALUATION_buildingYearRequired: 'Nature of the building:  year of construction',
    APPRAISALS_VALUATION_calculationDocument: 'Calculation document',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanAdoptedNecessaryExpenses: 'Necessary costs adopted %',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanConstructionCostCurrent: 'Construction cost - Current',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanConstructionCostTotal: 'Total construction cost',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanExpensesProgressPercentage: 'Progress of costs %',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanFormMessage: 'The construction values are calculated based on the construction progress percentage. The Cost value is taken from the distribution in the Construction Status tab.',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanLink: 'Calculation based on construction progress',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanManualEditing: 'Edit percentage of progress in costs',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanMessage: '* The necessary costs are calculated based on the total construction costs of the unit ("in construction status" table), considering the % applied based on the degree of progress with the construction. See details under "Calculation based on construction progress".',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanNecessaryExpenses: 'Necessary costs %',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanNecessaryExpensesCurrent: 'Necessary costs - Current',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanProgressPercentage: 'Construction progress %',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanTitle: 'Construction - Costs and expenses',
    APPRAISALS_VALUATION_construction: 'Construction',
    APPRAISALS_VALUATION_constructionCost: 'Construction cost',
    APPRAISALS_VALUATION_constructionCostsTitle: 'Construction costs',
    APPRAISALS_VALUATION_constructionCostTotalValue: 'Construction cost ({{currency}})',
    APPRAISALS_VALUATION_constructionCostUnitValue: 'Construction cost ({{currency_per_surfaceUnit}})',
    APPRAISALS_VALUATION_constructionExpenses: 'Construction costs',
    APPRAISALS_VALUATION_direction: 'Address',
    APPRAISALS_VALUATION_distance: 'Distance',
    APPRAISALS_VALUATION_elementPhysicDeprecation: 'Physical depreciation of the element',
    APPRAISALS_VALUATION_financialExpenses: 'Financial expenses',
    APPRAISALS_VALUATION_functionalDeprecation: 'Functional depreciation',
    APPRAISALS_VALUATION_ground: 'Ground',
    APPRAISALS_VALUATION_KCoefficient: 'K (Coefficient to switch to new)',
    APPRAISALS_VALUATION_KeInfo: 'Ke: homogenization coefficient for specific units that are better or worse for general homogenization',
    APPRAISALS_VALUATION_KhInfo: 'Kh: coefficient of adjustment between the market value and the mortgage value. If the requirements of article 12.3 of the ECO order are met, apply the Kh coefficient and also include the warnings MER1 or MER2',
    APPRAISALS_VALUATION_marketValue: 'Market value',
    APPRAISALS_VALUATION_marketValueEuroM2: 'Market value ({{currency_per_surfaceUnit}})',
    APPRAISALS_VALUATION_marketValueEuroM2Month: 'Market value ({{currency_per_surfaceUnit}}/month)',
    APPRAISALS_VALUATION_euroM2Month: '({{currency_per_surfaceUnit}}/month)',
    APPRAISALS_VALUATION_monthly: 'Monthly',
    APPRAISALS_VALUATION_moreInfo: 'Further information',
    APPRAISALS_VALUATION_necessaryConstructionExpensesPercentageRequired: 'Complete "Calculations according to the progress of the work"',
    APPRAISALS_VALUATION_necessaryExpenses: 'Necessary costs',
    APPRAISALS_VALUATION_observations: 'Remarks',
    APPRAISALS_VALUATION_promoterBenefits: 'Developer benefits',
    APPRAISALS_VALUATION_proposalValue: 'Value proposition',
    APPRAISALS_VALUATION_recalulateWorkPlan: 'It is necessary to recalculate the state of the work and assign a surface to this asset.',
    APPRAISALS_VALUATION_repercussion: 'Repercussion',
    APPRAISALS_VALUATION_method: 'Method',
    APPRAISALS_VALUATION_requiredErrorHeader: 'Required fields for this method are missing',
    APPRAISALS_VALUATION_requiredErrorMessage: 'Some of the following data is missing:',
    APPRAISALS_VALUATION_requiredSurface: 'To continue, please select a surface area',
    APPRAISALS_VALUATION_selectSurfaceForValuation: 'Select a surface area to be used in this appraisal',
    APPRAISALS_VALUATION_staticResidual: 'Simplified static residual',
    APPRAISALS_VALUATION_total: 'Total',
    APPRAISALS_VALUATION_totalAdoptedSurface: 'It is necessary to define the surfaces adopted in the chapter of the State of the Work.',
    APPRAISALS_VALUATION_totalLegalMaximumValue: 'Total maximum legal value',
    APPRAISALS_VALUATION_totalMarketValue: 'Total market value',
    APPRAISALS_VALUATION_totalValueEuro: 'Total value ({{currency}})',
    APPRAISALS_VALUATION_suitableType: 'Quality control',
    APPRAISALS_VALUATION_totalValueEuroMonth: 'Total value ({{currency}})/month)',
    APPRAISALS_VALUATION_tradingExpenses: 'Marketing expenses',
    APPRAISALS_VALUATION_typology: 'Type',
    APPRAISALS_VALUATION_valueProposalChanges: 'Changes to the value proposition',
    APPRAISALS_VALUATION_valueProposalChangesObservations: 'Observations about the value proposition',
    APPRAISALS_VALUATION_yearly: 'Annual',
    APPRAISALS_VALUTION_comparableList: 'List of comparables',
    APPRAISALS_version: 'Version',
    APPRAISALS_viewRequest: 'See request',
    APPRAISALS_vinculation: 'Type of relationship',
    APPRAISALS_visit: 'Visit',
    APPRAISALS_limit: 'Deadline',
    APPRAISALS_absent: 'Absent',
    APPRAISALS_visitDateEnd: 'End date scheduled',
    APPRAISALS_visitDates: 'Visit dates',
    APPRAISALS_visitDateStart: 'Start date scheduled',
    APPRAISALS_visitFinished: 'Visit completed',
    APPRAISALS_visitName: 'adsasd',
    APPRAISALS_visitScheduled: 'Scheduled',
    APPRAISALS_visitScheduleDeadline: 'Scheduling deadline',
    APPRAISALS_visitStarted: 'Visit started',
    APPRAISALS_visitType: 'Type of visit',
    APPRAISALS_watchCustomerDetail: 'See customer file',
    APPRAISALS_watchPrescriptorDetail: 'See prescriptor file',
    APPRAISALS_watchProfile: 'See profile',
    APPRAISALS_withClaims: 'With claims',
    APPRAISALS_withClaimsMaxDateTime: 'Incident end date',
    APPRAISALS_withClaimsNotResolved: 'With unresolved claims',
    APPRAISALS_withIncidents: 'With incidents',
    APPRAISALS_withIncidentsMinDateTime: 'Incident start date',
    APPRAISALS_withIncidentsNotResolve: 'With unresolved incidents',
    APPRAISALS_withIncidentsActive: 'With active incidents',
    appraisals: 'Appraisals',
    AppraisalStatus_1: '1 - REGISTRATION',
    AppraisalStatus_10: '10 - CORRECT VALIDATION',
    AppraisalStatus_11: '11 - DELIVERED TO CUSTOMER',
    AppraisalStatus_2: '2 - PROCESS MANAGER ASSIGNED',
    AppraisalStatus_3: '3 - APPRAISER ASSIGNED',
    AppraisalStatus_4: '4 - VISIT SCHEDULED',
    AppraisalStatus_5: '5 - VISIT STARTED',
    AppraisalStatus_6: '6 - VISIT COMPLETED',
    AppraisalStatus_7: '7 - REPORT DELIVERED',
    AppraisalStatus_8: '8 - VALIDATOR ASSIGNED',
    AppraisalStatus_9: '9 - VALIDATION STARTED',
    appraisalsWithInvoice: 'Appraisals with invoice',
    appraiser: 'Appraiser',
    Appraiser: 'Professionals',
    appraiserFee: 'Technical fees',
    Ascensor: 'Lifts',
    assessments: 'Appraisals',
    ASSET_BUILDING_addDynamicResidual: 'Add dynamic residual method',
    ASSET_BUILDING_addingMethod: 'Adding ...',
    ASSET_BUILDING_basicProject: 'Preliminary design',
    ASSET_BUILDING_basicProjectDatetime: 'Preliminary design creation date',
    ASSET_BUILDING_basicProjectVisaDatetime: 'Preliminary design approval date',
    ASSET_BUILDING_buildingAverageConstructionCost: 'Average construction cost ({{ currency_per_surfaceUnit }})',
    ASSET_BUILDING_buildingEstimatedDate: 'Estimated construction end date',
    ASSET_BUILDING_buildingEstimatedDuration: 'Estimated duration of the work (months)',
    ASSET_BUILDING_buildingLicense: 'Construction permit',
    ASSET_BUILDING_buildingLicenseDatetime: 'Construction permit date',
    ASSET_BUILDING_buildingLicenseExpirationDatetime: 'Construction permit expiry',
    ASSET_BUILDING_buildingProgressGrade: 'Construction progress (%)',
    ASSET_BUILDING_buildingStateObservations: 'Remarks about the status of construction',
    ASSET_BUILDING_buildingTotalSurface: 'Total construction surface area ({{ surfaceUnit }})',
    ASSET_BUILDING_builingStateInitDatetime: 'Construction start date',
    ASSET_BUILDING_chapter: 'Chapter',
    ASSET_BUILDING_chapterAmount: 'Value ({{ currency }})',
    ASSET_BUILDING_chapterDescription: 'Chapter description',
    ASSET_BUILDING_chapterOverTotal: 'Chapter compared to total (%)',
    ASSET_BUILDING_chapterProgress: 'Advance chapter (%)',
    ASSET_BUILDING_currentBuildingStateObservations: 'Current construction status',
    ASSET_BUILDING_documentation: 'documentation',
    ASSET_BUILDING_dynamicResidualSummary: 'Summary of values of the Dynamic Residual Method',
    ASSET_BUILDING_executionBuildingBudget: 'Construction execution budget',
    ASSET_BUILDING_executionProject: 'Final design',
    ASSET_BUILDING_executionProjectDatetime: 'Final design creation date',
    ASSET_BUILDING_executionProjectVisaDatetime: 'Final design approval date',
    ASSET_BUILDING_needToReviewCashflow: '* Due to the modifications you have made, it is necessary to redo the distribution of cash flow',
    ASSET_BUILDING_recalculateMethod: 'Recalculate method',
    ASSET_BUILDING_notRecalculateMethod: 'It is not possible to recalculate because the linked file comes from Borsan. The method must be deleted and created again.',
    ASSET_BUILDING_removeDynamicMethodConfirmation: 'The static residual method that affects the following assets is going to be eliminated: \n \n {{assets}}',
    ASSET_BUILDING_totalBudget: 'Budget total ({{ currency }})',
    ASSET_BUILDING_totalProgress: 'Advance Global (%)',
    ASSET_BUILDING_workPlanConstructionCost: 'Works construction cost',
    ASSET_CLUSTER_DETAIL_adoptedSurface: 'Surface area adopted',
    ASSET_CLUSTER_DETAIL_buildingData: 'Building details',
    ASSET_CLUSTER_DETAIL_buildingUse: 'Building use',
    ASSET_CLUSTER_DETAIL_censusSection: 'Census section',
    ASSET_CLUSTER_DETAIL_clusterDetails: 'Cluster details',
    ASSET_CLUSTER_DETAIL_clusterUnits: 'Cluster Units',
    ASSET_CLUSTER_DETAIL_constructionYearWightedObservations: 'Observations regarding the weighting of the year of construction',
    ASSET_CLUSTER_DETAIL_name: 'Name',
    ASSET_CLUSTER_DETAIL_observations: 'Observations regarding grouping',
    ASSET_CLUSTER_DETAIL_reformYear: 'Year of refurbishment',
    ASSET_exploitingAboveGround: 'Exploitation above grade ({{surfaceUnit}})',
    ASSET_isEconomicExploit: 'Economic exploitation?',
    ASSET_TREE_childrenWithIncompatibilities: 'Children with incompatible characteristics',
    ASSET_TREE_unfinishedAssetWarning: 'Cannot group unfinished items',
    assetConstructiveElementsTotalBuiltSurfaceWithCommonElements: 'Built Surface Calculated',
    assetRepartitionErrorMessage: 'The addition of asset distributions is more than total workplan cost',
    ASSETS_accesibility: 'Accessibility',
    ASSETS_actualProductionsInLastThreeYears: 'Real productions in the last 3 years',
    ASSETS_add_new_asset: 'Add new asset',
    ASSETS_address: 'Address',
    ASSETS_addRoom: '+ Add room',
    ASSETS_agrologicalClasses: 'Agrological classes',
    ASSETS_alarmSystem: 'Alarm system',
    ASSETS_animalHusbrandy: 'Livestock',
    ASSETS_architecturalHomogeneity: 'Architectural homogeneity',
    ASSETS_assets: 'Assets',
    ASSETS_assetState: 'Asset status',
    ASSETS_atLeastOneBathAndOneBedroomWarning: 'It must have at least one bathroom and one bedroom.',
    ASSETS_averageAltitude: 'Average altitude',
    ASSETS_averageAnnualIncome: 'Average annual disposable income',
    ASSETS_basementSurface: 'Basement surface',
    ASSETS_basicFacilities: 'Basic facilities',
    ASSETS_book: 'Book',
    ASSETS_borrowersHabitualResidence: 'Habitual residence of the borrower',
    ASSETS_buildability: 'Development potential',
    ASSETS_buildingData: 'Building details',
    ASSETS_buildingDevelopment: 'Construction development',
    ASSETS_buildingsLinkedToExploitation: 'Buildings linked to exploitation',
    ASSETS_buildingsOwnEconomicActivity: 'Buildings own economic activity',
    ASSETS_buildingUse: 'Use',
    ASSETS_builtInWardrobes: 'Fitted wardrobes',
    ASSETS_builtWithCommonSurfaceM2: 'Constructed surface area with common areas ({{surfaceUnit}})',
    ASSETS_builtWithoutCommonSurfaceM2: 'Constructed surface area without common areas ({{surfaceUnit}})',
    ASSETS_cadastralInformation: 'Land registry information',
    ASSETS_cadastralReference: 'Land registry reference',
    ASSETS_cadastralReferenceContainerBuilding: "Building's land registry reference",
    ASSETS_cadastralSurface: 'Land registry',
    ASSETS_cadastralSurfaceM2: 'Land registry surface area ({{surfaceUnit}})',
    ASSETS_caracteristicUse: 'Use of the feature',
    ASSETS_ceilingType: 'Type of roof',
    ASSETS_characteristicAntiquity: 'Age of the feature',
    ASSETS_checkedSurface: 'Checked',
    ASSETS_checkedSurfaceM2: 'Surface area checked ({{surfaceUnit}})',
    ASSETS_clearanceHeight: 'Free height',
    ASSETS_closedSecurityCircuit: 'CCTV',
    ASSETS_clothesLine: 'Clothesline',
    ASSETS_coefTableBackground: 'Background Table Coefficient',
    ASSETS_coldHoursSunnyHours: 'Cold hours / Sun hours',
    ASSETS_commonFacilitiesSurface: 'Common facilities surface',
    ASSETS_communications: 'Communications',
    ASSETS_compatibleUses: 'Compatible uses',
    ASSETS_compositionAndSize: 'Composition and size',
    ASSETS_conditioning: 'Conditioning',
    ASSETS_conservationState: 'Conservation status',
    ASSETS_constructionYear: 'Year of construction',
    ASSETS_coreType: 'Location type',
    ASSETS_country: 'Country',
    ASSETS_createdBy: 'Created by',
    ASSETS_creationDate: 'Creation date',
    ASSETS_criticalJudgement: 'Critical judgment',
    ASSETS_cultivationAge: 'Age of culture',
    ASSETS_cultivationPlantingFramework: 'Crop planting frame',
    ASSETS_cultivesAndUses: 'Crops and uses',
    ASSETS_currentInfraestructure: 'Current infrastructure',
    ASSETS_currentPlanning: 'Planning in force',
    ASSETS_currentPricSaleRental: 'Current sale/rental price intervals (indicate maximum and minimum values ​​of the valuation methods used)',
    ASSETS_DefinitiveQualificationDateTime: 'Definitive classification date',
    ASSETS_delete: 'Delete',
    ASSETS_demand: 'Demand',
    ASSETS_demandRevaluationExpectations: 'Supply-demand and appreciation expectations',
    ASSETS_depth: 'Depth',
    ASSETS_description: 'Description',
    ASSETS_distribution: 'Distribution',
    ASSETS_divisibility: 'Divisibility',
    ASSETS_door: 'Door',
    ASSETS_dragBluePin: '* Drag the blue pin to the correct position. Once the location has been selected, it must be confirmed.',
    ASSETS_duplicate: 'Duplicate',
    ASSETS_economicExploitationObservations: 'Description of the exploitation',
    ASSETS_editActualProductionsInLastThreeYears: 'Edit information',
    ASSETS_energeticCertificateGrade: 'Current energy rating (emissions)',
    ASSETS_energeticCertificateGradeConsumo: 'Current energy rating (consumption)',
    ASSETS_environmentConsolidationDegreePercentage: 'Percentage degree of consolidation of surrounding area',
    ASSETS_environmentDescription: 'Description of the surrounding area',
    ASSETS_environmentSignificance: 'Meaning of the surrounding area',
    ASSETS_equipmentAndServices: 'Utilities and services',
    ASSETS_equippedKitchen: 'Fitted kitchen',
    ASSETS_executionDeadlinesAndConsequences: 'Lead times for urban development of the land. Consequences in case of non-compliance',
    ASSETS_existingEdifications: 'Existing constructions',
    ASSETS_Expedient: 'Case',
    ASSETS_exploitation: 'Exploitation',
    ASSETS_exploitationDescription: 'Description of the exploitation',
    ASSETS_expropiation: 'Expropriation. Status of the expropriation proceedings. Legal criteria applicable to its appraisal',
    ASSETS_externalWoodWork: 'Outdoor woodwork',
    ASSETS_extractionAndDistributionSystem: 'Extraction and distribution system',
    ASSETS_facade: 'Facade',
    ASSETS_facilities: 'Facilities',
    ASSETS_featuresMarket: 'Features comparable property market',
    ASSETS_floor: 'Floor',
    ASSETS_fundamentalBuildingElements: 'Main construction elements',
    ASSETS_generate: 'Generate',
    ASSETS_geolocate: 'Geolocate',
    ASSETS_groundFloorSurface: 'Ground floor area',
    ASSETS_groundLevel: 'Ground level (ECO/805/2003)',
    ASSETS_groundQualification: 'Ground classification',
    ASSETS_hasActiveCrop: 'Active cultivation',
    ASSETS_hasActiveCultivation: 'Has active cultivation?',
    ASSETS_hasAirConditioning: 'Air conditioning',
    ASSETS_hasCommonFacilities: 'Common facilities',
    ASSETS_hasElevator: 'Lift',
    ASSETS_hasEnergeticCertificate: 'Has energy efficiency certificate',
    ASSETS_hasFlatTerrain: 'Level ground',
    ASSETS_hasGas: 'Gas',
    ASSETS_hasGreenArea: 'Green spaces',
    ASSETS_hasHeating: 'Heating',
    ASSETS_hasHotWater: 'Hot water',
    ASSETS_hasIntercom: 'Intercom',
    ASSETS_hasPaving: 'Paving',
    ASSETS_hasPhone: 'Telephone',
    ASSETS_hasPipedGasNetwork: 'Piped gas network',
    ASSETS_hasPlumbingAndSanitaryEquipment: 'Plumbing and sanitary equipment',
    ASSETS_hasPool: 'Swimming pool',
    ASSETS_hasPublicLighting: 'Public lighting',
    ASSETS_hasRolledAccess: 'Road access',
    ASSETS_hasSanitation: 'Sanitation',
    ASSETS_hasSanitationNetwork: 'Sanitation Network',
    ASSETS_hasSecurity: 'Security',
    ASSETS_hasSportsZone: 'Sporting area',
    ASSETS_hasTelephoneNetwork: 'Telephone network',
    ASSETS_hasTennisOrPaddle: 'Tennis / Paddle',
    ASSETS_hasWarehouse: 'Warehouse',
    ASSETS_hasWaterDistribution: 'Water distribution',
    ASSETS_hasWaterSupply: 'Water supply',
    ASSETS_heatingType: 'Heating type',
    ASSETS_hotWaterType: 'Hot water type',
    ASSETS_humidityRegime: 'Humidity Regime',
    ASSETS_hydrology: 'Hydrology',
    ASSETS_idufir: 'IDUFIR',
    ASSETS_industrialBuildingCharacteristics: 'Industrial building characteristics',
    ASSETS_infrastructures: 'Infrastructures',
    ASSETS_inscription: 'Entry',
    ASSETS_internalWoodType: 'Indoor woodwork',
    ASSETS_isAdequatedToCurrentPlanning: 'Compliant with planning in force?',
    ASSETS_isEconomicExploitation: 'Is it an Economic Exploitation?',
    ASSETS_isPlot: 'Is it a plot?',
    ASSETS_jobOccupation: 'Workplace',
    ASSETS_lastRehabilitationYear: 'Year of refurbishment',
    ASSETS_legalSituation: 'Legal status',
    ASSETS_license: 'Licence',
    ASSETS_lngLatWarning: 'It has not been possible to retrieve details on the town/city and surrounding area',
    ASSETS_localCharacteristics: 'Nature of the premises',
    ASSETS_characteristics: 'Natures',
    ASSETS_locality: 'Town/City',
    ASSETS_localityMainFeatures: 'Main features of the town/city',
    ASSETS_locationComparedToSameBuildingElements: 'Location of property in the building compared to other elements in the same building',
    ASSETS_locationComparedToSameMunicipality: 'Location of property compared to others in the same municipality',
    ASSETS_locationComparedToSameZone: 'Location of property compared to others in the same area',
    ASSETS_locationIndustrialShip: 'Ship location',
    ASSETS_locationStreetType: 'Location - Street type',
    ASSETS_loftSurface: 'Mezzanine surface',
    ASSETS_LOG_currentValue: 'Current value',
    ASSETS_LOG_elementWithId: 'The element',
    ASSETS_LOG_previusValue: 'Last value',
    ASSETS_LOG_was: 'has',
    ASSETS_mainElements_Deck: 'Roof',
    ASSETS_mainElements_DeckDescription: 'Roof description',
    ASSETS_mainElements_Enclosure: 'Enclosures',
    ASSETS_mainElements_EnclosureDescription: 'Enclosures description',
    ASSETS_mainElements_Foundation: 'Foundations',
    ASSETS_mainElements_FoundationDescription: 'Foundations description',
    ASSETS_mainElements_Structure: 'Structure',
    ASSETS_mainElements_StructureDescription: 'Structure description',
    ASSETS_managementPhase: 'Management phase',
    ASSETS_managementSystem: 'Management system',
    ASSETS_managementTypology: 'Management typology',
    ASSETS_manuallySelection: 'Choose location manually',
    ASSETS_changeUrbanSituationMonths: 'Expected term of change of the urban situation of the land (months)',
    ASSETS_market: 'Market',
    ASSETS_expectedObtainingLicenseMonths: 'Expected term for obtaining a license (months)',
    ASSETS_marketMortgageValueDifferences: 'Difference in market and mortgage values',
    ASSETS_maxHeight: 'Maximum height',
    ASSETS_mezzanineSurface: 'Between-floor surface',
    ASSETS_microclimate: 'Microclimate',
    ASSETS_minimunPlot: 'Minimum plot',
    ASSETS_modeAndManagementSystem: 'Management mode and system',
    ASSETS_morphology: 'Morphology',
    ASSETS_notCorrectLocation: '* If the location shown is not correct, you can select it manually',
    ASSETS_numberOfBuildingAccesses: 'Access to the building',
    ASSETS_observations: 'Remarks',
    ASSETS_ocupation: 'Occupancy',
    ASSETS_offer: 'Offer',
    ASSETS_officeCharacteristics: 'Office characteristics',
    ASSETS_openSpace: 'Open space',
    ASSETS_otherBuildings: 'Other buildings',
    ASSETS_otherSurface: 'Other surface',
    ASSETS_paper: 'Sheet',
    ASSETS_parkings: 'Parking',
    ASSETS_pavementType: 'Paving type',
    ASSETS_pendingInfrastructure: 'Pending infrastructure',
    ASSETS_pendingInfrastructuresPercentage: 'Percentage of outstanding infrastructures',
    ASSETS_pendingProcedures: 'Procedures remaining for land to be considered ready for development',
    ASSETS_percentageOfValuedArea: 'Percentage of area appraised',
    ASSETS_percentOfWorkDone: 'Percentage of urbanization executed',
    ASSETS_placeName: 'Place',
    ASSETS_planningDevelopment: 'Planning development',
    ASSETS_plotCode: 'Plot',
    ASSETS_pluviometry: 'Rainfall',
    ASSETS_podeAndManagementSystem: 'Management mode and system',
    ASSETS_polivalentUse: 'Multifunction use',
    ASSETS_polygonCode: 'Estate',
    ASSETS_population: 'Town/City No. residents',
    ASSETS_populationEvolutionPercent: 'Recent change in population',
    ASSETS_portal: 'Entrance',
    ASSETS_postalCode: 'Post code',
    ASSETS_predominantOfficeUse: 'Does office use predominate?',
    ASSETS_productionDestination: 'Production destination',
    ASSETS_productToDevelopTypes: 'Product to be developed',
    ASSETS_prohibitedUses: 'Prohibited uses',
    ASSETS_propertyRegistrationName: 'Name of the property register',
    ASSETS_propertySize: 'Property size',
    ASSETS_ProtectionTimeLimit: 'Protection period (years)',
    ASSETS_province: 'Province',
    ASSETS_ProvisionalQualificationDateTime: 'Provisional classification date',
    ASSETS_pubjectCorrespondingManagementInitiative: 'Person responsible for management',
    ASSETS_qualityOfBuilding: "Constructions' quality",
    ASSETS_raisedFloorOrFalseCeiling: 'Raised floor / False ceiling',
    ASSETS_reformLastYear: 'Year of refurbishment',
    ASSETS_regionName: 'Region',
    ASSETS_registeredPropertyNumber: 'Property',
    ASSETS_registeredSurfaceM2: 'Registry surface area ({{surfaceUnit}})',
    ASSETS_registralSurface: 'Registry',
    ASSETS_registryInfo: 'Registry information',
    ASSETS_remarkableMicroclimates: 'Remarkable microclimates',
    ASSETS_renovation: 'Renovation',
    ASSETS_residenceType: '1st/2nd residence',
    ASSETS_roomType: 'Type of room',
    ASSETS_scopeSurface: 'Scope surface area',
    ASSETS_section: 'Section',
    ASSETS_securityDoor: 'Security door',
    ASSETS_setBacks: 'Setbacks',
    ASSETS_setOff: 'Emboss',
    ASSETS_sourceOfWaterAndFlow: 'Water source and flow',
    ASSETS_species: 'Species',
    ASSETS_stairs: 'Staircase',
    ASSETS_status: 'Status',
    ASSETS_stays: 'Rooms',
    ASSETS_streetCategory: 'Street category',
    ASSETS_streetName: 'Street name',
    ASSETS_streetNotExist: 'Street cannot be found on the street map',
    ASSETS_streetNumber: 'Number',
    ASSETS_streetType: 'Type of street',
    ASSETS_structureLight: 'Structure light',
    ASSETS_subjectCorrespondingManagementInitiative: 'Person responsible for management',
    ASSETS_subTypology: 'Subtype',
    ASSETS_surface: 'Surface area',
    ASSETS_surfaceM2: 'Surface area ({{surfaceUnit}})',
    ASSETS_surfaceToDemolish: 'Surface area to be demolished ({{surfaceUnit}})',
    ASSETS_surveillance: 'Surveillance',
    ASSETS_temperatures: 'Temperatures',
    ASSETS_terrainDescription: 'Description of the ground',
    ASSETS_terrainFeatures: 'Nature of the site',
    ASSETS_terrainInfraestructures: 'Site infrastructure',
    ASSETS_terrainSurface: 'Site surface area',
    ASSETS_terrainTexture: 'Terrain texture',
    ASSETS_topography: 'Topography',
    ASSETS_topographyAndAgrologicalCharacteristics: 'Topography and agrological features',
    ASSETS_topologyAndSlope: 'Topology and relief',
    ASSETS_totalFloorsAboveGround: 'Floors above ground level',
    ASSETS_totalFloorsBellowGround: 'Floors below ground level',
    ASSETS_totalPlotSurface: 'Plot surface area ({{surfaceUnit}})',
    ASSETS_typology: 'Type',
    ASSETS_unemploymentRatePercent: 'Unemployment rate',
    ASSETS_urbanBurdens: 'Town planning charges (assignments town planning costs, acquired obligations, etc)',
    ASSETS_urbanCoreProximity: 'Proximity to urban centre',
    ASSETS_urbanisticManagment: 'Town planning management',
    ASSETS_urbanisticParameters: 'Town planning parameters',
    ASSETS_urbanisticParams: 'Town planning parameters',
    ASSETS_urbanisticQualificationAndManagement: 'Town planning management and classification',
    ASSETS_urbanisticQualification: 'Town planning classification',
    ASSETS_urbanizationCostsAndPendingCharges: 'Town planning costs and outstanding charges',
    ASSETS_urbanizationState: 'Town planning status',
    ASSETS_use: 'Use',
    ASSETS_usefulSurfaceM2: 'Useful surface area ({{surfaceUnit}})',
    ASSETS_vegetativeState: 'Vegetative state',
    ASSETS_volume: 'Volume',
    ASSETS_wallType: 'Type of walls',
    ASSETS_withPaving: 'Paving',
    ASSETS_withPipedGasNetwork: 'Piped gas network',
    ASSETS_withSanitationNetwork: 'Sanitation network',
    ASSETS_withStreetLighting: 'Street lighting',
    ASSETS_withTelephoneNetwork: 'Telephone network',
    ASSETS_withWaterSupply: 'Water supply',
    ASSETS_marketStudy: 'Remember that this modification will imply changes in the methods where this market study is being used.',
    ASSETS_marketStudyInformation: '*Remember that homogenization must be broken down for ECO valuations itself.',
    ASSETS_marketStudyRemove: 'Are you sure you want to remove the market study?',
    AssetState: 'Status',
    assetSubTypology: 'Subtype',
    AssetSubTypology: 'Subtype',
    AssetTypology: 'Type',
    AssetUse: 'Use',
    assignRoles: 'Assign roles',
    Assistance: 'Assistance',
    automaticInvoicing: 'Automatic invoicing',
    avm_add_valuation: 'Add Valuation',
    avm_additionalInfo: 'Additional Info.',
    avm_alerts: 'Alerts',
    avm_assets: 'Assets',
    avm_back: 'Back',
    avm_backtesting_message_2: 'Only one backtesting can be generated per order. Choose one of the valued lots and click on the "Create backtesting" button. ',
    avm_backtesting_message_3: 'Choose valuation that will modify the assets used in the backtesting.',
    avm_backtesting_message: 'The backtesting process is completing. Was requested on {{dateValue}}. If it takes more than 30 minutes, contact the technical service.',
    avm_backtestings_files: 'Backtesting files',
    avm_backtestings: 'Backtestings',
    avm_backtestingSelectedBatchValorationIdis: 'Batch Id',
    avm_batchAlertType: 'Type',
    avm_batchId: 'Batch ID',
    avm_batchValorationId: 'Valoration ID',
    avm_cadastralApiBatchEstimatedFinishDatetime: 'Estimated Finish Time',
    avm_cadastralApiBatchFinishedDatetime: 'Fin time',
    avm_cadastralApiBatchStartDatetime: 'Start time',
    avm_cadastralApiBatchStartUserFullName: 'Username',
    avm_cadastralApiBatchStartUserId: 'Id del User',
    avm_cadastralData: 'Cadastral API',
    avm_calculated: 'Calculated',
    avm_checkValue_tooltip: 'AVM (A) assisted by valuer as per BDE Supervisory Guide. The valuation algorithm will take into account additional checks and expert judgment made by a valuer regarding the parameters used in valuing the asset.',
    avm_checkValue: 'Assisted Check – AVM (A)',
    avm_choose_backtesting: 'Choose Backtesting',
    avm_column_preselection: 'Columns preselection',
    avm_confidenceIndexPercent: 'Confidence Index %',
    avm_create_batch_headerRowsCount_tooltip: 'Header rows that the table has. Usually 1.',
    avm_create_batch_initialCell_tooltip: 'The cell where the table begins to read in Excel. For example: B2',
    avm_creationDate: 'Creation Date',
    avm_databaseVersion: 'DataBase Version',
    avm_dbVersion: 'Datadase Version',
    avm_deliveredDatetime: 'Delivery date',
    avm_deliveredSolveBacktestingValorationId: 'Valuation Id',
    avm_deliveredUserFullName: 'Full name',
    avm_deliveredUserId: 'User ID',
    avm_deliverToClient: 'Deliver to client',
    avm_deliverToClientData: 'Delivered client data',
    avm_detail: 'Detail',
    avm_discrepancies_filter_ids_tooltip: 'Enter the IDS of the assets separated by ; to search for them. <br> <br> Example: 1;2;3;4;5 ... ',
    avm_discrepancies: 'Discrepancies',
    avm_edit_translation: 'Edit Translate',
    avm_enrichment_criteria: 'Enrichment Criteria',
    avm_errorPercent: 'Error %',
    avm_establish_discrepancies: 'Establish criteria',
    avm_estimatedFinishDatetime: 'Estimated Finish Datetime',
    avm_failureProbability: 'Probabilidad de fallo %',
    avm_file: 'File',
    avm_generate_backtesting_file: 'Generate backtesting file',
    avm_generated_backtesting_files: 'Generated backtesting files',
    avm_go_to_order_detail: 'Go to Order detail',
    avm_hasMoreThanOneConstructiveElement: 'With more than one constructive element (origin)',
    avm_headerRowsCount: 'Header Rows Count',
    avm_hittingProbability: 'Probabilidad de acierto %',
    avm_ids_to_review_value_tooltip: 'By default they will be all assets, unless ids are specified',
    avm_initialCell: 'Initial Cell',
    avm_isDelivered: 'It is delivered',
    avm_isDiscrepancySolved: 'Discrepancy Solved',
    avm_keepDiscrepancies: 'Keep Discrepancies',
    avm_login_information: 'Login information',
    avm_logins_log: 'Logins log',
    avm_orderCustomerFullName: 'Order Customer Name',
    avm_orderDescription: 'Order Description',
    avm_orderOrderDateTime: 'Order Date',
    avm_orderType: 'Order type',
    avm_origin: 'Origin',
    avm_pq: 'PQ',
    avm_providerAssetId: 'Asset ID',
    avm_providerAssetIds: 'Provider Asset IDs',
    avm_relatedAssetHasMoreThanOneConstructiveElement: 'With more than one constructive element (calculated)',
    avm_resolve_discrepancies: 'Confirm criteria',
    avm_scriptVersion: 'Script Version',
    avm_see_batch: 'See the batch',
    avm_see_valuation: 'See Valuation',
    avm_solve_incidents: 'Solve incidents',
    avm_solving: 'Solving...',
    avm_this_batch_has_been_duplicated_from: 'This batch has been duplicated from batch with ID:',
    avm_this_is_a_duplicated_batch: 'This is a duplicated Batch',
    avm_useInValorationBatchAssetSurfaceData: 'Discrepancy Surface',
    avm_useInValorationBatchAssetTypologyData: 'Discrepancy Tipology',
    avm_useReferenceUnitValue_tooltip: 'Assessor-assisted AVM (A) as per BDE Supervisory Guide. The valuation algorithm will take into account additional checks and expert judgment made by an appraiser. The reference value is obtained from the input file, from that column that has been marked as “Reference unit value of the main element”. In those that do not have a value, the default algorithm will be applied.',
    avm_useReferenceUnitValue: 'Assisted valuation – AVM (A)',
    avm_useWitnessesAverageSurfaceInBatchAssetsDontHaveAnySurface_tooltip: 'By selecting this option, the valuation algorithm will contribute an area to the assets that they do not have, calculated from the core database (the latest version available) of the cluster',
    avm_useWitnessesAverageSurfaceInBatchAssetsDontHaveAnySurface: 'Witness Estimated Surfaces',
    avm_valorations: 'Valorations',
    avm_valorationState: 'Valuation State',
    avm_value_revision_similarity_index_tooltip: 'This index limits the difference of the witnesses that are going to enter new, with respect to those already selected. With 1 being the maximum similarity, this value should be increased to accept more difference between the controls. Therefore, 1.15 would be like saying that we have a deviation of 15% in similarity with respect to those that have already been selected. It is not recommended to go above 1.5. ',
    avm_witnessesLimitToCalculateAverageSurfaceInBatchAssetsDontHaveAnySurface_tooltip: 'Minimum number of witnesses in the cluster to calculate the surface on those assets that do not have. In those that do not reach the minimum witnesses, they will be left without a surface and therefore will not be valued.',
    avm_witnessesLimitToCalculateAverageSurfaceInBatchAssetsDontHaveAnySurface: 'Limite minimo de testigos para la media',
    backtesting: 'Backtesting',
    bank_account_number_for_payment: 'Bank Account Number for payment',
    basicFilters: 'Basic filters',
    batchAssetConstructiveElementsTotalBuiltSurfaceWithCommonElements: 'Built Surface Origin',
    batchDateTime: 'Fecha del Lote',
    BATCHES_addedUserName: 'User',
    BATCHES_batchId: 'ID',
    BATCHES_batchState: 'Status',
    BATCHES_creationDate: 'Creation date',
    BATCHES_creationMaxDate: 'To',
    BATCHES_creationMinDate: 'From',
    BATCHES_fileName: 'File name',
    BATCHES_processError: 'Process error',
    BatchStatus_0: 'Pending',
    BatchStatus_1: 'In progress',
    BatchStatus_2: 'Processed correctly',
    BatchStatus_3: 'Error',
    batchWithId: 'Batch{{massiveBatchId}}',
    requestWithId: 'Request {{requestId}}',
    bathroomNumber: 'Number of bathrooms',
    BDE_FILTER_IsReportedToBde: 'Reported to BoS',
    BDE_FILTER_Trimester: 'Quarter',
    BDE_FILTER_YearReportedToBdE: 'Year reported to BoS',
    bedroomNumber: 'Number of bedrooms',
    BILL_LIST_invoiceNumber: 'Invoice no.',
    billing_email: 'Billing email',
    billing_info: 'Invoicing Details',
    BILLING_INFORMATION_addAsDefaultMethod: 'Add as predetermined method',
    BILLING_INFORMATION_addPaymentMethods: 'Add payment methods',
    BILLING_INFORMATION_billingData: 'Invoicing details',
    BILLING_INFORMATION_card: 'Card',
    BILLING_INFORMATION_cardNumber: 'Card number',
    BILLING_INFORMATION_creditCardExpiryDateMonth: 'Expiry month',
    BILLING_INFORMATION_creditCardExpiryDateYear: 'Expiry year',
    BILLING_INFORMATION_cvv: 'CVV',
    BILLING_INFORMATION_defaultPayment: 'Predetermined payment option',
    BILLING_INFORMATION_expirationDate: 'Expiry date',
    BILLING_INFORMATION_paymentMethods: 'Payment methods',
    BILLING_INFORMATION_payments: 'Payments',
    BILLING_INFORMATION_redirect: 'Redirecting...',
    BILLING_INFORMATION_redirectToSafeLink: 'Redirecting to a secure website.',
    BILLING_INFORMATION_setAssDefault: 'Set as predetermined payment option',
    BILLING_INFORMATION_sureRemoveElement: 'Are you sure you want to delete this payment method?',
    BILLING_INFORMATION_warningDescription: 'For the payment of the appraisal files made, we store your card details with total security under the Redsys system. To do this, enter them in the pop-up window of the bank that will open when you click on "Add payment methods", a process that does not entail any charge on your card.',
    BillingDataCopyOptions_0: 'Copy owner details',
    BillingDataCopyOptions_1: 'Copy prescriber details',
    BillingDataCopyOptions_2: 'Copy account data',
    billingFileName: 'Invoice',
    billingStatus: 'Invoicing status',
    billingTaxType: 'Tax type',
    BillingTaxType: 'Tax type',
    billingType: 'Invoicing type',
    Book: 'Book',
    BooleanQuestionValues_false: 'No',
    BooleanQuestionValues_true: 'Yes',
    BUILDING_ELEMENT_COMPARISON_observations: 'Observations regarding the construction hypothesis',
    BUILDING_ELEMENT_valuationOtherMethod: 'Manually',
    BuildingInformation: 'Building details',
    buildingLastRehabilitationYear: 'Year of rehabilitation',
    Buildings: 'Buildings',
    buildingUse: 'Building use',
    BuildingUse: 'Use',
    buildingYear: 'Year of construction',
    builtConstructionYear: 'Construction Year',
    BusinessPremises: 'Commercial premises',
    BUTTON_ACTION_accept: 'Accept',
    BUTTON_ACTION_cancel: 'Cancel',
    BUTTON_ACTION_reject: 'Reject',
    BUTTON_ACTION_resend: 'Resend',
    cadastral: 'Land registry',
    cadastralData: 'Land registry data',
    cadastralReference: 'Land registry reference',
    CadastralReference: 'Land registry reference',
    CadastralReferenceContainerBuilding: "Building's land registry reference",
    CadastralSurfaces: 'Land registry surface area',
    calculated: 'Calculated',
    CAN_CREATE_APPRAISAL_addPaymentMethod: 'Add payment method',
    CAN_CREATE_APPRAISAL_selectPlan: 'Select a plan',
    cancel: 'Cancel',
    cancelled: 'Cancelled',
    cancelledDate: 'Cancellation date',
    CeilingType: 'Type of roof',
    change_asset_status: 'If you change the status of the asset to finished you will lose the data relative to the current valuations entered. Do you wish to continue?',
    change_asset_typology: 'The modifications made imply that those methods that do not apply to the chosen typology are eliminated. do you wish to continue? ',
    change_date_justification: 'Justification',
    change_password: 'Change password',
    change_picture: 'Change image',
    CHANGE_TEMPORAL_PASSWORD_change: 'Change',
    CHANGE_TEMPORAL_PASSWORD_changeTemporalPassword: 'Change temporary password',
    CHANGE_TEMPORAL_PASSWORD_newPassword: 'New password',
    CHANGE_TEMPORAL_PASSWORD_passwordsNotMatch: 'The passwords do not match',
    CHANGE_TEMPORAL_PASSWORD_repeatPassword: 'Repeat the password',
    CHANGE_TEMPORAL_PASSWORD_yourPasswordIsTemporal: 'Your current password is temporary. Please change it for security reasons',
    checked: 'Checked',
    CheckedSurfaces: 'Checked surface area',
    checkLicenseData: 'Please review your licence details',
    ChecksRelatedToAttachedDocumentation: 'Checks performed on attachments',
    cif_nif: 'Tax ID No.',
    cif: 'Tax ID No.',
    city: 'City',
    claims: 'Claims',
    claimsResolutionAverageTime: 'Average claim resolution time',
    client: 'Customer',
    close: 'Close',
    closed: 'Closed',
    CLUSTER_addAssetToCluster: 'Add asset',
    CLUSTER_addUnit: 'Add unit',
    CLUSTER_buildingUse: 'Building use',
    CLUSTER_chooseClusterAssets: 'Choose assets',
    CLUSTER_chooseClusterType: 'Choose type of grouping',
    CLUSTER_clusterType: 'Cluster type',
    CLUSTER_completeClusterData: 'Complete cluster data',
    CLUSTER_constructionYear: 'Year of construction',
    CLUSTER_createCluster: 'Create new cluster',
    CLUSTER_createClusterMessage: 'The elements to be grouped must always be of the same type according to the upper drop-down.',
    CLUSTER_details: 'Cluster details',
    CLUSTER_next: 'Next',
    CLUSTER_noSelectableAssets: 'Non-selectable assets',
    CLUSTER_propertyRegistry: 'Registry property',
    CLUSTER_selectableAssets: 'Selectable assets',
    CLUSTER_sureDelete: 'Are you sure you want to delete this cluster?',
    CLUSTER_TERRAIN_clusterTerrain: 'Cluster of plots',
    CLUSTER_TERRAIN_typologyInfo: 'This asset belongs to a land grouping and is not possible to edit its Use. For its edition you must extract it from the grouping.',
    CLUSTER_typologyInfo: 'This asset belongs to a group and it is not possible to edit its status or change its use to land / rustic farms or economic farms. For editing you must extract it from the grouping.',
    CLUSTER_unit: 'unit',
    CLUSTER_valuationSurfaceSelectedMessage: 'The selected surface can be modified in Units',
    CLUSTERS_canNotChangeUseToTerrain: 'This asset belongs to a cluster. If you want to convert it into land, it is necessary to remove it from that grouping ',
    CLUSTERS_surfaces_error: 'It is necessary to add the surfaces in the Surfaces section of each asset and select them in the Units section.',
    CLUSTERS_valorations_summary: 'Valuation breakdown',
    clusterSurface: 'Cluster surface',
    ClusterTerrainSurfaces_0: 'Useful',
    ClusterTerrainSurfaces_12: 'Constructed without common areas',
    ClusterTerrainSurfaces_6: 'Constructed with common areas',
    CLUTER_reformLastYear: 'Year of reform',
    CODE_MANAGEMENT_addNewCode: 'Add code',
    CODE_MANAGEMENT_code_management: 'Code management',
    CODE_MANAGEMENT_discount_percentage: 'Percentage discount',
    CODE_MANAGEMENT_discountPercent: 'Percentage',
    CODE_MANAGEMENT_ending_date: 'End date',
    CODE_MANAGEMENT_identifier: 'Identifier',
    CODE_MANAGEMENT_initial_date: 'Start date',
    CODE_MANAGEMENT_is_active: 'Active',
    CODE_MANAGEMENT_isActive: 'Active',
    CODE_MANAGEMENT_isActiveFromDateTime: 'Start date',
    CODE_MANAGEMENT_isActiveUntilDateTime: 'End date',
    CODE_MANAGEMENT_payment_summary: 'Payment summary',
    CODE_MANAGEMENT_plus_add_new: '+ Add new',
    CODE_MANAGEMENT_promotionalCode: 'Identifier',
    CODE_MANAGEMENT_user: 'User',
    code: 'Code',
    Code: 'Code',
    codeManagement: 'Code management',
    collaborator: 'Collaborator',
    collaboratorCheckbox: 'Collaborator?',
    COMMERCIAL_MANAGER_paymentOption: 'Payment method',
    COMMERCIAL_MANAGER_USERS_add_commercial_manager: 'Add commercial manager',
    COMMERCIAL_MANAGER_USERS_appraisals_in_process_count: 'Appraisals in progress',
    COMMERCIAL_MANAGER_USERS_email: 'Email',
    COMMERCIAL_MANAGER_USERS_first_name: 'Name',
    COMMERCIAL_MANAGER_USERS_is_active: 'Active user',
    COMMERCIAL_MANAGER_USERS_lastname: 'Surnames',
    COMMERCIAL_MANAGER_USERS_national_identity_document: 'ID No.',
    COMMERCIAL_MANAGER_USERS_plus_add_user: '+ Add user',
    COMMERCIAL_MANAGER_USERS_position: 'Position',
    COMMERCIAL_MANAGER_USERS: 'Commercial Manager Users',
    commercial_managers: 'Commercial managers',
    commercialFee: 'Fees charged',
    CommercialManager: 'Commercial managers',
    CommercialMedian: 'Large retail premises',
    COMPARISON_METHOD_comparison: 'Comparison',
    COMPARISON_METHOD_depreciation: 'Functional Dep. ({{currency_per_surfaceUnit}})',
    COMPARISON_METHOD_depreciationTotal: 'Functional Dep. ({{currency}})',
    COMPARISON_METHOD_direction: 'Address',
    COMPARISON_METHOD_finalValue: 'Final value ({{currency_per_surfaceUnit}})',
    COMPARISON_METHOD_finalValueMonthly: 'Final value ({{currency_per_surfaceUnit}}/month)',
    COMPARISON_METHOD_homogCoef: 'Approval Coef.',
    COMPARISON_METHOD_homogeneizedValue: 'Approved value',
    COMPARISON_METHOD_homogeneizedValueDepreciated: 'Depreciated approved value ({{currency_per_surfaceUnit}})',
    COMPARISON_METHOD_initialValue: 'Market value ({{currency_per_surfaceUnit}})',
    COMPARISON_METHOD_initialValueMonthly: 'Market value ({{currency_per_surfaceUnit}}/month)',
    COMPARISON_METHOD_moreInfo: 'Further information',
    COMPARISON_METHOD_normalizedComparison: 'adjusted comparison',
    COMPARISON_METHOD_observations: 'Observations related to the market study',
    COMPARISON_METHOD_reduc: 'Reduc. / Negotiation',
    COMPARISON_METHOD_surface: 'Surface area ({{surfaceUnit}})',
    COMPARISON_METHOD_typology: 'Type and sub-type',
    COMPARISON_METHOD_UnitValue: 'Market value ({{currency_per_surfaceUnit}})',
    COMPARISON_METHOD_weight: 'Weight',
    COMPARISON_METHOD_witnessInformation: 'Witness information',
    complete: 'Complete',
    completeRequiredFields: 'Required fields filled in',
    completing: 'Completing...',
    COND_WARN_OBS_obsCode: 'REMARK',
    conditions: 'Conditioning factors',
    config_report: 'Configure your report',
    config: 'Configuration',
    confirm_and_get_assets: 'Confirmar y obtener activos',
    confirm: 'Confirm',
    skip: 'Skip',
    confirming: 'Confirming',
    confirmInvoice: 'Confirm invoice',
    confirmPassword: 'Repeat the password',
    Connection_label: 'Connection type',
    connections: 'Connections',
    ConservationState: 'Conservation status',
    ConstructionYear: 'Year of construction',
    contact_number: 'Contact us',
    contactLicenseeAdmin: 'Please contact your licence administrator',
    CONTRACT_METHOD_adoptedValueType: 'Type of value adopted',
    CONTRACT_METHOD_adoptedValueTypes: 'Types of value adopted',
    CONTRACT_METHOD_calculationDate: 'Calculation date',
    CONTRACT_METHOD_constructionCost: 'Construction cost',
    CONTRACT_METHOD_contractId: 'Contract identifier',
    CONTRACT_METHOD_contractInitialDate: 'Contract start date',
    CONTRACT_METHOD_contractualData: 'Contractual data',
    CONTRACT_METHOD_economicData: 'Economic data',
    CONTRACT_METHOD_monthlyNetIncome: 'Monthly net income',
    CONTRACT_METHOD_necessaryExpenses: 'Necessary costs',
    CONTRACT_METHOD_nextChargeDate: 'Date of next payment',
    CONTRACT_METHOD_observationsNetIncome: 'Remarks in relation to net income / Payment estimate information',
    CONTRACT_METHOD_pendingContractDuration: 'Remaining contract duration',
    CONTRACT_METHOD_technicalValues: 'Technical construction values',
    CONTRACT_METHOD_tenantsFreeValue: 'Tenant free value',
    CONTRACT_METHOD_updateType: 'Update type',
    cost_group_valuation_message_information: '* The construction cost that will be indicated will correspond to the weighting of all the elements that make up the group.',
    COST_METHOD_comeFromWorkInProgress: 'This value is taken from the distribution in the Construction Status tab.',
    COST_METHOD_futureMessage: 'The rest of the fields can only be edited from the Current Rating tab',
    COST_METHOD_isUnfinishedMessage: 'The adopted surface can only be modified in the Work Status chapter.',
    COST_METHOD_isUnfinishedClusterMessage: 'The adopted surface can only be modified in the Units chapter.',
    COST_METHOD_observations: 'Remarks',
    COST_METHOD_justification_rent: 'Justification of % of income attributable to the property',
    COST_METHOD_observationsGround: 'Rationale for Land Value Calculations',
    COST_METHOD_justification_construction_expenses: 'Justification of construction costs',
    COST_METHOD_justification_construction_expenses_text: 'The construction cost adopted has been obtained from internal statistics prepared from specialized magazines contrasted with real construction budgets.',
    costMethodRepercussionCalculateMessage: 'If you amend the repercussion entry mode, you will lose the data included to date. Do you want to proceed?',
    country: 'Country',
    CountryThreeLetterISORegionName: 'Country',
    CREATE_CRAWLEDASSET_annexes: 'Annexes',
    CREATE_CRAWLEDASSET_annexesInfoMessage: 'If necessary, the value of these annexes will have to be deducted from the offer value of the comparable',
    CREATE_CRAWLEDASSET_buildeableSurface: 'Buildable surface',
    CREATE_CRAWLEDASSET_builtSurface: 'Built surface',
    CREATE_CRAWLEDASSET_contactNumber: 'Contact number',
    CREATE_CRAWLEDASSET_coverTerraceSurface: 'Sup. covered terrace ({{surfaceUnit}}) ',
    CREATE_CRAWLEDASSET_crawledDateTime: 'Witness creation date: {{date}}',
    CREATE_CRAWLEDASSET_crawledSource: 'Witness origin',
    CREATE_CRAWLEDASSET_numberOfGarages: 'No. of parking spaces',
    CREATE_CRAWLEDASSET_observations: 'Observations',
    CREATE_CRAWLEDASSET_offerType: 'Offer type',
    CREATE_CRAWLEDASSET_offerValue: 'Offer value',
    CREATE_CRAWLEDASSET_providerCreateDate: 'Witness creation date:',
    CREATE_CRAWLEDASSET_providerData: 'Provider information',
    CREATE_CRAWLEDASSET_providerId: 'Provider ID',
    CREATE_CRAWLEDASSET_providerUrl: 'Provider URL',
    CREATE_CRAWLEDASSET_sourceType: 'Source type',
    CREATE_CRAWLEDASSET_storageRoom: 'Storage room',
    CREATE_CRAWLEDASSET_uncoverTerraceSurface: 'Sup. open terrace ({{surfaceUnit}}) ',
    create: 'Create',
    createBacktesting: 'Create Backtesting',
    created: 'Created',
    creating: 'Creating',
    CriteriaTypes_0: '% of income',
    CriteriaTypes_1: '% of total construction costs',
    CriteriaTypes_2: 'Manual ({{currency}})',
    Cultural: 'Cultural',
    currency_per_month: '{{currency}}/mes',
    currency_per_year: '{{currency}}/año',
    currency: 'Currency',
    CurrentWorkflowStep: 'Status',
    Customer: 'Customer',
    CUSTOMERS_add_new_customer: '+ Add new customer',
    CUSTOMERS_billing_information: 'Invoicing Details',
    CUSTOMERS_cif_nif: 'Tax ID No.',
    CUSTOMERS_cif: 'Tax ID No.',
    CUSTOMERS_contactEmail: 'Email',
    CUSTOMERS_contactPhone: 'Telephone',
    CUSTOMERS_creation_date: 'Creation date',
    CUSTOMERS_customers: 'Customers',
    CUSTOMERS_email: 'Email',
    CUSTOMERS_firstName: 'Name',
    CUSTOMERS_its_a_company: "It's a company",
    CUSTOMERS_lastName: 'Surnames',
    CUSTOMERS_name: 'Name',
    CUSTOMERS_NEW_add_customer: 'Add new customer',
    CUSTOMERS_nif: 'Tax ID No.',
    CUSTOMERS_observations: 'Remarks',
    CUSTOMERS_profile: 'Profile',
    customers: 'Customers',
    customerTranslationType: 'Customer Translation Type',
    customerValue: 'Customer Value',
    dashboard: 'Dashboard',
    DATE_OF_VISIT_addVisitDate: '+ Add visit date',
    DATE_OF_VISIT_allDay: 'Any time of the day',
    DATE_OF_VISIT_date: 'Date',
    DATE_OF_VISIT_from: 'From',
    DATE_OF_VISIT_until: 'To',
    DATES_addedDateTime: 'added',
    DATES_appraiserAllDataSentDeadlineDateTime: 'deadline for sending report',
    DATES_exchangeFinishDateTime: 'Sent report deadline',
    DATES_obtainedDatetime: 'Obtained date',
    DATES_solvedDateTime: 'resolved',
    DATES_visitScheduleDateTime: 'visit date',
    DATES_visitScheduleDeadlineDateTime: 'scheduling deadline',
    degree: 'Qualification',
    delete: 'Delete',
    DELIVERABLE_MODAL_deliverable: 'Deliverable',
    DELIVERABLE_MODAL_documentName: 'Document name',
    DELIVERABLE_MODAL_documentVersion: 'Document version',
    DELIVERABLE_MODAL_downloadAll: 'Download all',
    DELIVERABLE_MODAL_downloading: 'Downloading',
    DELIVERABLE_MODAL_emissionDate: 'Issue date',
    DELIVERABLE_MODAL_expirationDate: 'Expiry date',
    DELIVERABLE_MODAL_external: 'External',
    DELIVERABLE_MODAL_generationDate: 'Generation date',
    DELIVERABLE_MODAL_internal: 'Internal',
    DELIVERABLE_REPOSITORY_addRow: '+ Add new deliverable',
    DELIVERABLE_REPOSITORY_deliverable: 'Deliverable',
    DELIVERABLE_REPOSITORY_orderType: 'Type of assignment',
    DELIVERABLE_REPOSITORY_prupose: 'Purpose',
    DELIVERABLE_REPOSITORY_source: 'Source',
    deliverables: 'Deliverables',
    deliverablesConfiguration: 'Configuration of deliverables',
    deliveredByAppraiser: 'Delivered by appraiser',
    deliveredToClient: 'Complete',
    description: 'Description',
    Description: 'Description',
    detail: 'Details',
    Developable: 'For development',
    DgcCodVia: 'Street code',
    dni: 'NIF/NIE/Passport',
    document: 'Document',
    documents: 'Documents',
    download: 'Download',
    downloadBatchFile: 'Download batch file',
    downloadCsv: 'Download CSV',
    duplicate: 'Duplicate',
    duplicateBatch: 'Duplicate batch',
    ECONOMIC_EXPLOITATION_buildingOrRehabilitationYear: 'Year of construction or rehabilitation',
    ECONOMIC_EXPLOITATION_costMethod: 'Cost Method',
    edit_cost_method_percentages: 'Edit percentages',
    edit: 'Edit',
    elevator: 'Elevator',
    email: 'Email',
    Email: 'Email',
    EnergeticCertificateGrade: 'Energy efficiency certificate',
    english: 'English',
    ENTITIES_add_entity: 'Add entity',
    ENTITIES_auto_billing: 'Automatic Invoicing',
    ENTITIES_cif: 'Tax ID No.',
    ENTITIES_companyName: 'Name/Company name',
    ENTITIES_contactEmail: 'Email',
    ENTITIES_contactPhone: 'Telephone',
    ENTITIES_createEntity: 'Create account',
    ENTITIES_creationDateTime: 'Creation date',
    ENTITIES_email: 'Email',
    ENTITIES_entities: 'Entities',
    ENTITIES_entityType: 'Entity type',
    ENTITIES_sectorType: 'Sector',
    ENTITIES_hasCustomer: 'Are you a customer?',
    ENTITIES_hasPrescriptor: 'Are you a prescriber?',
    ENTITIES_hasUsers: 'Do you have users?',
    ENTITIES_its_a_company: "It's a company",
    ENTITIES_isAppraiserSociety: 'It is an approved appraisal company',
    ENTITIES_lastName: 'Surnames',
    ENTITIES_name: 'Name',
    ENTITIES_nationalIdentityDocument: 'ID Document',
    ENTITIES_nif: 'Tax ID No.',
    ENTITIES_observations: 'Remarks',
    ENTITIES_plus_add_new: '+ Add new',
    ENTITIES_profile: 'Profile',
    entitiesLabel: 'Accounts',
    entrance: 'Entrance',
    EnvironmentPersonalOpinion: 'Opinion',
    error: 'Error',
    errors: 'Errors',
    EX_each: 'Each',
    EX_economicDataInfoModalContent: '<ul> <li> <span> Description </span> - Description of Income / Expense. </li> <li> <span> Assigned Value </span> - Amount of Income / Expense ({{currency}}). </li> <li> <span> Is it periodic? </span> - Type of Income/Expenditure. It will be indicated if it is recurring (periodic) or punctual (not periodic). <ul class = "inside"> <li> The Value Assigned to Income/Expense is Periodic, it will be distributed automatically and equitably between the period that has been selected in the Start/End Date fields of Income/Expense. </li> <li> The Value Assigned to Income/Expense is not Periodic, the amount will be distributed manually in the cash flows of the method. </li> </ul> </li> <li> <span> Periodicity </span> - Periodicity of Income / Expense. It may be Annual or Monthly. </li> <li> <span> Start date </span> - Date from which the Income / Expense will occur. </li> <li> <span> End date </ span> - Date until which the Income / Expense will occur. </li> </ul> ',
    EX_economicDataInfoModalTitle: 'Information',
    EX_expensesObservations: 'Observations on expenses. In case of using average ratios, specify the sources',
    EX_incomesObservations: 'Observations on income. In case of using average ratios, specify the sources',
    EX_month_months: 'month / months',
    EX_repeatEach: 'Repeat every:',
    EX_year_years: 'year / years',
    existFormChanges: 'Changes have been made to the form',
    externalCode: 'External Code',
    ExternalRecordId: 'External reference number',
    externalTech: 'External appraiser',
    ExternalWoodWork: 'Outdoor woodwork',
    extract: 'Extract',
    Facilities: 'Facilities',
    FacilityObservations: 'Remarks',
    familiarRelationShip: 'Relationship',
    features: 'Features',
    Fee: 'Fees',
    fileInfoFileName: 'info_archivo_procesado.txt',
    fileName: 'File name',
    filesAverageTime: 'Average case times',
    filesGlobalAverageTime: 'Average overall case time',
    filesVolume: 'Case volume',
    filter: 'Filter',
    financial: 'Financial',
    finished: 'Complete',
    finishedAppraisals: 'Completed appraisals',
    FinishedAppraisals: 'Completed appraisals',
    finishedDate: 'Completion date',
    FirstName: 'Name',
    floor: 'Floor',
    Floor: 'Floor',
    FORM_VALIDATION_ALPHANUMERIC: 'Only alphanumeric characters are permitted',
    FORM_VALIDATION_BUDGET_NUMBER_MASK_ERROR: 'The format must be:  999999-999999',
    FORM_VALIDATION_CUSTOMER_ALREADY_EXISTS: 'This user already exists',
    FORM_VALIDATION_EMAIL_VALIDATION_ERROR_ALT: 'The email format is not valid',
    FORM_VALIDATION_EMAIL_VALIDATION_ERROR: 'The email format is not valid',
    FORM_VALIDATION_MAX_ADDITION: 'The total is more than:',
    FORM_VALIDATION_MAX_LENGTH: 'The maximum field length is:',
    FORM_VALIDATION_MAX: 'The maximum value is:',
    FORM_VALIDATION_MIN_LENGTH: 'The minimum field length is:',
    FORM_VALIDATION_MIN: 'The minimum value is:',
    FORM_VALIDATION_NOT_SAME: 'The fields do not match',
    FORM_VALIDATION_PASSWORD_DOES_NOT_MATCH: 'Your password must have at least 8 characters and contain a combination of lower case and upper case letters, numbers and special characters (for example, ! # $).',
    FORM_VALIDATION_PATTERN: 'The format is incorrect',
    FORM_VALIDATION_REQUIRED: 'This field is mandatory',
    FORM_VALIDATION_MIN_MAX_VALUE: 'This value must be greater than {{ min }} and less than or equal to {{ max }}',
    free: 'Free',
    from: 'From',
    FuelStation: 'Service station',
    Garage: 'Garage',
    gate: 'Door',
    Gate: 'Door',
    generalData: 'General data',
    generate_password: 'Create password',
    GestasaId: 'Gestasa No.',
    GlobalToOrder: 'Global',
    greenZones: 'Green zones',
    group_valuation_message_information: '* The year of construction will correspond to the weighted average of all the elements that make up the group.',
    had_recalculate_valoration: 'You have to recalculate to be able to add a valuation',
    HasAirConditioning: 'Air conditioning',
    HasArquitectonicProtectionRegime: 'Architectural protection scheme',
    HasaSportsZone: 'Sporting area',
    HasAssetSketch: 'Layout of the property',
    HasCadastralCertification: 'Land registry certificate',
    HasCadastralGoodsCertificate: 'Land registry assets certificate',
    HasCadastralProperty: 'Land registry property',
    HasCadastralRecord: 'Land registry file',
    HasConservationState: 'Conservation status',
    HasContract: 'Documentation demonstrating occupancy status',
    HasCorrespondanceWithRegisteredProperty: 'Correspondence with the property register',
    HasCurrentPlanAdequation: 'Compliance with current plan',
    HasDeck: 'Roof',
    HasElevator: 'Lift',
    HasGarden: 'Garden',
    HasParking: 'Parking',
    HasEnergeticCertificate: 'Energy efficiency certificate?',
    HasExpropiation: 'Expropriation',
    HasExternalEnclosure: 'Outdoor enclosure',
    HasGas: 'Gas',
    HasGreenArea: 'Green spaces',
    HasHeating: 'Heating',
    HasHotWater: 'Hot water',
    HasIBIBill: 'Property tax receipt',
    HasIntercom: 'Intercom',
    HasNonexistenceLeasesDocument: 'No lease document',
    HasOccupationStateUseAndExploitation: 'Occupancy, use and exploitation status',
    HasPaymentMethod: 'Payment method',
    HasPhone: 'Telephone',
    HasPhotos: 'Photographs',
    HasPhysicalAndRegisteredIdenficication: 'Physical and registry identification',
    HasPlumbingAndSanitaryEquipment: 'Plumbing and sanitary equipment',
    HasPool: 'Swimming pool',
    HasProperyRegisterBookCopy: 'Photocopy of the register of deeds',
    HasPublicProtectionRegime: 'Public protection scheme',
    HasRegisteredCertificationUpdated: 'Updated registry certificate',
    HasRegisteredPropertyDeed: 'Deeds of the registered property',
    HasSanitation: 'Sanitation',
    HasSecurity: 'Security',
    HasSimpleNoteOutdated: 'Registry extract out of date',
    HasSimpleNoteUpdated: 'Registry extract up to date',
    HasSituation: 'Situation',
    HasSwimmingPool: 'Swimming pool',
    HasTenisField: 'Tennis',
    HasTownHallCertificate: 'Town hall certificate',
    HasUnregisteredPropertyDeed: 'Deeds of the unregistered property',
    HasUrbanism: 'Town planning',
    HasUrbanisticIdentification: 'Town planning certificate',
    HasUrbanisticNormative: 'Town planning regulations',
    HasVisibleEasements: 'Visible easements',
    heating: 'Heating',
    hide_filters: 'Hide filters',
    HOMOG_COEF_MODAL_accessibility: 'Accessibility Coef.',
    HOMOG_COEF_MODAL_antiquity: 'Age Coef.',
    HOMOG_COEF_MODAL_calculateOmogCoef: 'Calculate homogenisation coefficient',
    HOMOG_COEF_MODAL_direction: 'Address',
    HOMOG_COEF_MODAL_height: 'Height Coef.',
    HOMOG_COEF_MODAL_infoTooltip: 'Remarks will be mandatory if a value is inserted in the "other" coefficient.',
    HOMOG_COEF_MODAL_marketPrice: 'Market value ({{ currency_per_surfaceUnit }})',
    HOMOG_COEF_MODAL_morphology: 'Morphology Coef.',
    HOMOG_COEF_MODAL_observationPLaceholder: 'Remarks',
    HOMOG_COEF_MODAL_orientation: 'Orientation Coef.',
    HOMOG_COEF_MODAL_others: 'Others Coef.',
    HOMOG_COEF_MODAL_polyvalence: 'Multipurpose Coef.',
    HOMOG_COEF_MODAL_slope: 'Slope Coef.',
    HOMOG_COEF_MODAL_requiredFields: 'All homogenisation coefficients must be filled in. If any concept does not apply, leave as 1.00',
    HOMOG_COEF_MODAL_situation: 'Situation Coef.',
    HOMOG_COEF_MODAL_state: 'Status Coef.',
    HOMOG_COEF_MODAL_surface: 'Surface Coef.',
    HOMOG_COEF_MODAL_typologieAndSubtypologies: 'Type and sub-type',
    HOMOG_COEF_MODAL_witnessSurface: 'Surface ({{ surfaceUnit }})',
    HOMOG_COEF_MODAL_elevators: 'Nº of elevators',
    HOMOG_COEF_MODAL_constructionRehabilitation: 'Construction / rehabilitation year',
    Hotel: 'Hotel',
    identification: 'Identification',
    Identification: 'Identification',
    Idufir: 'CRU (IDUFIR)',
    incidents: 'Incidents',
    incidentsResolutionAverageTime: 'Average incident resolution time',
    incomeOrExpensesInvoiced: 'Income/Expenses invoicing',
    incomeOrExpensesProvision: 'Provision Income/Expenses',
    incomesExpensesPeriodicityMessage: 'The assigned Value entered will be repeated according to the indicated periodicity (annual or monthly amount).',
    INCOMPATIBILITIES_userName: 'Username',
    incompatibilities: 'Incompatibilities',
    IndividualByAsset: 'Individual by properties',
    IneCodLocality: 'Town/city code',
    inProcess: 'In progress',
    inProgress: 'In progress',
    inputFileWebPath: 'Ruta del fichero',
    Inscription: 'Entry',
    InternalWoodWork: 'Indoor woodwork',
    invoice: 'Invoice',
    INVOICES_ALL_INVOICES: 'All invoices',
    INVOICES_askForConfirm: 'Request Confirmation',
    INVOICES_askForConfirmation: 'Request confirmation',
    INVOICES_askForConfirmationModal: 'You are about to request confirmation of the invoice. Are you sure?',
    INVOICES_billingFlow: 'Billing flow',
    INVOICES_cancelationReason: 'Reason for cancellation',
    INVOICES_cif_nif: 'Tax ID No.',
    INVOICES_code_management: 'Code management',
    INVOICES_confirmation_date: 'Confirmation date',
    INVOICES_CONFIRMATION_SENT: 'Confirmation sent',
    INVOICES_CONFIRMED: 'Confirmed',
    INVOICES_dayFlow: 'Day/Flow',
    INVOICES_DETAIL_resetInvoiceConfirmation: 'The current invoice is about to be reset. This may take a few seconds.',
    INVOICES_downloadBills: 'Download invoices',
    INVOICES_downloadDraft: 'Download draft',
    INVOICES_downloadInvoicesModal: 'You are about to download all invoices for the year and month chosen. This may take a few minutes.',
    INVOICES_downloadInvoicesSummaryMonth: 'Download monthly summary in Excel',
    INVOICES_downloadInvoicesSummaryYear: 'Download yearly summary in Excel',
    INVOICES_downloadInvoicesZip: 'Download invoices in zip format',
    INVOICES_file_number: 'Case number',
    INVOICES_fullInvoiceReset: 'Reset in full',
    INVOICES_incomeTax: 'Instalment of',
    INVOICES_internal_reference_number: 'Internal reference number',
    INVOICES_invoiceConfirmationDate: 'Invoice confirmation date',
    INVOICES_invoiceGenerationDate: 'Invoice generation date',
    INVOICES_massConfirmation: 'You are about to request confirmation of all invoices for the year and month chosen. This may take a few minutes.',
    INVOICES_month: 'Month',
    INVOICES_invoiceMonth: 'Invoice month',
    INVOICES_name: 'Name',
    INVOICES_payment_summary: 'Payment summary',
    INVOICES_PENDING_REQUEST_CONFIRMATION: 'Pending request for confirmation',
    INVOICES_REJECTED: 'Rejected',
    INVOICES_rejection_date: 'Rejection date',
    INVOICES_reseting: 'Resetting invoice',
    INVOICES_resetInvoice: 'Reset invoice',
    INVOICES_resetInvoiceKeepState: 'Reset maintaining status',
    INVOICES_resetInvoiceKeepStatus: 'Maintain status',
    INVOICES_resetInvoices: 'Reset invoices',
    INVOICES_resetInvoicesConfirmation: 'You are about to reset all invoices for the year and month chosen. This may take a few minutes.',
    INVOICES_sent_confirmation_date: 'Confirmation sent date',
    INVOICES_status: 'Status',
    INVOICES_subtotal: 'Subtotal',
    INVOICES_taxBase: 'Taxable base at',
    INVOICES_technical_mannager_users: 'Appraiser manager users',
    INVOICES_total_with_taxes_included: 'Amount (including taxes)',
    INVOICES_total: 'Total',
    INVOICES_year: 'Invoice year',
    INVOICES_youSure: 'Are you sure?',
    invoices: 'Invoices',
    InvoiceStatus_0: 'Pending request for confirmation',
    InvoiceStatus_1: 'Confirmation sent',
    InvoiceStatus_2: 'Confirmed',
    InvoiceStatus_3: 'Rejected',
    irpfPercentage: 'Income tax withholding percentage (%)',
    IrpfPercentage: 'Income tax withholding percentage (%)',
    IsActive: 'Active user',
    isActiveLicense: 'Active License',
    IsAdequatedToCurrentPlanning: 'Planning in force',
    IsOkStatusValues_false: 'KO',
    IsOkStatusValues_true: 'OK',
    isUserActive: 'Active user',
    isVPO: 'Is VPO?',
    language: 'Language',
    LastName: 'Surnames',
    LastRehabilitationYear: 'Year of refurbishment',
    lastUpdate: 'Last update',
    latitude: 'Latitude',
    Latitude: 'Longitude',
    LEGAL_MAX_value: 'Value',
    LegalSituation: 'Legal status',
    LICENSE_individual: 'Individual',
    LICENSE_teams: 'Teams',
    LicenseExpirationDatetime: 'End date',
    licenseExpired: 'Your licence has expired',
    LicensePlanChange: 'History',
    LicenseRegistryDatetime: 'Register date',
    list: 'List',
    locality: 'Town/City',
    Locality: 'Town/City',
    LocalityEnvironmentInformation: 'Location and Surrounding area',
    location: 'Location',
    Location: 'Location',
    LocationConfirmedType: 'Location type',
    LocationObservations: 'Remarks',
    log_in: 'Log in',
    log_out: 'Log out',
    logging_in: 'Loggin in...',
    LOGIN_forgotPassword: 'Did you forget your password?',
    LOGIN_password: 'Password*',
    LOGIN_resetYourPassword: 'Introduce your email address. You will be sent an email with instructions to restore your password',
    LOGIN_signin: 'Log in',
    LOGIN_userName: 'Username/Email*',
    logout: 'Log out',
    Longitude: 'Latitude',
    longitude: 'Longitude',
    mainAssetTipology: 'Tipology Calculated',
    mainAssetUse: 'Use Calculated',
    mainBatchAssetTipology: 'Tipology Origin',
    mainBatchAssetUse: 'Use Origin',
    mainConstructiveSubtypology: 'SubTypology',
    mainConstructiveTotalBuiltSurfaceWithCommonElements: 'Built Surface',
    mainConstructiveTypology: 'Typology',
    mainConstructiveUse: 'Use',
    MainElements_Deck: 'Roof',
    MainElements_Enclosure: 'Roof',
    MainElements_Structure: 'Structure',
    mainUse: 'Main use',
    manager: 'Manager',
    massive: 'Mass upload',
    MAXIMUM_LEGAL_mainAssetPercentage: 'Main unit percentage',
    MAXIMUM_LEGAL_totalValue: 'Total value',
    MAXIMUM_LEGAL_unitaryValue: 'Unitary value',
    MAXLEGAL_METHOD_costMethodRequired: 'Appraisal by cost method',
    MAXLEGAL_METHOD_groundLimitationValue: 'Ground value limit',
    MAXLEGAL_METHOD_groundMaximumLegal: 'Maximum legal ground value',
    MAXLEGAL_METHOD_groundTotalValue: 'Total ground value',
    MAXLEGAL_METHOD_isNotChildElement: 'This element has no main asset',
    MAXLEGAL_METHOD_maximumLegalValue: 'Maximum legal value',
    MAXLEGAL_METHOD_parentHasNotMaximumLegalValue: 'The parent element does not have a value in the maximum legal method',
    MAXLEGAL_METHOD_parentLegalMaxValue: 'The total maximum legal value of the parent is',
    MAXLEGAL_METHOD_qualifiedSurface: 'Qualified surface area',
    MAXLEGAL_METHOD_REAMaximumLegal: 'REA (maximum legal)',
    MAXLEGAL_METHOD_REAMethodCost: 'REA (cost method)',
    MAXLEGAL_METHOD_residualStaticAbr: 'Static residual method in the cost appraisal method',
    MAXLEGAL_METHOD_totalValue: 'Total maximum legal value',
    MAXLEGAL_METHOD_unitValue: 'Maximum legal unit value',
    MAXLEGAL_METHOD_updatedNetIncome: 'Updated income',
    MAXLEGAL_METHOD_updatedReversionValue: 'Reversion updated',
    MAXLEGAL_METHOD_updatedTotalValue: 'Total updated',
    MAXLEGAL_METHOD_updatedValues: 'Values updated',
    MAXLEGAL_METHOD_usefulCheckedSurface: 'Checked useful surface area',
    MAXLEGAL_METHOD_usefulQualifiedSurface: 'Useful surface area qual. certificate',
    MAXLEGAL_METHOD_usefulRegistralSurface: 'Registry useful surface area',
    MAXLEGAL_METHOD_valueTypes: 'Types of value',
    MENU_ASSESSMENTS_add: 'Add Appraisal',
    MENU_ASSESSMENTS_claims: 'Claims',
    MENU_ASSESSMENTS_incidents: 'Incidents',
    MENU_ASSESSMENTS_massive_load: 'Mass upload',
    MENU_ASSESSMENTS_orders_alive: 'Asset Appraisals',
    MENU_ASSESSMENTS_orders_all: 'All',
    MENU_ASSESSMENTS_orders_cancelled: 'Cancelled',
    MENU_ASSESSMENTS_orders_deliveredByAppraiser: 'Delivered by appraiser',
    MENU_ASSESSMENTS_orders_deliveredToClient: 'Delivered to Customer',
    MENU_ASSESSMENTS_orders_inProcess: 'Under way',
    MENU_ASSESSMENTS_orders_pendingToAssignManager: 'Awaiting assignment to process manager',
    MENU_ASSESSMENTS_orders_pendingToSchedule: 'Pending Scheduling',
    MENU_ASSESSMENTS_orders_scheduledByTec: 'Scheduled by Appraiser',
    MENU_ASSESSMENTS_orders_toAssign: 'Waiting for Assignment to Appraiser',
    MENU_ASSESSMENTS_orders_validated: 'Validated',
    MENU_ASSESSMENTS_orders_validationProcess: 'Validation in Progress',
    MENU_ASSESSMENTS_orders: 'Assignments',
    MENU_ASSESSMENTS_portfolios: 'Portfolios',
    MENU_ASSESSMENTS: 'Appraisals',
    MENU_ASSETS_add: 'Add',
    MENU_ASSETS_assets: 'Assets',
    MENU_ASSETS_list: 'List',
    MENU_CLAIMS: 'Claims',
    MENU_CLIENTS: 'Customers',
    MENU_dashboard: 'Control panel',
    MENU_ENTITIES_appraisal_companies: 'Appraisal firms',
    MENU_ENTITIES_customers: 'Customers',
    MENU_ENTITIES_prescribers: 'Prescribers',
    MENU_ENTITIES_users: 'Users',
    MENU_ENTITIES: 'Entities',
    MENU_FINANCIAL_paymentSummary_allInvoices: 'All invoices',
    MENU_FINANCIAL_paymentSummary_confirmedByInvoicer: 'Confirmed',
    MENU_FINANCIAL_paymentSummary_pendingToSendToInvoicer: 'Pending request for confirmation',
    MENU_FINANCIAL_paymentSummary_rejectedByInvoicer: 'Rejected',
    MENU_FINANCIAL_paymentSummary_sendToRevisionToInvoicer: 'Confirmation sent',
    MENU_FINANCIAL_paymentSummary: 'Payment Summary',
    MENU_FINANCIAL: 'Financial',
    MENU_ORDERS_list: 'List',
    MENU_ORDERS_requests: 'Requests',
    MENU_QUALITYCONTROL_createOrder: 'Create order',
    MENU_QUALITYCONTROL_orders_alive: 'Asset Appraisals',
    MENU_QUALITYCONTROL_orders_all: 'All',
    MENU_QUALITYCONTROL_orders_cancelled: 'Cancelled',
    MENU_QUALITYCONTROL_orders_deliveredToClient: 'Delivered to Customer',
    MENU_QUALITYCONTROL_orders_pending2AssignTechnical: 'Pending Assignment to Appraiser',
    MENU_QUALITYCONTROL_orders_pendingToAssignProcessManager: 'Pending Assignment to Process Manager',
    MENU_QUALITYCONTROL_orders_pendingToAssignTechnicalManager: 'Pending Assignment to Technical Manager',
    MENU_QUALITYCONTROL_orders_pendingToStartValidation: 'Waiting to start validation',
    MENU_QUALITYCONTROL_orders_validating: 'Validation in progress',
    MENU_QUALITYCONTROL_orders_validationFinished: 'Validation complete',
    MENU_QUALITYCONTROL_orders: 'Assignments',
    MENU_QUALITYCONTROL: 'Quality control',
    MENU_USERS_processManagers_list: 'List',
    MENU_USERS_processManagers_statistics: 'Statistics',
    MENU_USERS_processManagers: 'Process Manager Users',
    MENU_USERS_professionals: 'Appraisers',
    MENU_USERS_techManagers_list: 'List',
    MENU_USERS_techManagers: 'Appraiser Manager User',
    MENU_USERS: 'Users',
    MinimumChecks: 'Minimum checks',
    minlength: 'Minimum length{value}}',
    moreFilters: 'More filters',
    month: 'Month',
    MonthsEnum_1: 'January',
    MonthsEnum_10: 'October',
    MonthsEnum_11: 'November',
    MonthsEnum_12: 'December',
    MonthsEnum_2: 'February',
    MonthsEnum_3: 'March',
    MonthsEnum_4: 'April',
    MonthsEnum_5: 'May',
    MonthsEnum_6: 'June',
    MonthsEnum_7: 'July',
    MonthsEnum_8: 'August',
    MonthsEnum_9: 'September',
    municipality: 'Municipality',
    my_network: 'My network',
    MY_PROFILE_MODAL_cancel: 'Cancel',
    MY_PROFILE_MODAL_cancelling: 'Cancelling',
    MY_PROFILE_MODAL_confirm: 'Confirm',
    MY_PROFILE_MODAL_confirming: 'Confirming',
    MY_PROFILE_MODAL_my_profile: 'My profile',
    MY_PROFILE_MODAL_save: 'Save',
    MY_PROFILE_myProfile: 'My profile',
    my_profile: 'My profile',
    my_work_modal_body: 'By working as a <strong>professional</strong> on this license you will not be able to access it through the web version. If you want to see or work on your files you can do it through the application that you have available in stores (Google Play or App Store) or from the mobile version.',
    my_work_modal_btn: 'Go to mobile version',
    my_work_modal_title: 'NOTICE',
    MY_WORK_mywork: 'My Work',
    myComparables: 'Valum comparables',
    externalComparables: 'External comparables',
    studyMarket: 'Study of market',
    studyMarketList: 'Market Research List',
    mywork_finalized_expedients: 'Finalized files',
    mywork_job_summary: 'Job Summary',
    mywork_my_assignments: 'My assignments',
    mywork_my_pending_job: 'My pending job',
    mywork_pending_expedients: 'Pending files',
    mywork_total_expedients: 'Total records',
    mywork_totals: 'Totals',
    myData_info_displacement: 'In order to calculate our contribution to reducing the carbon footprint, we would need you to tell us how you travel for visits.',
    myData_label_displacement: 'Most common mode of movement',
    myData_preferences_typology: 'Typologies',
    MULTIPLE_RECALCULATION_title: 'Multiple recalculation of assets',
    MULTIPLE_RECALCULATION_subtitle: '*The recalculation of all report elements pending recalculation is carried out.',
    MULTIPLE_RECALCULATION_result: 'Recalculation result',
    name_or_business_name: 'Name or company name',
    name: 'Name',
    Name: 'Name',
    nationalBankSpainCutomerType: 'BDE client type',
    NationalIdentityDocument: 'ID No.',
    nationalIdentityDocument: 'National identity document',
    necessaryRecalculate: 'It must be recalculated before it can be edited.',
    necessaryRecalculateToNavigate: 'You need to recalculate before you can access.',
    NETWORK_acceptCancelation: 'Accept cancellation',
    NETWORK_acceptRequest: 'Accept request',
    NETWORK_acceptRequestQuestion: 'Accept request?',
    NETWORK_alreadyRelatedMessage: 'This person already belongs to your network of contacts. Check that the data you have entered is correct.',
    NETWORK_cancelConfirmation: 'Are you sure you want to cancel this connection?',
    NETWORK_cancelConnection: 'Cancel connection',
    NETWORK_code: 'Code',
    NETWORK_codeCopied: 'Code copied!',
    NETWORK_colaborateWithLicenses: 'Collaborate with other licenses',
    NETWORK_conectionDate: 'Connection date',
    NETWORK_confirmAndSend: 'Confirm and send',
    NETWORK_connectionCode: 'Connection code',
    NETWORK_connectionHistory: 'Connection history',
    NETWORK_connectionType: 'Connection type',
    NETWORK_copyCode: 'Copy code',
    NETWORK_insertPersonalDataToConnect: 'Enter the data of the person you want to connect with',
    NETWORK_invalidSharingIdMessage: 'The code you have entered does not match any Tasafy user, please check this code and make the request again',
    NETWORK_joinYourLicense: 'Join your license',
    NETWORK_licenseeCodeContent: 'This is the license code that other professionals should use to find you and connect with you. This way, they become part of your network and you can start assigning them all the work you need. ',
    NETWORK_licenseeCodeTitle: 'Let other professionals join your license!',
    NETWORK_licenseMessage: 'I want to collaborate with you to make appraisals in ...',
    NETWORK_message: 'Message',
    NETWORK_myIdentification: 'My ID',
    NETWORK_name: 'Name',
    NETWORK_newConnection: 'New connection',
    NETWORK_pendingConnectionRequestMessage: 'You have already tried to connect with this person but they have not yet accepted the invitation. You can resend the connection request again ',
    NETWORK_professionalCodeContent: 'This is the professional code with which other licenses can search for you and collaborate with you. This way you become part of their network and they can start assigning you work. ',
    NETWORK_professionalCodeTitle: '{{userName}}, do you want to collaborate with other licenses as a professional?',
    NETWORK_reassignAppraisals: 'Reassign all appraisals to:',
    NETWORK_received: 'Received',
    NETWORK_rejectRequest: 'Reject request',
    NETWORK_requestDate: 'Request date',
    NETWORK_requestMessageWorkFor: '{{name}} wants to connect with you!',
    NETWORK_requestMessageWorkWith: '{{name}} wants to work with you!',
    NETWORK_requests: 'Requests',
    NETWORK_resendConfirmation: 'Are you sure you want to resend the request?',
    NETWORK_resendRequest: 'Resend request',
    NETWORK_sended: 'Sent',
    NETWORK_state: 'Status',
    NETWORK_successfulCancelation: 'The connection cancellation with {{name}} was successful',
    NETWORK_userMessage: 'I want to work with you to increase my network of technicians in ...',
    NETWORK_wantWorkFor: 'Do you want to work for ...?',
    NETWORK_wantWorkWith: 'Do you want to work with ...?',
    NETWORK_workWithMe: 'Work with me!',
    NETWORK_workWithSomeone: 'Join someone!',
    newCustomer: 'New customer',
    newProfessional: 'New appraiser',
    nif: 'Tax ID No.',
    no_documents: 'No documents',
    not_valid_email: 'Email not valid',
    not: 'no',
    notAutomaticInvoicing: 'Invoicing not automatic',
    NotDevelopable: 'Not for development',
    notHavePrescriber: 'No prescriber assigned',
    notInvoiced: 'Invoice No.',
    number: 'Number',
    NumberOfBuildingAccesses: 'Number of accesses',
    NumberOfLicensees: 'No. active users',
    Observación: 'Comments',
    Observations: 'Remark',
    observations: 'Remarks',
    justificationPercentageNegotiation: 'Justification of the % of negotiation',
    residualObservations: 'Observations of construction assumptions and construction costs',
    OcupationState: 'Occupancy status',
    OcupationType: 'Occupancy type',
    of: 'of',
    Office: 'Office',
    ORDER_REQUEST_DETAIL_addNewAsset: '+ Add new asset',
    ORDER_REQUEST_DETAIL_available: 'Available',
    ORDER_REQUEST_DETAIL_cancel: '- Cancel',
    ORDER_REQUEST_DETAIL_euro: 'EUROS(without fees).',
    ORDER_REQUEST_DETAIL_hasAppraisals: 'In an appraisal',
    ORDER_REQUEST_DETAIL_idufir: 'CRU (IDUFIR)',
    ORDER_REQUEST_DETAIL_propertyRegister: 'Property register',
    ORDER_REQUEST_DETAIL_propertyRegisterNumber: 'Register number',
    ORDER_REQUEST_DETAIL_realEstate: 'Estate',
    ORDER_REQUEST_DETAIL_registerData: 'Registry data',
    ORDER_REQUEST_DETAIL_showDetail: 'See details',
    ORDER_REQUEST_requestSimpleNote: 'Request registry extract',
    ORDER_REQUESTS_customerWillReceiveAnEmailSoon: 'The customer will receive an email with the quote and request details shortly.',
    order: 'Order',
    orderDateTime: 'Order Date',
    orderDescription: 'Order description',
    orderId: 'ID del pedido',
    orderInputFileId: 'orderInputFileId',
    orderInputFileInputFileName: 'orderInputFileInputFileName',
    orders: 'Works',
    origin: 'Origin',
    otherExpenses: 'Other costs',
    otherExpensesRappel: 'Related costs',
    feesAndPublicDocuments: 'Fees, taxes and official documents',
    Others: 'Others.',
    outOfDate: 'Outside the deadline',
    Paper: 'Sheet',
    parametersOfValoration: 'Parameters of Valoration',
    partialInvoiced: 'Invoiced in part',
    PARTICIPANTS_hasUsers: 'Do you have users?',
    participants: 'Participants',
    partnerCheckWarningMessage: 'By ticking the collaboration option, the invoice generation for commissions in which you act as the prescriber will be automatically selected. To change this, access "Invoicing details".',
    password: 'Password',
    pattern: 'Incorrect format',
    PavementType: 'Paving type',
    pending: 'Pending',
    pendingToAssignManager: 'Awaiting assignment to manager',
    pendingToAssignProcessManager: 'Awaiting assignment to process manager',
    pendingToAssignTechnicalManager: 'Awaiting assignment to technical manager',
    QUALITY_processManagerUserTitle: 'Process manager',
    QUALITY_technicalManagerUserTitle: 'Appraiser manager',
    QUALITY_professionalUsers: 'Appraisers',
    pendingTOAssignTechnician: 'Waiting assignment to appraiser',
    pendingToStartValidation: 'Pending start of validation',
    PERIOD_percentage: 'Percentage',
    PERIOD_unitary: 'Unitary',
    PersonalOpinion: 'Remarks',
    Phone: 'Mobile',
    phone: 'Telephone',
    PHOTOS_mainImageWarning: 'The main photo will only be upon request and will appear on the front cover of the document. Are you sure you want to continue?',
    pool: 'Pool',
    population: 'population',
    Portal: 'Entrance',
    portuguese: 'Portuguese',
    position: 'Position',
    postalCode: 'Post Code',
    PostalCode: 'Post code',
    preferences: 'Professional preferences',
    prescribers: 'Prescribers',
    PrescriptorType: 'Prescriber type',
    PROCESS_MANAGER_USER: 'Process manager',
    PROCESS_MANAGER_USERS_add_process_manager_user: 'Add process manager',
    PROCESS_MANAGER_USERS_addNew: 'Add new',
    PROCESS_MANAGER_USERS_appraisals_in_process_count: 'Appraisals in progress',
    PROCESS_MANAGER_USERS_email: 'Email',
    PROCESS_MANAGER_USERS_first_name: 'Name',
    PROCESS_MANAGER_USERS_is_active: 'Active user',
    PROCESS_MANAGER_USERS_lastname: 'Surnames',
    PROCESS_MANAGER_USERS_national_identity_document: 'ID No.',
    PROCESS_MANAGER_USERS_plus_add_user: '+ Add user',
    PROCESS_MANAGER_USERS_position: 'Position',
    PROCESS_MANAGER_USERS: 'Process Manager Users',
    process_managers: 'Process managers',
    processDept: 'Process Department',
    ProcessManager: 'Process managers',
    ProcessManagerUser: 'Process manager',
    processState: 'Process State',
    processStates: 'Process States',
    PROFESSIONAL_LIST_isPartner: 'Is a collaborator',
    PROFESSIONAL_LIST_isAbsent: 'Absent',
    PROFESSIONAL_LIST_isVerifiedAppraiser: 'Validated professional',
    PROFESSIONAL_LIST_inProgressTooltip: 'For professionals, only files in status 3 to 6 are taken into account.',
    PROFESSIONALS_addNewDocument: 'Add new document',
    PROFESSIONALS_addSignature: 'add signature',
    PROFESSIONALS_appraisalsInProcessCount: 'In progress',
    PROFESSIONALS_appraisers: 'Appraisers',
    PROFESSIONALS_changeSignature: 'change signature',
    PROFESSIONALS_commercialPercentageFee: '% Fees charged',
    PROFESSIONALS_distance: 'Distance',
    PROFESSIONALS_executionPercentageFee: '% Completion',
    Professionals_internalObservations: 'Internal remarks',
    PROFESSIONALS_maxDistance: 'Maximum distance',
    PROFESSIONALS_month: 'Month',
    PROFESSIONALS_NEW_add_professional: 'Add appraiser',
    PROFESSIONALS_notFileSelected: 'No files selected',
    PROFESSIONALS_notifications: 'Notifications',
    PROFESSIONALS_paymentOption: 'Customer invoicing mode:',
    PROFESSIONALS_qualification: 'Qualification',
    PROFESSIONALS_recieveAppNotifications: 'Receive notifications in the app',
    PROFESSIONALS_recieveEmailNotifications: 'Receive notifications via email',
    PROFESSIONALS_selectFile: 'Select a file',
    PROFESSIONALS_signature: 'signature',
    PROFESSIONALS_status: 'Status.',
    PROFESSIONALS_totalTaxesIncluded: 'Amount (including taxes)',
    PROFESSIONALS_typologiesAppraisable: 'Typologies',
    PROFESSIONALS_year: 'Year',
    professionals: 'Appraisers',
    PROFFESIONALS_aditionalInfo: 'Additional information',
    PROFFESIONALS_aditionalInfoTooltip: 'The external code is used to conserve a unique numbering system. The remarks may be read by the appraiser on the app. While internal remarks can only be read from this administration panel, the appraiser cannot read them on the app.',
    PROFFESIONALS_observationTooltip: 'The remarks may be read by the appraiser on the app. While internal remarks can only be read from this administration panel, the appraiser cannot read them on the app.',
    profile: 'Profile',
    PROPERTY_DATA_TABLE_adress: 'Address',
    PROPERTY_DATA_TABLE_antiquity: 'Age',
    PROPERTY_DATA_TABLE_assetRregistryInf: 'Property registry information',
    PROPERTY_DATA_TABLE_CadastralReference: 'Land registry reference',
    PROPERTY_DATA_TABLE_estimatedValueBand: 'Estimated value bracket',
    PROPERTY_DATA_TABLE_isUsedInAppraisal: 'In an appraisal',
    PROPERTY_DATA_TABLE_pruposedProperty: 'Proposed fee',
    PROPERTY_DATA_TABLE_refCatastral: 'Land registry ref.',
    PROPERTY_DATA_TABLE_RegistryInfoBook: 'Book',
    PROPERTY_DATA_TABLE_RegistryInfoIdufir: 'CRU (IDUFIR)',
    PROPERTY_DATA_TABLE_RegistryInfoInscription: 'Entry',
    PROPERTY_DATA_TABLE_RegistryInfoRealEstateNumber: 'Estate',
    PROPERTY_DATA_TABLE_RegistryInfoRegister: 'Property register',
    PROPERTY_DATA_TABLE_RegistryInfoRegisterNumber: 'Property register number',
    PROPERTY_DATA_TABLE_RegistryInfoSection: 'Sheet',
    PROPERTY_DATA_TABLE_RegistryInfoVolume: 'Volume',
    PROPERTY_DATA_TABLE_surface: 'Surface',
    PROPERTY_DATA_TABLE_tipologyAndUse: 'Use and type',
    PROPERTY_DATA_TABLE_total: 'Total:',
    PropertyRegistrationCode: 'Property register number',
    PropertyRegistrationName: 'Property register',
    protected: 'Protected',
    providerAssetId: 'Provider Asset ID',
    providerAssetIds: 'Origin ID',
    province: 'Province',
    Province: 'Province',
    PROVISION_EXPENSES_alert: 'Warning!',
    PROVISION_EXPENSES_alertMessage: 'The details below correspond to the Provision of Income/Costs, the definitive Income/Costs must be confirmed.',
    QUALITY_add_massive_control_appraisal: 'Create a new Quality control bulk load',
    QUALITY_add_new_appraisal: 'Create assignment',
    QUALITY_add_new_control_appraisal: 'Create new validation control assignment',
    QUALITY_addedDate: 'Creation date',
    QUALITY_addKo: 'Add KO',
    QUALITY_addMessage: 'Add a message',
    QUALITY_appraiser: 'Appraiser',
    QUALITY_attachDocument: 'Attach',
    QUALITY_cancelAppraisal: 'Cancel assignment',
    QUALITY_cancelled: 'Cancelled',
    QUALITY_cancelledDate: 'Cancellation date',
    QUALITY_claimDate: 'Claim date',
    QUALITY_appraiserName: 'Appraiser name',
    QUALITY_select_prescriber: 'Selecione um prescritor',
    QUALITY_valorationMethodAdopted: 'Method adopted',
    QUALITY_CONTROL_detail: 'Details',
    QUALITY_CONTROL_documents: 'Documentation',
    QUALITY_CONTROL_finishValidationConfirm: 'You are going to finish the validation. Are you sure?',
    QUALITY_CONTROL_massiveControlAppraisal: 'New mass validation control upload',
    QUALITY_CONTROL_messageBoard: 'Message board',
    QUALITY_CONTROL_pressingCtrlKey: 'Select them one by one, pressing the control (Ctrl) key.',
    QUALITY_CONTROL_pressingShiftKey: 'Select ranges by holding down the shift (upper case) key',
    QUALITY_CONTROL_registralPlat: 'Registry estates',
    QUALITY_CONTROL_selectMultipleFilesOptions: "To select multiple files, it is possible:'",
    QUALITY_CONTROL_startValidationConfirmation: 'Are you sure you want to start validation?',
    QUALITY_CONTROL_status: 'Result of the validation',
    QUALITY_creationDate: 'Creation date',
    QUALITY_customer: 'Customer',
    QUALITY_customerName: 'Full name/Company name',
    QUALITY_customerReferenceId: 'Customer reference',
    QUALITY_documentType: 'Document type',
    QUALITY_download_template: 'Download farm loading template to validate',
    QUALITY_estimatedValue: 'Estimated value',
    QUALITY_estimatedValueWithSuffix: 'Estimated value ({{currency}})',
    QUALITY_externalTech: 'External appraiser',
    QUALITY_fileName: 'Document',
    QUALITY_fileNumber: 'Case number',
    QUALITY_finished: 'Complete',
    QUALITY_finishedDate: 'Completion date',
    QUALITY_finishValidation: 'Complete validation',
    QUALITY_from: 'From',
    QUALITY_incidenceCode: 'Incident code',
    QUALITY_incidentInStatus: 'Incident status',
    QUALITY_isCustomerDeadlineExpired: 'Delivered to customer after the deadline',
    QUALITY_isSolved: 'Resolved',
    QUALITY_noValidate: 'Do not validate',
    QUALITY_pdf: 'PDF',
    QUALITY_pendingValidation: 'Pending validation',
    QUALITY_portfolios: 'Portfolios',
    QUALITY_processManager: 'Process manager',
    QUALITY_processManagers: 'Process managers',
    QUALITY_purpose: 'Purpose',
    QUALITY_recordId: 'Case number',
    QUALITY_resultValidation: 'Result of the validation',
    QUALITY_select_client: 'Select a customer',
    QUALITY_solvedDate: 'Resolution date',
    QUALITY_startValidation: 'Start validation',
    QUALITY_status: 'Status.',
    QUALITY_techManager: 'Appraiser manager',
    QUALITY_toDate: 'To',
    QUALITY_validate: 'Validate',
    QUALITY_validationFinished: 'Validation complete',
    QUALITY_validationStarted: 'Validation started',
    QUALITY_validationState: 'Validation status',
    QUALITY_viewPDF: 'View PDF',
    QUALITY_withClaims: 'With claims',
    QUALITY_withClaimsNotResolved: 'With unresolved claims',
    QUALITY_xml: 'XML',
    QualityOfBuilding: "Constructions' quality",
    REA_costMethodSurface: 'Surface area of the cost method',
    REA_groundValueMaximumLegal: 'Ground value based on the maximum legal',
    REA_total: 'Total',
    REA_valueMaximumLegalDividedSurface: 'This value represents the maximum legal value divided by the surface area adopted in the cost method',
    recalculationNeeded: 'This needs to be recalculated',
    recalculation: 'Recalculation',
    recalculation_success: 'Successful recalculation',
    RecordId: 'Case number',
    ReformLastYear: 'Year of refurbishment',
    registered: 'Registry',
    RegisteredPropertyNumber: 'Property',
    registryName: 'Register',
    relatedAppraisals: 'Related appraisals',
    Religious: 'Religious',
    remainder: 'Rest',
    remove_cluster_asset_modal_1: 'Remove drive?',
    remove_cluster_asset_modal_2: 'Removing this drive will remove the linked drives. Remove?',
    RENTAL_METHOD_activeNetIncome: 'Active net income',
    RENTAL_METHOD_amountMonthlyExpenses: 'Value monthly costs ({{currency}})',
    RENTAL_METHOD_annualNetIncome: 'Annual net income',
    RENTAL_METHOD_annualNetIncomeSuffix: '{{currency}}/year',
    RENTAL_METHOD_capex: 'Annual Capex',
    RENTAL_METHOD_capexPercent: '% annual Capex',
    RENTAL_METHOD_capexValue: 'Value annual Capex',
    RENTAL_METHOD_capitalGain: 'Ground capital gains/losses',
    RENTAL_METHOD_chooseDate: 'Select date',
    RENTAL_METHOD_currentIncome: 'Current net income',
    RENTAL_METHOD_defaulting: 'Arrears',
    RENTAL_METHOD_defaultingPercent: '% arrears',
    RENTAL_METHOD_defaultingValue: 'Months of arrears',
    RENTAL_METHOD_economicMarketIncomeData: 'Financial market income details',
    RENTAL_METHOD_euroMonthSuffix: '({{currency}}/month)',
    RENTAL_METHOD_euroYearSuffix: '({{currency}}/year)',
    RENTAL_METHOD_extinctionContractDate: 'Contract end date',
    RENTAL_METHOD_finalValue: 'Final value ({{currency_per_surfaceUnit}}/month)',
    RENTAL_METHOD_generalEconomicData: 'General and financial contractual details',
    RENTAL_METHOD_groundRepercussion: 'Ground repercussion',
    RENTAL_METHOD_groundRepercussionError: 'Cost method',
    RENTAL_METHOD_homogeneizedValue: 'Homogenised value ({{currency_per_surfaceUnit}}/month)',
    RENTAL_METHOD_incomeUpdated: 'Updated income',
    RENTAL_METHOD_initialValue: 'Start value ({{currency_per_surfaceUnit}}/month)',
    RENTAL_METHOD_IPC: 'CPI (%)',
    RENTAL_METHOD_leisure: 'Vacant',
    RENTAL_METHOD_leisurePercent: '% vacant',
    RENTAL_METHOD_leisureValue: 'Months vacant',
    RENTAL_METHOD_marketNetIncome: 'Market net income',
    RENTAL_METHOD_MonthlyEquivalentUpdateRate: 'Monthly equivalent update rate',
    RENTAL_METHOD_monthlyExpenses: 'Monthly costs',
    RENTAL_METHOD_monthlyGrossIncome: 'Monthly gross income',
    RENTAL_METHOD_monthlyNetIncome: 'Monthly net income',
    RENTAL_METHOD_monthlyNetIncomeSuffix: '{{currency}}/month',
    RENTAL_METHOD_observationsOcupation: 'Remarks in relation to occupancy',
    RENTAL_METHOD_residualUsefulLife: 'Residual Useful Life',
    RENTAL_METHOD_rollbackUpdated: 'Reversion updated',
    RENTAL_METHOD_rollbackValue: 'Reversion value',
    RENTAL_METHOD_unitNetIncome: 'Net unit income',
    RENTAL_METHOD_unitNetIncomeSuffix: '{{currency_per_surfaceUnit}}/month',
    RENTAL_METHOD_unitCurrencySurfaceSuffix: '{{currency_per_surfaceUnit}}',
    RENTAL_METHOD_updateRatePercent: 'Update Rate',
    RENTAL_METHOD_updateValue: 'Update value',
    RENTAL_METHOD_valuesUpdated: 'Updated values',
    REPORT_actualValuation: 'Current Appraisal',
    REPORT_ASSET_hide_assets_to_group: 'Hide assets to group',
    REPORT_ASSET_only_finished: '* Unfinished elements cannot be grouped.',
    REPORT_ASSET_only_group_same_types: '* You will not be able to group mixed elements, soils can only be grouped with soils, finished elements, with finished elements and economic exploitations with economic exploitations.',
    REPORT_ASSET_report_structure_childrenHasErrors_error: 'It is necessary to review the errors belonging to the annexes',
    REPORT_ASSET_report_structure_hasValorations_error: 'You cannot group this asset as it has associated valuation methods.',
    REPORT_ASSET_report_structure_isClustered_error: 'You cannot group this asset because have another group.',
    REPORT_ASSET_report_structure_stateNotDefined_error: 'You have to select the Status of the asset in Typology to be able to group this asset.',
    REPORT_ASSET_show_assets_to_group: 'Show assets to group',
    REPORT_CUSTOMIZATION_footerLine1: 'Footer (line 1)',
    REPORT_CUSTOMIZATION_footerLine2: 'Footer (line 2)',
    REPORT_CUSTOMIZATION_footerLine3: 'Footer (line 3)',
    REPORT_CUSTOMIZATION_includeCustomImageHeader: 'Include custom image header',
    REPORT_CUSTOMIZATION_includeCustomLogo: 'Include the cover logo',
    REPORT_CUSTOMIZATION_includeSignature: "Include the representative's signature in all reports",
    REPORT_CUSTOMIZATION: 'Customise your appraisal report',
    REPORT_futureValuation: 'HET appraisal',
    REPORT_LIST_deliverablesAccess: 'Access to deliverables',
    REPORT_LIST_generateDraft: 'Generate draft',
    REPORT_reportVersionSelected: 'Report version selected',
    REPORT_valuation_summary_adoptedMethod: 'Adopted Method',
    REPORT_valuation_summary_adoptedSurface: 'Surface Area Adopted',
    REPORT_valuation_summary_cadastralReference: 'Land registry reference',
    REPORT_valuation_summary_element: 'Element',
    REPORT_valuation_summary_registeredPropertyNumber: 'Property',
    REPORT_valuation_summary_totalValue: 'Total Value ({{currency}})',
    REPORT_valuation_summary_unitaryValue: 'Unit Value',
    REPORT_valuationSummary: 'Appraisals summary',
    REPORT_validationAsset: 'Asset Validation',
    REPORT_validation: 'Report validation',
    REPORT_thereAreNoItems: 'No elements',
    REPORT_checkValidation: 'Check validations',
    REPORT_checkReportsValidation: 'Check Reports',
    REPORT_checkReportValidation: 'Check Report',
    REPORT_checkAssetValidation: 'Check actives',
    reportVersion: 'Version',
    REQUEST_createdFrom: 'Added datetime from',
    REQUEST_createdTo: 'Added datetime to',
    REQUEST_customerInvoiced: 'Appraisals that can be invoiced to customer',
    REQUEST_notCancelled: 'Not cancelled',
    requestCreateInvoiceModalMsg1: 'The provision of the amount included in the request is',
    requestCreateInvoiceModalMsg2: 'Indicate the amount to be invoiced',
    requestCreateInvoiceModalMsg3: "The invoice will be generated for the appraisals selected and the amount indicated.' This invoice cannot be amended. Are you sure you want to continue?",
    REQUESTER_COMPANIES_billing_information: 'Billing information',
    REQUESTER_COMPANIES_code: 'Code',
    REQUESTER_COMPANIES_DEFAILS_USERS_users: 'Users',
    REQUESTER_COMPANIES_name: 'Name',
    REQUESTER_COMPANIES_NEW_add_prescriber: 'Add prescriber',
    REQUESTER_COMPANIES_prescribers: 'Prescribers',
    REQUESTER_COMPANIES_prescriptorType: 'Prescriber type',
    REQUESTER_COMPANIES_profile: 'Profile',
    Requester: 'Prescriber',
    REQUESTS_acceptQuoteManually: 'Accept quote manually',
    REQUESTS_add_manually: 'Enter manually',
    REQUESTS_add_new_asset: 'Add new asset',
    REQUESTS_add_new_request: 'Create request',
    REQUESTS_addedUserFullName: 'Full Name',
    REQUESTS_address: 'Address',
    REQUESTS_addToInvoice: 'Add to invoice',
    REQUESTS_amounts: 'Amounts',
    REQUESTS_feesAndPublicDocuments: 'Fees, taxes and official documents',
    REQUESTS_apply: 'Apply',
    REQUESTS_appraisal_owner_data: 'Appraisal owner details',
    REQUESTS_appraisal_owner_name: "Owner's name",
    REQUESTS_appraisal_owner_lastName: "Owner's last name",
    REQUESTS_appraisal_customer_data: 'Customer data',
    REQUESTS_appraisal_retriever_email: 'Appraisal receiver email address',
    REQUESTS_appraisals: 'Appraisals',
    REQUESTS_askForConfirmation: 'Request confirmation',
    REQUESTS_at_any_moment_of_the_day: 'Any time of the day',
    REQUESTS_basicFilters: 'Basic filters',
    REQUESTS_state: 'Request status',
    REQUEST_RETURN: '< Go to request',
    REQUESTS_billing_data: 'Invoicing details',
    REQUESTS_billing: 'Invoicing',
    REQUESTS_financial_information: 'Financial information',
    REQUESTS_budget_number: 'Quote no.',
    REQUESTS_cadastralReference: 'Land registry reference',
    REQUESTS_cancel: 'Cancel',
    REQUESTS_cancelled: 'Cancelled',
    REQUESTS_communication: 'Communication',
    REQUESTS_message_code: 'Message code',
    REQUESTS_message_board: 'Message board',
    REQUESTS_state_communication: 'Communication status',
    REQUESTS_cancelRequest: 'Cancel request',
    REQUESTS_copyRequest: 'Copy request',
    REQUESTS_cancelRequestConfirmation: 'This request will be cancelled. Do you want to proceed?',
    REQUESTS_cif: 'Tax ID No.',
    REQUESTS_client_error: 'The customer does not have an email address, this field is mandatory for advance payment requests',
    REQUESTS_code: 'Code',
    REQUESTS_commercial_percentage_fee: '% Fees charged',
    REQUESTS_copyDataFromClient: 'Copy from customer data',
    REQUESTS_copyDataFromOwner: 'Copy owner details',
    REQUESTS_createAssessments: 'Create appraisal',
    REQUESTS_createOrder: 'Create assignment',
    REQUESTS_createWork: 'Create work',
    REQUESTS_createNewInvoice: 'Create new invoice',
    REQUESTS_createOk: 'The request has been created correctly.',
    REQUESTS_creationDate: 'Creation date',
    REQUESTS_customer: 'Customer',
    REQUESTS_date: 'Date',
    REQUESTS_DETAIL_createDate: 'Creation date',
    REQUESTS_DETAIL_paymentAmount: 'Amount',
    REQUESTS_DETAIL_paymentDate: 'Date and time',
    REQUESTS_DETAIL_paymentId: 'Payment ID',
    REQUESTS_DETAIL_description: 'Description',
    REQUESTS_DETAIL_paymentState: 'Status.',
    REQUESTS_DETAIL_redsysPaymentId: 'Redsys ID',
    REQUESTS_DETAIL_redsysResponseState: 'Redsys Status',
    REQUESTS_detail: 'Details',
    REQUESTS_direction: 'Address',
    REQUESTS_dni_nie_passport: 'NIF/NIE/Passport',
    REQUESTS_dni: 'NIF / CIF Client',
    REQUESTS_do_visit: "'Perform visit",
    REQUESTS_do_you_have_code: 'Do you have a code?',
    REQUESTS_document: 'Document',
    REQUESTS_documents: 'Documents',
    REQUESTS_eco_805_2003: 'Eco 805/2003',
    REQUESTS_editBillingData: 'Edit invoicing details',
    REQUESTS_email: 'Email',
    REQUESTS_end: 'Complete',
    REQUESTS_euros_without_taxes: 'Euros (without fees)',
    REQUESTS_execution_percentage_fee: '% Completion',
    REQUESTS_expensesProvision: 'Provision of Income/Expenses',
    REQUESTS_expensesRapel: 'Related costs',
    REQUESTS_externalReference: 'External reference number',
    REQUESTS_feeOptions: 'Fee mode',
    REQUESTS_finishDate: 'Completion date',
    REQUESTS_finished: 'Completed',
    REQUESTS_first_name_last_name_business_name: 'Full Name/Company Name',
    REQUESTS_first_name: 'Name',
    REQUESTS_from: 'From',
    REQUESTS_hasAppraisals: 'You have assignments',
    REQUESTS_id: 'Identifier',
    REQUESTS_internal_amounts: 'Internal amounts',
    REQUESTS_invoiceDateTime: 'Invoice date',
    REQUESTS_invoiceNumber: 'Invoice no.',
    REQUESTS_invoiceNumberId: 'Invoice number',
    REQUESTS_irpfTotal: 'Total income tax',
    REQUESTS_is_company: "It's a company",
    REQUESTS_ivaTotal: 'Total taxes',
    REQUESTS_last_name: 'Surnames',
    REQUESTS_locality: 'Town/City',
    REQUESTS_manualOrderAcceptConfirmation: 'The quote will be marked as accepted manually. Do you want to proceed?',
    REQUESTS_new_appraisal: 'New appraisal',
    REQUESTS_new_request: 'New request',
    REQUESTS_new_others: 'New order',
    REQUESTS_noPrescriber: 'No prescriber assigned',
    REQUESTS_observations: 'Remarks',
    REQUESTS_order_characteristics: 'Characteristics of the work',
    REQUESTS_order_type: 'Type of assignment / assessment',
    REQUESTS_job_type: 'Type of job',
    REQUESTS_otherExpenses: 'Other associated costs',
    REQUESTS_payment_history: 'Payment history',
    REQUESTS_communications: 'Communications',
    REQUESTS_history: 'History',
    REQUESTS_payment_mode: 'Invoicing mode',
    REQUESTS_payment_mode_info: '*Clicking on Automatic billing will generate an invoice for each file completed',
    REQUESTS_phone_number: 'Telephone',
    REQUESTS_postal_code: 'Post code',
    REQUESTS_prescriptorName: 'Prescriber',
    REQUESTS_property_data: 'Request details',
    REQUESTS_province: 'Province',
    REQUESTS_purpose: 'Purpose',
    REQUESTS_remove_code: 'Delete code',
    REQUESTS_requests: 'Requests',
    REQUESTS_resendMailPayment: 'Resend payment email',
    REQUESTS_select_a_client: 'Select a customer',
    REQUESTS_simpleNote: 'Registry extract',
    REQUESTS_status: 'Status.',
    REQUESTS_subtotal: 'Subtotal',
    REQUESTS_to: 'To',
    REQUESTS_total_fees: 'Total fees',
    REQUESTS_total_totalAmountWarning: 'Please introduce a valid fee',
    REQUESTS_totalWithoutTaxes: 'Total without taxes',
    REQUESTS_totalWithTaxes: 'Total with taxes',
    REQUESTS_visit_contact_user_data: 'Contact details for the visit',
    REQUESTS_visit_type: 'Type of visit',
    REQUESTS_with_report: 'Run the report',
    requests: 'Requests',
    REQUESTSS_at_any_moment_of_the_day: 'At any time of the day',
    REQUETS_can_be_launched_in_exchange: 'Include the request in the assignment exchange',
    REQUETS_generalConditions: 'General sale conditions',
    REQUETS_has_read_and_accepted: 'I have read and accepted the',
    REQUIRE_PASSWORD_CHANGE_inputEmail: 'Introduce your email address. You will be sent an email with instructions to restore your password',
    REQUIRE_PASSWORD_CHANGE_restorePassword: 'Recover password',
    REQUIRE_PASSWORD_CHANGE_sendEmailSuccessMsg: 'You have been sent an email with instructions on how to change your password',
    REQUIRE_PASSWORD_CHANGE_sendEmailSuccessTitle: 'Email address',
    required: 'This field is mandatory',
    ResidentialMultiFamily: 'Multi-family residential building',
    ResidentialSingleFamily: 'Single-family residential building',
    RESIDUAL_DYNAMIC_workPlanDatesWarning: 'The start and end date data of the promotion are editable in the Work Status tab',
    restart: 'Reset',
    RESTORE_PASSWORD_changePassword: 'Change password',
    RESTORE_PASSWORD_changePasswordLabel: 'Enter your new password to access your account',
    RESTORE_PASSWORD_newPassword: 'New password',
    revisionsResolutionAverageTime: 'Average review resolution time',
    roadType: 'Type of street',
    root: 'Root',
    Sanitary: 'Sanitary',
    save: 'Save',
    saveAndFinish: 'Save and complete',
    saveRequiredChangesToContinue: 'Tienes cambios requeridos pendientes de guardar para realizar Residual Dinámico. Cierra y revisa el formulario o guarda para continuar.',
    saving: 'Saving...',
    schedule: 'Schedule',
    scheduledByTec: 'Scheduled by appraiser',
    search: 'Search',
    Section: 'Section',
    select_batch_excel_file: 'Choose CSV file to load',
    select_currency_warning: 'Selected language only will change the label, will not change the value',
    select_currency: 'Select the currency you want to work with',
    select_language_title: 'Select language',
    select_language: 'Select language',
    selectAmountToIncludeInInvoice: 'Select the amount to be included in the invoice',
    selectCustomer: 'Select a customer',
    selectedAsset: 'Asset selected',
    selectedCluster: 'Cluster selected',
    selectInvoiceData: 'Select the invoicing details',
    selectPrescriber: 'Select a prescriber',
    selectRequest: 'Select a request',
    send: 'Send',
    sending: 'Sending',
    sendToCadastralApi: 'Send to cadastral API',
    sendToClient: 'Send to client',
    sendToValorate: 'Send to valuate',
    SeveralUses: 'Multiple uses',
    show_filters: 'Show filters',
    signature: 'Signature',
    SIMPLE_NOTE_addedDateTime: 'Creation date',
    SIMPLE_NOTE_attach: 'Attach',
    SIMPLE_NOTE_Block: 'Block',
    SIMPLE_NOTE_Book: 'Book',
    SIMPLE_NOTE_cadastralReference: 'Land registry reference',
    SIMPLE_NOTE_Country: 'Country',
    SIMPLE_NOTE_currentWorkflowStep: 'Current status',
    SIMPLE_NOTE_errorMessage: 'Leave a comment',
    SIMPLE_NOTE_errorType: 'Error type',
    SIMPLE_NOTE_FILTER_Book: 'Book',
    SIMPLE_NOTE_FILTER_CadastralReference: 'Land registry reference',
    SIMPLE_NOTE_FILTER_Idufir: 'IDUFIR',
    SIMPLE_NOTE_FILTER_OwnerFirstName: 'Customer name',
    SIMPLE_NOTE_FILTER_OwnerLastName: 'Customer surnames',
    SIMPLE_NOTE_FILTER_OwnerNationalIdentityDocument: 'Customer ID',
    SIMPLE_NOTE_FILTER_Page: 'Sheet',
    SIMPLE_NOTE_FILTER_RealEstateNumber: 'Estate',
    SIMPLE_NOTE_FILTER_RegisterName: 'Name of the property register',
    SIMPLE_NOTE_FILTER_RegisterNumber: 'Property register number',
    SIMPLE_NOTE_FILTER_Section: 'Section',
    SIMPLE_NOTE_FILTER_status: 'Status.',
    SIMPLE_NOTE_Floor: 'Floor',
    SIMPLE_NOTE_Gate: 'Door',
    SIMPLE_NOTE_Id: 'ID',
    SIMPLE_NOTE_Idufir: 'CRU (IDUFIR)',
    SIMPLE_NOTE_Inscription: 'Entry',
    SIMPLE_NOTE_Locality: 'Town/City',
    SIMPLE_NOTE_mustUploadAFile: 'A file has been attached',
    SIMPLE_NOTE_notAvailable: 'Registry extract not available',
    SIMPLE_NOTE_OwnerFirstName: 'Customer name',
    SIMPLE_NOTE_OwnerLastName: 'Customer surnames',
    SIMPLE_NOTE_OwnerNationalIdentityDocument: 'Customer ID',
    SIMPLE_NOTE_Page: 'Page',
    SIMPLE_NOTE_Portal: 'Entrance',
    SIMPLE_NOTE_PostalCode: 'Post code',
    SIMPLE_NOTE_PrescriptorRecordId: 'Prescriber identification',
    SIMPLE_NOTE_Province: 'Province',
    SIMPLE_NOTE_RealEstateNumber: 'Estate',
    SIMPLE_NOTE_RegisterName: 'Register name',
    SIMPLE_NOTE_RegisterNumber: 'Register number',
    SIMPLE_NOTE_Section: 'Section',
    SIMPLE_NOTE_sendedDateTime: 'Sent date',
    SIMPLE_NOTE_StreetName: 'Street name',
    SIMPLE_NOTE_StreetNumber: 'Street number',
    SIMPLE_NOTE_StreetType: 'Street type',
    SIMPLE_NOTES_add_new_simple_note: '+ Add registry extract',
    SIMPLE_NOTES_DETAIL_recivedFromRegister: 'Have you received the extract from the property register?',
    SIMPLE_NOTES_failedReceivedFromRegister: 'Document not available from property register',
    SIMPLE_NOTES_mustUploadAFile: 'The registry extract must be uploaded',
    SIMPLE_NOTES_recivedFromRegister: 'Received from the property register',
    SIMPLE_NOTES_sendToClient: 'Send to customer',
    SIMPLE_NOTES_sendToPropertyRegister: 'Send to the property register',
    SIMPLE_NOTES_simple_notes: 'Extracts',
    simpleNote: 'Registry extract',
    smartaValue: 'Smarta Value',
    someTranslatesShouldBeAdded: 'Some translates should be added',
    SortingDepot: 'Industrial premises',
    spanish: 'Spanish',
    Sports: 'Sports',
    sportsZone: 'Sports Zone',
    stairs: 'Staircase',
    Stairs: 'Staircase',
    StartedAppraisals: 'Appraisals started',
    State: 'Status.',
    Stays: 'Rooms',
    StayType: 'Type of room',
    STEPPER_mobileTooltip: 'This step is performed in the mobile App',
    StorageRoom: 'Storage room',
    StreetCategory: 'Street category',
    streetName: 'Street name',
    StreetName: 'Street name',
    streetNumber: 'Street number',
    StreetNumber: 'Street number',
    StreetType: 'Street type',
    streetType: 'Type of street',
    StudentResidence: 'Student halls of residence',
    successAction: 'The action has been performed correctly',
    successful: 'Successful',
    successfully_completed: 'Completed successfully',
    SUPPLIER_COMPANIES_add_appraisal_company_title: 'Create new appraisal firm',
    SUPPLIER_COMPANIES_add_appraisal_company: '+ Add appraisal firm',
    SUPPLIER_COMPANIES_appraisal_companies: 'Appraisal firms',
    SUPPLIER_COMPANIES_cif: 'Tax ID No.',
    SUPPLIER_COMPANIES_contactEmail: 'Email',
    SUPPLIER_COMPANIES_contactPhone: 'Telephone',
    SUPPLIER_COMPANIES_description: 'Description',
    SUPPLIER_COMPANIES_name: 'Name',
    SUPPLIER_COMPANIES_observations: 'Remarks',
    SUPPLIER_COMPANIES_profile: 'Profile',
    sureAssignProfessionalManual: 'Are you sure you want to assign a appraiser manually? This action will remove the assignment from the exchange.',
    sureWantToExit: 'Are you sure you want to leave? You have unsaved changes.',
    surface: 'Surface',
    SurfaceArea: 'Surface',
    surfaceCommons: 'Constructed surface area with common areas ({{surfaceUnit}})',
    surfaceFrom: 'Surface from',
    surfaceGroundPiece: 'Plot surface area (ha)',
    surfacePlotLand: 'Plot surface area',
    surfaces: 'Surface areas',
    Surfaces: 'Surface areas',
    property_features: 'Property features',
    surfacesAndTotalValue: 'Surface areas and value of the offer',
    SurfacesObservations: 'Remarks',
    surfaceTo: 'Surface to',
    surfaceUseful: 'Useful surface area ({{surfaceUnit}})',
    surfaceWithoutCommons: 'Constructed surface area without common areas ({{surfaceUnit}})',
    Surveillance: 'Surveillance',
    tab_information_updated_please_reload_tab: 'Tab information updated. Reload tab',
    tax_type: 'Tax type',
    tax: 'Tax',
    TECHNICAL_MANAGER_USER: 'Appraiser manager',
    TECHNICAL_MANAGER_USERS_add_technical_manager_user: 'Add appraiser manager',
    TECHNICAL_MANAGER_USERS_appraisals_in_process_count: 'Appraisals in progress',
    TECHNICAL_MANAGER_USERS_email: 'Email',
    TECHNICAL_MANAGER_USERS_first_name: 'Name',
    TECHNICAL_MANAGER_USERS_is_active: 'Active user',
    TECHNICAL_MANAGER_USERS_lastname: 'Surnames',
    TECHNICAL_MANAGER_USERS_national_identity_document: 'ID No.',
    TECHNICAL_MANAGER_USERS_plus_add_user: '+ Add user',
    TECHNICAL_MANAGER_USERS_position: 'Position',
    TECHNICAL_MANAGER_USERS: 'Appraiser Manager Users',
    technical_managers: 'Appraiser Managers',
    technicalDept: 'Technical Department',
    TechnicalManager: 'Appraiser managers',
    TechnicalManagerUser: 'Appraiser manager',
    technician: 'Technician',
    tendingToSchedule: 'Pending scheduling',
    TENURES_warningMessage: 'The sum of the percentages must be 100%',
    Terrace: 'Terrace',
    TERRAIN_CLUSTER_all: 'All',
    TERRAIN_CLUSTER_AppliedPremiumPercent: 'Premium applied (%)',
    TERRAIN_CLUSTER_applyToAll: 'Apply to all',
    TERRAIN_CLUSTER_deadlinesAndComercialParamethers: 'Economic parameters and terms',
    TERRAIN_CLUSTER_expenses: 'Costs',
    TERRAIN_CLUSTER_ExternalFinancingIncreaseJustification: 'Explain the increase in financing',
    TERRAIN_CLUSTER_ExternalFinancingIncreasePercent: 'Increase financing (%)',
    TERRAIN_CLUSTER_filterByIncome: 'Filter by income',
    TERRAIN_CLUSTER_filterByExpense: 'Filter by cost',
    TERRAIN_CLUSTER_FreeRateConstantPercent: 'Constant free rate (%)',
    TERRAIN_CLUSTER_FreeRateType: 'Constant (Actual) / Nominal (%)',
    TERRAIN_CLUSTER_income: 'Income',
    TERRAIN_CLUSTER_IncomeExpenseDiff: 'Current income - Current costs',
    TERRAIN_CLUSTER_ipc: 'CPI (%)',
    TERRAIN_CLUSTER_isActiveFromDateTime: 'Promotion start date',
    TERRAIN_CLUSTER_isActiveUntilDateTime: 'Promotion end date',
    TERRAIN_CLUSTER_isConstructionFromDateTime: 'Construction start date',
    TERRAIN_CLUSTER_isConstructionUntilDateTime: 'Construction end date',
    TERRAIN_CLUSTER_isSalesFromDateTime: 'Sales start date',
    TERRAIN_CLUSTER_isSalesUntilDateTime: 'Sales end date',
    TERRAIN_CLUSTER_Period: 'Period',
    TERRAIN_CLUSTER_PeriodTotalNetExpense: 'TOTAL costs ({{currency}})',
    TERRAIN_CLUSTER_PeriodTotalNetExpenseDiff: 'TOTAL income - costs ({{currency}})',
    TERRAIN_CLUSTER_PeriodTotalNetIncome: 'TOTAL income ({{currency}})',
    TERRAIN_CLUSTER_PeriodTotalUpdatedExpense: 'TOTAL current costs',
    TERRAIN_CLUSTER_PeriodTotalUpdatedIncome: 'TOTAL current income.',
    TERRAIN_CLUSTER_PeriodTotalUpdatedIncomeExpenseDiff: 'UPDATED TOTAL ({{currency}})',
    TERRAIN_CLUSTER_PeriodUpdateMonthlyRatePercent: 'Current monthly fee',
    TERRAIN_CLUSTER_RD_EX_Period: 'Period',
    TERRAIN_CLUSTER_RD_EX_PeriodTotalNetExpense: 'TOTAL Assigned expenses ({{currency}})',
    TERRAIN_CLUSTER_RD_EX_PeriodTotalNetExpenseDiff: 'TOTAL income - Assigned expenses ({{currency}})',
    TERRAIN_CLUSTER_RD_EX_PeriodTotalNetIncome: 'TOTAL Assigned income ({{currency}})',
    TERRAIN_CLUSTER_RD_EX_PeriodTotalUpdatedExpense: 'TOTAL Updated expenses',
    TERRAIN_CLUSTER_RD_EX_PeriodTotalUpdatedIncome: 'TOTAL Updated income',
    TERRAIN_CLUSTER_RD_EX_PeriodTotalUpdatedIncomeExpenseDiff: 'TOTAL UPDATED ({{currency}})',
    TERRAIN_CLUSTER_RD_EX_PeriodUpdateMonthlyRatePercent: 'Rate act. monthly',
    TERRAIN_CLUSTER_RiskFreePercent: 'Risk-free rate  (%)',
    TERRAIN_CLUSTER_RiskPremiumWeighted: 'Weighted risk premium',
    TERRAIN_CLUSTER_Totals: 'Total',
    TERRAIN_CLUSTER_totalToBeAsigned: 'Total to be assigned',
    TERRAIN_CLUSTER_UpdateExpense: 'Updated cost',
    TERRAIN_CLUSTER_UpdateIncome: 'Updated income',
    TERRAIN_CLUSTER_UpdateMonthlyRatePercent: 'Current eq. monthly fee',
    TERRAIN_CLUSTER_UpdateRatePercent: 'Update type',
    TerrainCalculationMethod_0: 'Static residual',
    TerrainCalculationMethod_1: 'Dynamic residual',
    terrainToAddListTitle: 'List of plots that can be added',
    testTranslation: 'Test to check merge process',
    the_action_was_successful: 'The action has been performed correctly',
    ThirdAgeResidence: 'Retirement home',
    toAssign: 'Waiting assignment to appraiser',
    toCreateManualCustomerMessage: 'To create a customer manually, the selected customer must be deselected by clicking the cross',
    toDate: 'To',
    TotalBuiltSurfaceWithCommonSurfaces: 'Constructed surface area with common areas ({{surfaceUnit}})',
    TotalBuiltSurfaceWithoutCommonSurfaces: 'Constructed surface area without common areas ({{surfaceUnit}})',
    TotalFloorsAboveGround: 'Floors above ground level',
    TotalFloorsBellowGround: 'Floors below ground level',
    TotalUsefulSurface: 'Useful surface area ({{surfaceUnit}})',
    usefulSurface: 'Useful surface',
    translationWillBeDeleted: 'the translation will be deleted. Are you sure?',
    TreeActionsEnum_0: 'Edit multiple',
    TreeActionsEnum_1: 'Appraise plots',
    TreeActionsEnum_2: 'Group appraisals',
    TreeActionsEnum_3: 'Amend structure',
    TreeActionsEnum_4: 'Import request assets',
    TreeActionsEnum_5: 'Recalculate methods',
    type: 'Type',
    typeOfAppraisal: 'Type of appraisal',
    Typologies: 'Types',
    typology: 'Type',
    uncompleteRequiredFields: 'Required fields missing',
    unexpectedError: 'Unexpected error',
    unitValue: 'Offer value ({{currency_per_surfaceUnit}}/month)',
    Unproductive: 'Non-productive',
    until: 'To',
    Urban: 'Urban',
    UrbanisticSituation: 'Town planning status',
    use: 'Use',
    USER_FORM_ConfirmPassword: 'Repeat the password',
    USER_FORM_CustomerId: 'Customer',
    USER_FORM_IsActive: 'Active user',
    USER_FORM_RequesterCompanyId: 'Prescribers',
    User: 'Users',
    userNotAssigned: 'User not assigned',
    userNotAvailable: 'User not available',
    USERS_add_new_client_or_prescriber_user: 'Add new customer user or prescriber',
    USERS_add_new_user: 'Add new user',
    USERS_add: 'Add user',
    USERS_addWarningModal: 'Bear in mind that your tariff depends on the number of associated users and appraisals performed. If you would like to add a new one, the additional amount will be charges as per the payment method selected in your profile.',
    USERS_commercial: 'Perform the salesperson manager role',
    USERS_createIncompatibility: 'Create incompatibility',
    USERS_detail: 'Details',
    USERS_edit_user: 'Edit user',
    USERS_list: 'List',
    USERS_process: 'Perform the process manager role',
    USERS_professional: 'Perform the professional role',
    USERS_receiveNotificationsByEmail: 'Receive notifications via email',
    USERS_roles: 'Roles',
    USERS_supplierCompany: 'Appraisal firm',
    USERS_technical: 'Perform the appraiser manager role',
    USERS_user: 'User',
    USERS_userclient: 'Client user',
    USERS_roles_incompatibilities: 'The roles you have selected are incompatible with each other.',
    users: 'Users',
    USERS: 'Users',
    useType: 'Type of use',
    VALIDATE_APPRAISAL_ASSETS_MODAL_appraisalData: 'Appraisal data',
    VALIDATE_APPRAISAL_ASSETS_MODAL_fieldName: 'Field name',
    VALIDATE_APPRAISAL_ASSETS_MODAL_originData: 'Origin details',
    VALIDATE_APPRAISAL_ASSETS_MODAL_saveChange: 'Save change',
    validated: 'Validated',
    validatedSing: 'Validated',
    validating: 'In process of validation',
    validation: 'Validation',
    ValidationAppraisalStepsWithNumber_1: '1 - Creation',
    ValidationAppraisalStepsWithNumber_2: '2 - Process Manager assigned',
    ValidationAppraisalStepsWithNumber_3: '3 - Technician Manager Assigned',
    ValidationAppraisalStepsWithNumber_4: '4 - Technician assigned',
    ValidationAppraisalStepsWithNumber_5: '5 - Validation started',
    ValidationAppraisalStepsWithNumber_6: '6 - Validation completed',
    ValidationAppraisalStepsWithNumber_7: '7 - Delivered to customer',
    ValidationFinishDatetime: 'Validation date',
    validationFinished: 'Validation complete',
    validationProcess: 'In process of validation',
    valoratedBatches: 'Valorated Batches',
    VALUATION_LIST_residualDynamicDeleteMessage: 'This method can only be removed by removing the corresponding common data dynamic residual method in the Work status tab',
    VALUATION_LIST_residualDynamicInfo: 'The dynamic residual method will be added from the State of the work chapter',
    VALUATIONS_currentValuations: 'Current appraisal',
    VALUATIONS_edit_reversion: 'Edit reversion value',
    VALUATIONS_futureValuations: 'HET appraisal',
    VALUATIONS_reversion_observations: 'Observations regarding the reversion value',
    VALUATIONS_edit: 'Are you sure you want to go out? There are fields to fill in that are required',
    valuations: 'Appraisals',
    visit: 'Visit',
    Volume: 'Volume',
    vpo: 'Social housing',
    WallType: 'Type of walls',
    Warehouse: 'Warehouse',
    warning: 'Warning',
    warningModalTitle: 'NOTICE!',
    warnings: 'Warnings',
    withholding: 'Income tax withholding %',
    year: 'Year',
    yes: 'yes',
    yesSaving: 'Yes, saving',
    yesWithoutSaving: 'Yes, without saving',
    your_business: 'Your business',
    yourLicenses: 'Your licenses',
    AUTHORITIES_canNotAccess: "You don't have permission to access",
    AUTHORITIES_permissions: 'Permissions',

    APPRAISALS_COMMUNICATIONS_BREADCRUMB_communications: 'Communications',
    APPRAISALS_COMMUNICATIONS_list_title: 'Communications',
    APPRAISALS_COMMUNICATIONS_list_tab_communications: 'Communications',
    APPRAISALS_COMMUNICATIONS_list_tab_records: 'Records',
    APPRAISALS_COMMUNICATIONS_list_id: 'ID',
    APPRAISALS_COMMUNICATIONS_list_request_id: 'Request ID',
    APPRAISALS_COMMUNICATIONS_list_prescriptorName: 'Requester Name',
    APPRAISALS_COMMUNICATIONS_list_status: 'Status',
    APPRAISALS_COMMUNICATIONS_list_lastEventDate: 'Last Event Date',
    APPRAISALS_COMMUNICATIONS_list_emitter: 'Emitter',

    REQUESTS_COMMUNICATIONS_BREADCRUMB_communications: 'Communications',
    REQUESTS_COMMUNICATIONS_list_title: 'Communications',
    REQUESTS_COMMUNICATIONS_list_tab_records: 'Records',
    REQUESTS_COMMUNICATIONS_list_tab_communications: 'Communications',
    REQUESTS_COMMUNICATIONS_list_id: 'ID',
    REQUESTS_COMMUNICATIONS_list_request_id: 'Request ID',
    REQUESTS_COMMUNICATIONS_list_prescriptorName: 'Requester Name',
    REQUESTS_COMMUNICATIONS_list_status: 'Status',
    REQUESTS_COMMUNICATIONS_list_lastEventDate: 'Last Event Date',
    REQUESTS_COMMUNICATIONS_list_emitter: 'Emitter',
    VALORACIONES_COMUNICACIONES_list_action: 'Action',
    APPRAISALS_COMMUNICATIONS_info: 'There is no information',

    zoom: 'zoom',
    rotate: 'rotate',
    reflect: 'reflect',
    straightened: 'straighten',
    resetImageCropperChanges: 'Clear changes',

    communications: 'Communications',
    REQUESTS_unknown_user: 'Unknown user',

    APPRAISALS_COMMUNICATIONS_internal_id: 'Internal ID',
    REQUESTS_COMMUNICATIONS_internal_id: 'Internal ID',

    APPRAISALS_COMMUNICATIONS_list_workflowItemId: 'Process ID',
    APPRAISALS_COMMUNICATIONS_list_requesterCompany: 'Requester',
    APPRAISALS_COMMUNICATIONS_list_state: 'State',
    APPRAISALS_COMMUNICATIONS_list_lastUpdateDateTime: 'Last Update',
    APPRAISALS_COMMUNICATIONS_list_lastUpdatedUser: 'User',

    APPRAISALS_COMMUNICATIONS_LIST_FILTER_id: 'ID',
    APPRAISALS_COMMUNICATIONS_LIST_FILTER_workflowItemId: 'Process ID',
    APPRAISALS_COMMUNICATIONS_LIST_FILTER_state: 'State',
    APPRAISALS_COMMUNICATIONS_LIST_FILTER_lastUpdateMinDatetime: 'Last Update',
    APPRAISALS_COMMUNICATIONS_LIST_FILTER_lastUpdateMaxDatetime: 'Last Update',
    APPRAISAL_HISTORY_COMMUNICATIONS_there_is_no_communications: 'There is no communication events',

    REQUESTS_COMMUNICATIONS_list_workflowItemId: 'Process ID',
    REQUESTS_COMMUNICATIONS_list_requesterCompany: 'Requester',
    REQUESTS_COMMUNICATIONS_list_state: 'State',
    REQUESTS_COMMUNICATIONS_list_lastUpdateDateTime: 'Last Update',
    REQUESTS_COMMUNICATIONS_list_lastUpdatedUser: 'User',

    REQUESTS_COMMUNICATIONS_LIST_FILTER_id: 'ID',
    REQUESTS_COMMUNICATIONS_LIST_FILTER_workflowItemId: 'Process ID',
    REQUESTS_COMMUNICATIONS_LIST_FILTER_state: 'State',
    REQUESTS_COMMUNICATIONS_LIST_FILTER_lastUpdateMinDatetime: 'Last Update',
    REQUESTS_COMMUNICATIONS_LIST_FILTER_lastUpdateMaxDatetime: 'Last Update',

    ASSETS_category: 'Category',
    ASSETS_targetType: 'Target Type',
    ASSETS_restaurant: 'Restaurant',
    ASSETS_meetingRoom: 'Meeting/conference room',
    ASSETS_sportsFacilities: 'Sports Facilities',
    ASSETS_hotelBuildingCharacteristics: 'Hotel Characteristics',

    APPRAISALS_records_history: 'History',
    APPRAISALS_records_history_communications: 'Communications',
    APPRAISALS_records_history_records: 'Records',

    REQUESTS_records_history_communications: 'Communications',

    TOUR_button: 'Need help?',
    TOUR_next: 'Next',
    TOUR_prev: 'Back',
    TOUR_done: 'Okay!',
    TOUR_processManager: 'Please select a <b>Process Manager</b> to continue!',
    TOUR_appraiser: 'Please select a <b>Technician</b> to continue! Remember that the following steps are performed from the <b>mobile app</b>.',
    TOUR_technicalManager: 'Select a <b>Technical Manager</b> and start the report validation!',
    TOUR_stepMobile: '…if not, you can make the report from the <b> Tasafy mobile app </b>!',
    TOUR_advanceSteps: 'Would you prefer to <b>advance the tour</b> without going through the mobile app? Fill in the dates and you can advance to step 7...',
    TOUR_startValdiation: 'You can start the report validation from here',
    TOUR_finishOkValidation: 'If the validation is correct and you want to finish it, you can do it from here...',
    TOUR_finishKoValidation: '...on the contrary, if you think there is an error, click here to have the technician review it.',
    TOUR_documents: 'Go to the <b>Documents</b> section, there you will find your report in <b>PDF</b> format!',
    TOUR_appraiserSignature: 'Look for the <b>professional</b> who has made the file and add the <b>signature</b> to the <b>documents</b> part so that it is included in the report! ',
    TOUR_reclamationNotLastStep: 'Do you need to make any <b>modifications</b> to the file? Pass the file to 11 and open a <b>claim</b>. Marking it as <b>proceeds</b> will return the file to step 8 so you can start the validation again and change what you need.',
    TOUR_reclamationLastStep: 'Do you need to make any <b>modifications</b> to the file? Open a <b>complaint</b>. Marking it as <b>proceeds</b> will return the file to step 8 so you can start the validation again and change what you need.',
    TOUR_firstEntityPost: 'Do you want to make your first file? Make sure you have previously created a client',
    TOUR_firstAppraisalPost: 'After creating the client you can start making your first report. Do not forget to mark the execution check by Tasafy to complete the fields of the report.',
    TOUR_firstVisitToAppraisal: 'Is this your <b>first file</b>? You can click on Tafy to help you in the key points so that it tells you how to continue.',
    ASSETS_otherFeaturesRusticEstate: 'Other features of the estate',
    ASSETS_electricalEnergy: 'Electric Energy',
    ASSETS_accessToRoadInfrastructure: 'Access to road infrastructure',
    ASSETS_legalizedIrrigationWater: 'Legalized irrigation water',
    ASSETS_expectedLeverage: 'Expected use (Rural property with urban expectations)',

    ASSETS_builtSurfaceOfHouseholds: 'Built Surface Of Houses',
    ASSETS_builtSurfaceForAgriculturalUses: 'Built surface for agricultural uses',
    ASSETS_coveredTerraceArea: 'Covered terrace area',
    ASSETS_openTerraceArea: 'Open terrace area',

    WELCOME_CONNECTIONS_step1_title: 'Welcome to the world of connections on Tasafy!',
    WELCOME_CONNECTIONS_step1_paragraph1: 'Immerse yourself in the <b>Tasafy</b> universe and connect with the different <i>players</i> to be able to carry out appraisals.',
    WELCOME_CONNECTIONS_step1_paragraph2: 'What does this mean?',

    WELCOME_CONNECTIONS_step2_paragraph1: 'In this section you will find two different codes that you can share to connect with other licenses.',
    WELCOME_CONNECTIONS_step2_section1_title: 'LICENSE CODE',
    WELCOME_CONNECTIONS_step2_section1_paragraph1: 'It is the code that starts with <b>L-</b>. Provide this code to other users so they can connect with you and start working for you as professionals',
    WELCOME_CONNECTIONS_step2_section2_title: 'PROFESSIONAL CODE',
    WELCOME_CONNECTIONS_step2_section2_paragraph1: 'It is the code that starts with <b>U-</b>. Give this code to other licenses so they can connect with you and you become part of their network so they can commission work from you.',

    WELCOME_CONNECTIONS_step3_paragraph1: 'If you have any code you want to connect with, you can add a connection from the <b>New Connection</b> button. ',
    WELCOME_CONNECTIONS_step3_paragraph2: 'Just like that, you can order work from all the professionals that are part of your network and vice versa, if you are the one who connects to other licenses they can order work from you.',

    WELCOME_CONNECTIONS_step4_paragraph1: 'In the history you can see all the connections you have and at any time you can cancel that connection if you no longer want a user to be part of your network or you to be part of theirs.',
    WELCOME_CONNECTIONS_step4_paragraph2: 'In the event that this user had active files, you can reassign them to any user belonging to your license.',

    WELCOME_CONNECTIONS_step5_paragraph1: 'From your profile you will be able to see the different accounts to which you are connected, you will even be able to see from My work the pending files that you have assigned in each of them.',
    WELCOME_CONNECTIONS_step5_button: 'Lets connect!',
    APPRAISAL_REQUEST_DATA_customer_modal_tooltip_message: 'You can create a request from the request panel',
    CUSTOMER_PRESCRIBER_customer_modal_tooltip_message: "You can create a customer from the top menu 'Entities'",
    CUSTOMER_PRESCRIBER_prescriber_modal_tooltip_message: "You can create a prescriber from the top menu 'Entities'",
    QUALITY_CONTROL_customer_modal_tooltip_message: "You can create a customer from the top menu 'Entities'",
    APPRAISAL_OWNER_DATA_customer_tooltip_message: "You can create a customer from the top menu 'Entities'",
    REQUESTER_COMPANY_prescriber_tooltip_message: "You can create a prescriber from the top menu 'Entities'",
    SELECT_CUSTOMER_MODAL_table_no_content_label: "You don't have any customer created yet",

    COMMUNICATIONS_added: 'Added',
    COMMUNICATIONS_information: 'Information',
    COMMUNICATIONS_body: 'Body',
    COMMUNICATIONS_updated: 'Updated',
    COMMUNICATIONS_lastUpdated: 'Last Updated',
    COMMUNICATIONS_events: 'Events',
    COMMUNICATIONS_of: 'of',
    COMMUNICATIONS_seeAll: '< See all communications',
    APPRAISAL_CLAIMS_proceed: 'Proceed',
    APPRAISAL_CLAIMS_not_proceed: 'Not Proceed',
    APPRAISAL_CLAIMS_solve_claim: 'Resolve claim',

    BOTTOM_BAR_error_dialog_enabled: 'Error dialog enabled',

    APPRAISALS_errorInForm: 'Error in some form field',

    APPRAISALS_information: 'Application information',
    REQUESTS_information: 'Application information',
    EXTRA_INFORMATION_information: 'Application information',
    EXTRA_INFORMATION_informationProvidedByEntity: 'Data provided by the entity',
    EXTRA_INFORMATION_noExtraInformation: 'No reference data',
    EXTRA_INFORMATION_title: 'Title',
    EXTRA_INFORMATION_description: 'Description',
    EXTRA_INFORMATION_add: 'Added By',
    EXTRA_INFORMATION_date: 'Dated Added',

    REQUESTS_reportTypes: 'Report types',
    APPRAISALS_reportTypes: 'Report types',

    ASSETS_publicTransportation: 'Public Transportation',
    ASSETS_equipmentEndowments: 'Equipment Endowments',
    ASSETS_businessEnvironment: 'Business Environment',
    ASSETS_tertiaryEnvironment: 'Tertiary environment',
    ASSETS_industrialEnvironment: 'Industrial environment',
    ASSETS_marketingPropertyDifficulties: 'Difficult to market the property?',
    ASSETS_numberOfElevators: 'Number of elevators',
    ASSETS_witnessInternalState: 'Witness internal state',
    ASSETS_buildingNumberOfFloors: 'Number of floors of the building',
    ASSETS_buildingNumberOfElevators: 'Number of elevators in the building',

    ASSETS_publicParkings: 'Public parkings',
    ASSETS_assetInternalState: 'Internal state of the property',
    ASSETS_assetInternalOrExternal: 'External or internal property',
    APPRAISALS_selectDocuments: 'Select Appraisal Documents',
    APPRAISALS_REPORT_selectDocuments: 'Select documents',
    APPRAISALS_downloadDocumentsSuccess: 'Documents have been downloaded',
    APPRAISALS_downloadDocumentsError: 'Error downloading documents',

    APPRAISALS_REPORT_selectAll: 'Select All',
    ASSETS_notStartedStoppedUrbanization: 'Urbanization not started or stopped',
    ASSETS_orientation: 'Orientation',

    CLUSTER_ITEMS_LIST_can_not_be_removed_at_least_two_assets: 'Impossible to delete. There must be at least two assets per pool.',
    REQUESTS_invoicesInformation: 'Invoice information',
    REQUESTS_selectResolver: 'Select Appraisal Society',
    REQUESTS_resolver: 'Appraisal Society',
    REQUESTS_DASHBOARD_recordState: 'Current status of requests',
    REQUESTS_DASHBOARD_totalOrders: 'Total Requests: ',
    records: 'records',

    NETWORK_basicClientCardTitle: 'Allow companies, offices, etc… to work with you!',
    NETWORK_basicClientCardContent: 'This license code allows other people to find you and connect with you so you can commission work from them.',
    NETWORK_licenseeToBasicClientMessage: 'I want to join your license so you can order requests from me...',
    NETWORK_basicClientToLicenseeMessage: 'I want you to join my license so I can handle applications...',
    NETWORK_newLicenseeUserConnection: 'New license/professional connection',
    NETWORK_newLicenseeRelationshipLicenseeConnection: 'New connection with client',
    NETWORK_newLicenseeRelationshipBasicClientConnection: 'New Licensed Connection',
    APPRAISALS_REPORT_expenseType: 'Type of expense',

    ASSET_numberOfCopiesModalTitle: 'how many times do you want to copy it?',
    ASSET_numberOfCopies: 'Number of copies',
    APPRAISALS_REPORT_copy: 'Copy',
    APPRAISALS_LIST_professional: 'Professional',
    NETWORK_myNetwork: 'My network',
    NETWORK_customerConnections: 'Customer Connections',

    EXTRA_INFO_addNewData: '+ Add new data',
    EXTRA_INFO_addNewDataTitle: 'Add new data',
    EXTRA_INFO_generalDataTitle: 'General Data',
    EXTRA_INFO_noDataToAdd: 'There is no data to add',
    EXTRA_INFO_succesRemovingData: 'Data removed successfully',
    EXTRA_INFO_errorRemovingData: 'Error removing data',
    EXTRA_INFO_errorCreatingData: 'Error creating data',
    EXTRA_INFO_succesCreatingData: 'Data successfully created',
    ASSET_numberOfCopiesError: 'Number of copies between 1 and 20',
    BADGE_visitScheduleDeadlineExpiredLabel: 'Out of time to schedule',
    BADGE_appraiserAllDataSentDeadlineExpiredLbel: 'Deadline report delivery',
    BADGE_customerDeadlineExpiredLabel: 'Delivery to customer after deadline',
    BADGE_withIncidentsLabel: 'with incidents',
    BADGE_withClaimsLabel: 'with claims',
    BADGE_cancelledLabel: 'Cancelled',

    APPRAISALS_advance_visit: 'Advance visit',
    APPRAISALS_cantAdvanceVisitMessage: 'You do not have permission to perform this action. You have to have the professional role marked.',
    APPRAISALS_visitScheduleDate: 'Scheduled visit date',
    APPRAISALS_startedVisitDate: 'Date visit started',
    APPRAISAL_finishVisitDate: 'Date visit finished',
    APPRAISALS_copyFromVisitScheduleDate: 'Copy date of scheduled visit',
    APPRAISALS_confirmImportReport: 'You are going to import the data from the selected report to the current one, are you sure?',
    APPRAISALS_ImportedConfirmed: 'The data has been imported successfully',
    REQUEST_errorSelectReportTypeFirst: 'Report Type must first be selected',
    REPORT_CUSTOMIZATION_includeTextInCertificate: 'Include certificate text',
    REPORT_CUSTOMIZATION_certificateTextLabel: 'Certificate Text (Top)',
    REPORT_CUSTOMIZATION_certificateTextIsAppraisalCompany: 'Appraisal Company?',
    REPORT_CUSTOMIZATION_certificateTextIsAppraisalCompanyMessage: 'NAME, legal representative of COMPANY',
    WITNESS_copy: 'Copy',
    WITNESS_confirmDeleteMessage: 'Are you sure you want to delete this witness from your database?',
    WITNESS_OwnComparables: 'Own comparables',
    WITNESS_IdealistaComparables: 'Idealista comparables',
    REQUESTS_payments: 'Payments',
    REQUESTS_paymentId: 'Payment ID',
    REQUESTS_paymentState: 'State',
    REQUESTS_paymentRedsysPaymentIdContains: 'Redsys Id',

    APPRAISALS_advanceTo: 'Advance To',
    APPRAISALS_chooseStepToAdvance: 'Choose the step you want to advance to.',
    APPRAISALS_chooseSuggestedDate: 'Do you want to choose any of the proposed dates to schedule the visit?',
    APPRAISALS_chooseProposedDate: 'Proposed Date',
    APPRAISALS_completeDates: 'Complete Dates',

    IMPORT_ASSETS_REQUEST_message: 'You are going to import the request assets',
    ASSETS_ceeEmissions: 'Emissions (kgCO₂/m² año)',
    ASSETS_ceeEmissionsLetter: 'Emissions letter',
    ASSETS_ceeDemand: 'Demand (kWH/m² año)',
    ASSETS_ceeDemandLetter: 'Demand letter',
    ASSETS_ceeHeating: 'Heating',
    ASSETS_ceeRefrigeration: 'Refrigeration',
    ASSETS_ceeLighting: 'Lighting',
    ASSETS_CEEConsuEner: 'Total energy consumption (Kwh/m² year)',
    ASSETS_CEENormatiAnroConst: 'Regulation that applies to the year of construction',
    ASSETS_CEECatAnter: 'Previous energy rating',
    ASSETS_CEEFchRecCertif: 'Date of visit collected in the CEE',
    ASSETS_CEEFchLimValid: 'CEE Validity Limit Date',
    ASSETS_CEEFchEmisionCertif: 'CEE issue date',
    ASSETS_CEEMejoraCalAntCEE: 'Does the qualification improve from the previous CEE?',
    ASSETS_CEEPorceImprovement: 'Improvement percentage',
    ASSETS_CEECo2Emisions: 'Carbon dioxide emissions (KgCO2/M2)',
    APPRAISALS_bindingInternalExternal: 'Internal/External',
    APPRAISALS_vinculationFile: 'File in:',
    APPRAISALS_notifyToEntity: 'Notify Entity',
    APPRAISALS_entityObservations: 'Entity Observations',

    COMMUNICATIONS_FILTER_BAR_needToDoSomething: 'Need an action',
    COMMUNICATIONS_FILTER_BAR_inProcess: 'In Process',
    COMMUNICATIONS_FILTER_BAR_finishedAndReviewed: 'Finished or Reviewed',

    COMMUNICATIONS_markAsReviewed: 'Mark as reviewed',
    COMMUNICATIONS_reviewed: 'Reviewed: ',

    statusFilters: 'Order status',
    REPORT_validation_calculate: 'Update validations',
    REPORT_validation_watch: 'Info validations',
    REPORT_validation_calculating: 'Calculating...',
    REPORT_validation_succeed: 'Click here to see the validation result',
    APPRAISALS_externalManager: 'Basic Validator',

    ACCOUNT_MANAGEMENT_invoiceCustomization: 'Invoice configuration',
    ACCOUNT_MANAGEMENT_areYouSureDeleteLogo: 'Are you sure you want to remove the custom logo from your new invoices?',
    ACCOUNT_MANAGEMENT_customLogo: 'Custom logo image',
    REQUESTS_expensesProvisionAllAmountInEuro: 'Provision of Income/Expenses',
    APPRAISALS_precedentType: 'Type of precedent',
    APPRAISALS_updatePrecedents: 'Update precedents',
    APPRAISALS_precedents: 'Precedents',
    APPRAISALS_lookingForPrecedents: 'We are looking for precedents...',
    APPRAISALS_precedentsFounded: 'Applications have been found for this appraisal. You can find these files in the background section.',
    APPRAISALS_precedentsNotFounded: 'No precedents have been found for this appraisal.',
    APPRAISALS_precedentsTitleTooltip: 'The list of appraisals that exists in the system is displayed regardless of the date of completion.',
    APPRAISALS_aditionalDataNotCompleted: 'The data presented in order information must be added in Additional Data. Otherwise it will not be possible to send.',
    LINK_tooltip: 'Use control + click, or mouse wheel to open in a new tab',
    COMPARABLES_environmentFeatures: 'Environment features',
    COMPARABLES_situationWithinArea: 'Situation within area',
    COMPARABLES_socioEconomicLevel: 'Socio-economic level area',
    COMPARABLES_fastTrackProximity: 'FastTrack proximity',
    COMPARABLES_buildingType: 'Building-area type',

    COOKIES_headerTitle: 'Your cookie settings',
    COOKIES_headerDescription: 'We take good care of you and your data. You can read more about how we use cookies and update your cookie settings here.',
    COOKIES_analyticsCookies: 'Analytics Cookies',
    COOKIES_analyticsCookiesDescription: 'Analytics cookies make it possible to collect statistics on the use and traffic of our website, so that we can improve it.',
    COOKIES_analyticsPurposeCookiesProviders: 'Which cookie providers do we use for analytics purposes?',
    COOKIES_analyticsPurposeCookiesProvidersDescription: 'We use these providers to collect information for statistical purposes.',
    COOKIES_googleAnalyticsDescription: 'Google specializes in internet-related products and services, including online advertising technologies, a search engine, cloud computing, software and hardware.',
    COOKIES_hotjarDescription: 'Hotjar Ltd. provides web analytics tools that analyze customer data traffic',
    COOKIES_urlText: "Read the cookie provider's privacy policy",
    MULTIPLE_EDITION_title: 'Massive Edition',
    MULTIPLE_EDITION_chapter: 'Chapter',
    MULTIPLE_EDITION_chooseChapterMessage: 'Choose a chapter to complete the information of the assets in massive edition',
    MULTIPLE_EDITION_errorMessage: 'Please check the form fields',
    MULITPLE_EDITION_chaptersSelector: 'Chapter Selector',

    COMMUNICATIONS_technicalManager: 'Validator',
    copy_button: 'Copy',
    paste_button: 'Paste',
    copy_success: 'The information has been copied.',
    paste_success: 'Pasted information.',
    paste_error: 'There is no copied information.',
    paste_not_allowed: 'It is not possible to paste the information on an already closed file.',
    copied: '¡Copied!',
    APPRAISALS_errorRecalculatingValidations: 'Failed to recalculate validations',
    VALIDATION_checkWarnings: 'Successful! We show you the warnings to verify that you have taken them into account',
    VALIDATION_warning: 'Warning!',
    APPRAISALS_contract: 'Origin',
    emptyValue: 'No value',

    web_reload_app_title: 'New version available!',
    web_reload_app_message: '<p class="app-version-p">There is a <b>newer version</b> of the tool available.</p><p class="app-version-p"> In order to enjoy of the latest updates, reload this tab by pressing <img class="app-version-image" src="assets/images/icons/reload.PNG"> next to the browser search bar or press the <span class="app-version-key">Ctrl</span> <b>+</b> <span class="app-version-key">F5</span> on your keyboard.</p>',
    mobile_android_reload_app_message: '<p class="app-version-p">There is a <b>newer version</b> of the tool available.</p><p class="app-version-p"> In order to enjoy for the latest updates, download the app from the <b>Play Store</b>.</p>',
    mobile_ios_reload_app_message: '<p class="app-version-p">There is a <b>newer version</b> of the tool available.</p><p class="app-version-p"> In order to enjoy of the latest updates, download the app from the <b>Apple Store</b></p>',
    APPRAISALS_mbWeight: 'Weight',
    APPRAISALS_sizeInMB: 'MB',
    APPRAISALS_editDocument: 'Edit Document',
    DOCUMENTS_obtainedDatetimeNull: '<b>The issue date is required in documents</b> Registry Documentation, Simple Note and Registry Certification type.',
    DOCUMENTS_vinculatedToAssets: 'Documents of the Cadastral Documentation type must be linked to an asset.',
    APPRAISAL_notifiedToEntity: 'Notification to Entity',

    MASSIVE_marketStudy: 'To create a market study, you can do it from: •Common Data -> Market Study',
    Massive_marketStudyCopyCell: 'Use and typology are different',
    Massive_surfaceCopyCell: 'There is no surface for this asset.',

    cadastralSurfaces: 'cadastral',
    checkedSurfaces: 'checked',
    registeredSurfaces: 'registration',
    APPRAISALS_externalIntermediateWorkEvaluationLastAppraisalDatetime: 'Previous validation date',
    APPRAISALS_externalIntermediateWorkEvaluationLastAppraisalWorkProgressPercent: 'Work progress of the previous report (%)',
    APPRAISALS_externalIntermediateWorkEvaluationLastAppraisalTotalValue: 'Valuation previous appraisal',
    entityDetail: 'Entity detail',
    ASSETS_rCoefficient: 'R',
    ASSETS_rCoefficientInfo: 'Area built with commons between useful area',
    ASSETS_existsComparableMarket: 'Is there a comparable market?',
    ASSETS_defaultComparableMarketText: 'It has not been possible to calculate the value of the property by the comparison method since the requirements described in article 21 of the ECO Order 805/2003 for the use of this method are not met.',

    appraisalInfo: 'Info',
    REPORT_LIST_succesDraftDeneration: "If the report draft didn't open in a new tab, check that you don't have pop-ups blocked in your browser and regenerate it.",
    APPRAISALS_externalConnectionError: 'There was an error with the connection',
    ASSETS_socioEconomicLevel: 'Socioeconomic level',
    languageReport: 'Report language',
    SelectAllNodes: 'Mark/Unmark all',
    CONTACT_US_title_Tasafy: 'Tasafy support',
    CONTACT_US_description: 'If you have any questions or problems with the tool, contact us at the following email:',
    CONTACT_US_call: 'Call',
    MASSIVE_possessions: 'Posses',
    MASSIVE_stays: 'Stays',
    locationError: 'Coordinates cannot be found for the indicated address.',

    hour: 'Hour',
    creation: 'Creation',
    edition: 'Edition',
    actual: 'Current',
    HET: 'HET',

    VALORATION_METHOD_MODAL_ACTION: 'What action do you want to perform?',
    VALORATION_METHOD_MODAL_TYPE: 'Valuation Type',
    PARTNER_MODAL_slide1_title1: '2023 Partner Program',
    PARTNER_MODAL_slide1_subtitle1: 'WE HELP YOU MAKE YOUR DREAMS BIGGER',
    PARTNER_MODAL_slide2_header1: 'Even closer to your growth',
    PARTNER_MODAL_slide2_paragraph1: 'At Valum we have always been concerned with helping you <b>get the most out of your work</b>.',
    PARTNER_MODAL_slide2_paragraph2: 'First we did it by offering to join our <b>partner program</b>: a new form of <b>professional development</b> and a complementary source of income.',
    PARTNER_MODAL_slide2_paragraph3: 'Later, we launched the most complete appraisal management and execution tool on the market -<b>Tasafy</b>-, and we gave you access to the <b>collaborator module: a unique space</b> where you can give Create your own budgets, monitor all your work in real time and achieve maximum customer satisfaction.',
    PARTNER_MODAL_slide2_paragraphBottom1: '...',
    PARTNER_MODAL_slide3_header1: 'Introducing something revolutionary...',
    PARTNER_MODAL_slide3_paragraph1: 'Now 2023 arrives and we want to continue <b>betting on you and your work</b>.',
    PARTNER_MODAL_slide3_paragraph2: 'Can you imagine entering your fees at the same moment the client receives his appraisal report?',
    PARTNER_MODAL_slide3_paragraph3: 'We make it possible!',
    PARTNER_MODAL_slide4_header1: 'Find out! Your agility will mark ours',
    PARTNER_MODAL_slide4_paragraph1: 'The customer makes the payment of the estimate issued from Tasafy.',
    PARTNER_MODAL_slide4_paragraph2: 'You visit the property and begin the execution of the appraisal report through Tasafy.',
    PARTNER_MODAL_slide4_paragraph3: 'You deliver the draft appraisal report to Valum. This is validated and delivered to the client',
    PARTNER_MODAL_slide4_paragraph4: 'Valum and you approve the fees for this file and they will be paid immediately on the same day*.',
    PARTNER_MODAL_slide4_paragraphTitle0: 'Useful example',
    PARTNER_MODAL_slide4_paragraphTitle1: 'Monday',
    PARTNER_MODAL_slide4_paragraphTitle2: 'Tuesday',
    PARTNER_MODAL_slide4_paragraphTitle3: 'Wednesday',
    PARTNER_MODAL_slide4_paragraphBottom1: '*Check requirements and conditions',

    PARTNER_MODAL_slide5_header1: 'Requirements',
    PARTNER_MODAL_slide5_paragraph1: 'This payment format will only apply to budgets generated from January 1, 2023 and paid by POS: credit card or bizum, as well as for payments by bank transfer only when the amount exceeds €1,500 + VAT.',
    PARTNER_MODAL_slide5_paragraph2: 'Likewise, it will only be valid for files in which you appear as a prescriber. Assignments for data collection or assembly are therefore excluded.',
    PARTNER_MODAL_slide5_paragraph3: 'Likewise, it must be a valuation report made by our execution tool <b>Tasafy</b>, other external tools cannot be used.',

    PARTNER_MODAL_slide6_header1: 'General conditions',
    PARTNER_MODAL_slide6_paragraph1: 'The billing system becomes daily. From Monday to Friday, from 3:00 p.m., an invoice will be generated with all your files delivered to the client (status 11). From your billing panel, you must validate it.',
    PARTNER_MODAL_slide6_paragraph2: 'You decide when to validate your invoices: daily, weekly, fortnightly... You will know that once you have validated your invoices, you will receive payment from 5:00 p.m.',
    PARTNER_MODAL_slide6_paragraph3: 'The invoice date will be the date of your validation. As before, you must fill in the invoice number.',
    PARTNER_MODAL_slide6_paragraph4: 'To receive payment on the same day, you must accept your invoices before 5:00 p.m. each day. After this period, you will receive the payment the next day after 5:00 p.m.',
    PARTNER_MODAL_slide6_paragraph5: 'On holidays, invoices will also be generated for files in status 11. Payment will be made on the next business day. Likewise, invoices validated on Fridays will be paid together with those of Monday',

    PARTNER_MODAL_slide7_header1: 'How it works',
    PARTNER_MODAL_slide7_paragraph1: 'From Monday to Friday, starting at 3:00 p.m., a provisional invoice will be generated that will include all your files in status 11 that have been generated in the last 24 hours.',
    PARTNER_MODAL_slide7_paragraph2: 'You will receive an alert on your mobile notifying you that you have an invoice pending validation.',
    PARTNER_MODAL_slide7_paragraph3: 'Remember to have notifications turned on. You must validate it and enter the corresponding invoice number.',
    PARTNER_MODAL_slide7_paragraph4: 'You will have until 5:00 p.m. that day to carry out the validation. Subsequently, your payment will be made.',
    PARTNER_MODAL_slide7_paragraph5: 'Everything that you validate after 5:00 p.m. will be included in the payment of the next business day.',
    PARTNER_MODAL_slide7_paragraph6: 'If you reject an invoice because you do not agree with the fees of a file, it will enter the review process and will be corrected, if applicable, the next day before 5:00 p.m. You will receive your payment afterwards.',

    PARTNER_MODAL_slide8_paragraph1: 'THANK YOU',
    PARTNER_MODAL_slide8_paragraph2: 'For any questions or more information, contact us',
    PARTNER_MODAL_slide8_button1: 'Finish',
    PROFESSIONAL_MODAL_paragraph: '<p>You have <b>pending documentation</b> for delivery to be able to start receiving charges.</p><p>Please check the documents section of your profile</p>',
    PROFESSIONAL_MODAL_button: 'Go to documents',
    IMPORT_ASSETS_message: 'Do not forget to import application assets so you can <b>complete your report!<div class="js-intro-show-again"></div>',
    IMPORT_ASSETS_dontShowAgain: 'Do not show again',
    IMPORT_ASSETS_title: 'Import assets',

    COMMUNICATIONS_reviewedBy: 'Reviewed By',
    COMMUNICATIONS_theDay: 'the day',

    REQUESTS_fromPartner: 'Is it from partner?',

    TECHNICAL_MODAL_slide1_title1: 'Say hello to our bells!',

    TECHNICAL_MODAL_slide2_header1: 'What are they for?',
    TECHNICAL_MODAL_slide2_paragraphTitle0: 'There are three types:',
    TECHNICAL_MODAL_slide2_paragraphTitle1: 'MISTAKES',
    TECHNICAL_MODAL_slide2_paragraph1: 'The bell will indicate which report fields must be modified for delivery.',
    TECHNICAL_MODAL_slide2_paragraphTitle2: 'WARNINGS',
    TECHNICAL_MODAL_slide2_paragraph2: 'The bell will indicate relevant information to consider.',
    TECHNICAL_MODAL_slide2_paragraphTitle3: 'INFORMATION',
    TECHNICAL_MODAL_slide2_paragraph3: 'The bell will suggest possible improvements to the report.',

    TECHNICAL_MODAL_slide3_header1: 'How can I consult them?',
    TECHNICAL_MODAL_slide3_paragraph1: 'The alerts can be consulted at any time by accessing the latest version of the report and clicking on the bell that appears at the top right, here:',
    TECHNICAL_MODAL_slide3_paragraph2: 'Then a window like the following will be displayed:',
    TECHNICAL_MODAL_slide3_paragraph3: 'After making modifications, do not forget to click on the Update validations button, at the bottom, in order to know the most current version of the campaigns available.',

    COMPARABLES_completeComparables: 'Complete your witnesses',
    COMPARABLES_findComparables: 'Find your witness',
    COMPARABLES_createComparable: 'Create witness',
    COMPARABLES_selectComparable: 'Select witness',
    COMPARABLES_chooseComparablesTitle: 'Choose the witness you want to use',
    COMPARABLES_confirmSelection: 'Confirm selection',
    COMPARABLES_newSearch: 'New search',
    COMPARABLES_backToSearchResultList: 'Back to search result',
    COMPARABLES_completeErrorMessage: 'Complete the required data to be able to add the token',
    COMPARABLES_skipConfirmMessage: 'By performing this action, the result of the search performed will be lost, continue?',
    COMPARABLES_addComparables: 'Add witnesses',
    COMPARABLES_completeSuccessMessage: 'Completed',
    COMPARABLES_searchInIdealista: 'Search in Idealista',
    COMPARABLES_yearConstruction: 'Construction year',
    COMPARABLES_operation: 'Type of sale',
    COMPARABLES_numberOfRoomsFromTo: 'Number of rooms',
    COMPARABLES_numberOfBathsFromTo: 'Nº bathrooms',
    COMPARABLES_numberOfRooms: 'Number of rooms',
    COMPARABLES_numberOfBaths: 'Number of bathrooms',
    COMPARABLES_interiorStateProperty: 'Interior state of the property',
    COMPARABLES_top: 'Top',
    COMPARABLES_middle: 'Middle',
    COMPARABLES_ground: 'Ground',
    COMPARABLES_moreFilters: 'More filters',
    COMPARABLES_searchResultEmpty: 'There are no results for this search',
    COMPARABLES_searchResultError: 'Error in call',
    COMPARABLES_added: 'Added',
    COMPARABLES_withoutAddress: 'Unaddressed witness',
    COMPARABLES_removeComparableMessage: 'If you delete the witness, the information entered will be lost. Continue?',
    COMPARABLES_idealistaTermsAndConditionsTitle: 'Idealista Terms and Conditions',
    COMPARABLES_idealistaRequiredWitnessInfo: '<p>In the next step, the <a href="www.idealista.com">Idealista.com</a> tokens will be consulted and downloaded. This service has an associated cost of 20 cents + taxes and each consultation will return a maximum of 30 witnesses.</p><p>The application of excessively restrictive filters can reduce the volume of witnesses found.</p> <p>If after the consultation no witnesses are obtained, the price will be Also 20 cents + taxes.</p>',
    COMPARABLES_idealistaConditionsAccept: 'I have read and accept the conditions',
    COMPARABLES_addComparableManually: 'Add witness manually',
    COMPARABLES_chooseIdealistaComparables: 'Choose the witness you want to use',
    COMPARABLES_chooseACadastralReference: 'Choose a cadastral reference',

    ASSET_marketStudyCreateInValorationMethod: '*A market study will be created that you can select for other methods of this report',
    COMPARABLES_confirmCloseModal: 'Are you sure you want to close this window? You will lose the search and the comparables filled in.',

    MULTIPLE_EDITION_valuationState: 'Valuation state',
    MULTIPLE_EDITION_addValuation: 'Add Valuation',
    MULTIPLE_EDITION_cancelAddValuation: 'Cancel Add Valuation',
    MULTIPLE_EDITION_editingValuation: 'Editing valuation',
    MULTIPLE_EDITION_cancelEditValuation: 'Cancel Edit',
    MULTIPLE_EDITION_createValorationNotAllowed: 'Valuation cannot be added for this asset',
    RECALCULATION_successMessage: 'Successful recalculation',
    RECALCULATION_errorMessage: 'Failed to perform recalculation',

    MARKET_STUDY_assetReferenced: 'Referenced asset',
    MARKET_STUDY_assetReferencedMessage: 'This market study refers to the characteristics of the selected property, being applicable to other assets with similar properties.',
    MARKET_STUDY_assetReferenceInfo: '*Data extracted from the report, if you wish to complete or modify them you must do so from the corresponding chapter.',
    MARKET_STUDY_height: 'Height',
    MARKET_STUDY_landValueCalulation: 'Calculation of land value within cost',
    MARKET_STUDY_plotArea: 'Plot area',
    editDocument_error: 'Cannot edit a document that has been imported.',
    APPRAISALS_VALUATION_saveGroundValuation: 'Save Land Value',
    ground_characteristics: 'Ground characteristics',
    info_vinculation: 'Once the assessment is created, the link will not be editable',
    info_order: 'Once the order is created, the link will not be editable',
    COST_METHOD_GROUND_comparisonMethod: 'Ground Valuation - Comparison',
    COST_METHOD_GROUND_staticResidualMethod: 'Ground Valuation - Static Residual',
    COST_METHOD_GROUND_dynamicResidualMethod: 'Land Valuation - Dynamic Residual',
    CLUSTER_recalculateUnits: 'Recalculate units',
    APPRAISALS_VALUATION_groundValuation: 'Ground Value',

    COST_METHOD_observationsProposedValues: 'Justification for useful life calculation',
    APPRAISAL_DASHBOARD_FILTER_tabOrdersInfo: 'Order information',
    APPRAISAL_DASHBOARD_FILTER_interveners: 'Interveners',
    USER_IS_VERIFIED_APPRAISER: 'Validated professional',
    USER_IS_VERIFIED_APPRAISER_info: 'By verifying the professional, it is confirmed that he or she has provided all the necessary documentation for collaboration and correctly recorded incompatibilities, allowing work assignment.',
    USER_internal_observations_tooltip: 'These comments will not be viewed by the professional from their profile.',
    USER_userStatus: 'User status',
    USER_info_text: 'The information reflected in this section corresponds to the preferences indicated by the professional, and editing is not possible. The assignment of assignments will be carried out according to verified criteria, and may be adjusted in the training section.',
    absences_info_text: 'In this section you can indicate your periods of inactivity to block the receipt of orders during it. Press the "+" button to add absence days',
    absence: 'Absence',
    abstences_add: 'Add absence days',
    absences_startDate: 'Start of absence',
    absences_finishDate: 'Finish of absence',
    COMPARABLES_comparablesTitle: 'Comparables',
    COMPARABLES_infoComparablesTitle: 'Comparable information',
    COMPARABLES_appraisalFilterTitle: 'Order details',
    COMPARABLES_appraisalIdFilter: 'Appraisal ID',
    USERS_state_inactive: 'User inactive',
    USERS_state_checked: 'Validated user',
    USERS_state_absent: 'User absent',
    USERS_state_notValidated: 'User not validated',
    training: 'Training',
    typologies_to_evaluate: 'Typologies to evaluate',
    limit_assignment_check: 'Limit the assignment of assignments to data collection',
    limit_assignment_check_subtitle: 'If this option is selected, the professional will only be able to carry out tasks classified as Data Collection.',
    edit_professional_preferences_check: 'Edit professional preferences',
    professional_score: 'Professionals score',
    APPRAISALS_selectScore: 'Rate the technical work carried out by the professional responsible for this file',
    TRAINING_globalScore: 'GLOBAL SCORE',
    TRAINING_deadline: 'DEADLINE',
    TRAINING_execution: 'EXECUTION',
    TRAINING_level1: 'LEVEL 1',
    TRAINING_level2: 'LEVEL 2',
    TRAINING_level3: 'LEVEL 3',
    TRAINING_elements: 'ELEMENTS',
    TRAINING_buildings: 'BUILDINGS',
    TRAINING_terrains: 'TERREINS',
    TRAINING_rustic: 'RUSTIC PROPERTIES',
    TRAINING_economicExploitations: 'ECONOMIC EXPL',
    TRAINING_deadline_title: 'Total grades relative to the deadline',
    TRAINING_level_title: 'Total grades relative to execution',
    soon: 'SOON',

    REQUESTS_confirmExpenses: 'Confirm income/expense provision or the customer sector',
    REQUESTS_sectorCustomer: 'The customer sector',
    REQUESTS_confirmExpensesIconTooltip: 'Income/expense provision needs to be confirmed or the customer sector',
    REQUESTS_pendingConfirmExpenses: 'Provision income/expenses',
    pdfCustomerAdvanced: 'Appraisal Report',
    QUALITY_CONTROL_customerAdvanced_externalReference: 'External Reference',
    QUALITY_CONTROL_customerAdvanced_appraisalOwnerName: 'Appraisal owner name',
    QUALITY_CONTROL_customerAdvanced_appraisalOwnerLastName: 'Surname owner appraisal',
    QUALITY_CONTROL_customerAdvanced_appraisalOwnerFullName: 'Name and surname of appraisal holder',
    QUALITY_CONTROL_customerAdvanced_appraisalOwnerDNI: 'appraisal owner ID',
    GLOBALSEARCH_searchResult: 'Search result',

    REQUEST_documentPDF: 'PDF Document',
    REQUEST_uploadDocument: 'Upload PDF document',
    upload: 'Upload',

    SIVASA_waitingForPayment: 'Waiting for payment',
    SIVASA_cancelledNonPayment: 'Cancellation due to non-payment',

    MENU_how_it_works: 'How does it work?',
    MULTIPLE_FILE_UPLOAD_noItemsAdded: 'There is no added element',
    MULTIPLE_FILE_UPLOAD_chooseLabel: 'Select',
    MULTIPLE_FILE_UPLOAD_uploadLabel: 'Upload',
    MULTIPLE_FILE_UPLOAD_cancelLabel: 'Delete all',
    COMPARABLES_backToSelectedComparables: 'Complete your witness',
    COMPARABLES_completet: 'I have read and accept the conditions',
    COMPARABLES_searchResult: 'Search result',
    COMPARABLES_selectedComparables: 'Selected witnesses',
    COMPARABLES_noItemsSelected: 'No witnesses selected',
    COMPARISON_METHOD_witnessComplete: 'Complete your witness',
    COMPARISON_METHOD_SearchWitness: 'Find your witness',
    VISITTYPE_tooltip: 'For appraisals carried out under the purpose of Circular 4/2017, the minimum required type of visit (in the case that “exterior” is selected) will not be mandatory to carry out an interior visit.',

    key: 'Key',
    value: 'Value',
    addKeyValue: 'Add key/value pair',
    nifNiePassportCifCustomer: 'NIF/NIE/Passport/Customer CIF',
    nifNiePassportCif: 'NIF/NIE/Passport/CIF',

    QUALITY_DASHBOARD_qCPendingAssignProfessional: 'Pendientes de asignar técnico',
    QUALITY_DASHBOARD_qCInProgess: 'En proceso de validación',
    QUALITY_DASHBOARD_summaryOfQCValidated: 'Resumen control calidad finalizados',
    QUALITY_DASHBOARD_summaryOfQCTotalValidated: 'Resumen total finalizados',
    QUALITY_DASHBOARD_qCValidationOK: 'Validación OK',
    QUALITY_DASHBOARD_qCValidationKO: 'Validación KO',
    QUALITY_DASHBOARD_summaryQCFinished: 'Resumen control de calidad finalizado',
    QUALITY_DASHBOARD_globalAverageTime: 'Tiempo medio global control de calidad',
    QUALITY_DASHBOARD_professionalAssignmentAverageTime: 'Tiempo medio asignación',
    QUALITY_DASHBOARD_validationAverageTime: 'Tiempo medio validación',

    CLUSTER_workState: 'Construction status',
    CLUSTER_state: 'Asset status',
    REPORT_validatedVersion: 'Validated version',

    UPLOAD_DOCUMENT_documentation: 'Documentation',
    UPLOAD_DOCUMENT_visitPhoto: 'Visit Photo',
    DOCUMENTS_mustSelectAssets: 'Visit photos must be assigned to assets',
    UPLOAD_PHOTO_message: 'In this section only photographs of the visit should be added. The rest of the documents, regardless of the format, must be incorporated into the documentation chapter.',
    ASSETS_energeticExemptionJustification: 'Exemption justification',
    ASSETS_energeticExemptionJustificationMessage: 'Justificativa da isenção da obrigação de contribuir com EEC',

    APPRAISAL_REPORT_econominExploitationExceedUsefulLife: 'The dates you have selected exceed the useful life of the property, remember to consider the necessary costs in the exploitation flows for this extension of the useful life.',
    BDE_canAddBdeDataButton: 'Add Bank of Spain data',
    BDE_removeBdeDataButton: 'Remove Bank of Spain data',
    BDE_bdeData: 'Bank of Spain Data',
    BDE_sectionMessage: '<i>The Bank of Spain data must be broken down at the registry property level for <u>land, rural properties and building elements</u>. </br></br>For <u>buildings</u> it will be reported jointly at the appraisal level, providing the main use.</i>',
    BDE_valuationInformationTitle: 'Information relating to the valuation method adopted',
    BDE_valuationInformationHETTitle: 'Information regarding the HET valuation method adopted',
    BDE_valuationInformationBuildingElementTitle: 'Main use of the building to be developed',
    BDE_adoptedValorationValue: 'Current adopted value ({{currency}})',
    BDE_adoptedValorationHETValue: 'HET adopted value ({{currency}})',
    BDE_adoptedValorationMethod: 'Current valuation method adopted',
    BDE_adoptedValorationHETMethod: 'Adopted HET valuation method',
    BDE_khCoefficientWarningIncluded: 'Specific warning has been included',
    BDE_usefulLife: 'Outstanding useful life (years)',
    BDE_durationProject: 'Project duration (months)',
    BDE_yearsToFinish: 'Deadline for completion of works (years)',
    BDE_evolution: 'Expected evolution of the market. Rate',
    BDE_removeConfirmMessage: 'If you delete the Bank of Spain data you will lose the information entered. Do you wish to continue?',
    BDE_filterExecutionTitle: 'Reports without execution by Tasafy',
    BDE_activeData: 'Active BdE data',
    BDE_completeData: 'Complete BdE data',
    BDE_withMoreThanOneHundredParentAssetAsResidential: 'It has more than 100 homes',

    APPRAISALS_addMassiveAssets: 'Massive asset addition',
    APPRAISALS_addMassiveAssetsStructure: 'Massive addition of valuation structure',
    APPRAISALS_uploadMassiveAssetsFile: 'Upload upload file',
    APPRAISALS_massiveAssetsFileResult: 'Upload result',
    APPRAISALS_massiveAssetsProcessFile: 'Process file',
    APPRAISALS_massiveAssetsInProcess: 'Processing...',
    APPRAISALS_massiveAssetsProcessOK: 'The file has been carded successfully',
    APPRAISALS_massiveAssetsProcessKO: 'The file is not in the correct format',
    APPRAISALS_massiveAssetsBack: 'Back',
    APPRAISALS_massiveAssetsFinish: 'Finish',
    APPRAISALS_massiveAssetsSelectFile: 'Select File',
    APPRAISALS_massiveAssetsTimeMessage: 'Approximate load time: 10 assets per second',
    CLUSTER_BUILDINGSTATE_recalculateClusterWarning: 'Remember that after recalculating you must save the changes.',

    APPRAISALS_MASSIVE_addToStructure: 'Add assets to existing structure',
    APPRAISALS_MASSIVE_replaceStructure: 'Replace the current structure (existing information will be removed)',
    APPRAISALS_MASSIVE_replaceStructureMessage: 'It is not possible to add new registry properties for this report. Only the incorporation of annexes linked to existing ones will be allowed. </br> </br> To do this, you must first add a description to them to be able to reflect it on the template. This option is available in the <b>Description</b> chapter of the <b>Mass Edit</b>',
    APPRAISALS_REPORT_addDescription: 'Add Description',
    APPRAISALS_REPORT_assetDescription: 'Asset Description',
    REPORT_validationButtonsTitle: 'Validations',
    REPORT_validationButtonsUpdate: 'Update',
    REPORT_validationButtonsInfo: 'Information',
    REPORT_valuationButtonsTitle: 'Ratings',
    REPORT_valuationButtonsSummary: 'View summary',
    REPORT_valuationSumary_element: 'By element',
    REPORT_valuationSumary_property: 'By registered property',
    AssetTabs_50: 'Descripción del activo'

  },
};
