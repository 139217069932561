import { FormGroup } from '@angular/forms';
import { GroupMassiveValorationModel, MassiveModelData, MassiveValorationsBaseModel, MultipleEditionItemModel, ValuationMassiveStates } from '@foxeet/domain';

export const getParentFormGroup = (params): FormGroup => {
  const { context, node, rowNode } = params;
  return <FormGroup>context.formArray.controls[node?.id || +rowNode?.id];
};

export const getChildFormGroup = (params): FormGroup => {
  const { context, node, rowNode } = params;
  const parentFormGroup = <FormGroup>context.formArray.controls[node?.id || +rowNode?.id];
  return parentFormGroup.get('valorationData') as FormGroup;
};

export const createValoration = (params, callback: (data: any, formGroup: FormGroup, hasChanges: boolean) => void) => {
  const parentFormGroup = getParentFormGroup(params);
  const formGroup = getChildFormGroup(params);

  formGroup.enable();
  callback(params.data, formGroup, parentFormGroup.get('hasChanges')?.value);
};

export const isValuationMethodEnable = (params) => {
  if (params && params.data && params.data.el) {
    const { massiveState, valorationInitialValues } = params.data?.el.data;
    const parentFormGroup = getParentFormGroup(params);
    const errors: string[] = valorationInitialValues.errors;

    return (massiveState === ValuationMassiveStates.Editable && !errors.length) || (massiveState === ValuationMassiveStates.Creatable && parentFormGroup.get('hasChanges')?.value);
  }
};

export const isValuationDisableByMassiveStateAndErrors = (valuation: MultipleEditionItemModel<MassiveModelData<any>, any>, hasChanges: boolean) => {
  const { massiveState, valorationInitialValues } = valuation.el.data;
  return (
    massiveState === ValuationMassiveStates.NoActionAllowed ||
    (massiveState === ValuationMassiveStates.Creatable && !hasChanges) ||
    (valorationInitialValues && valorationInitialValues?.errors && valorationInitialValues?.errors.length)
  );
};
