import { BehaviorSubject, Observable } from 'rxjs';
import { ColDef, FillOperationParams, ICellEditorParams, ICellRendererParams } from 'ag-grid-community';
import { FormGroup } from '@angular/forms';
import { DynamicFormModel, IFormDataModel } from '../dynamic-form.model';
import { FormModel } from '../form.model';
import { AssetUses, InternationalizationTypes } from '../../enums';

export interface InputSurfacesDropdownParams {
  use: AssetUses;
  recalculateRow: (value: any) => void;
  callback?: (params, value?: any) => void;
  controlNameOriginal?: string;
}

export interface InputDropdownBaseParams {
  options: BehaviorSubject<any[]>;
  enumName?: string;
  activeFilter?: boolean;
  activeVirtualScroll: boolean;
  replaceDropdownWithText?: (params: ICellEditorParams | ICellRendererParams, fromFillOperation?: boolean) => boolean;
  controlNameOriginal?: string;
  controlNameToReplace?: string;
  checkStatusFrom?: string[];
  subscribeToChanges?: boolean;
  callback?: (params, value?: any) => void;
  filterValueFormatter?: (value: any) => void;
  showConfirmModal?: boolean;
  confirmModalTitle?: string;
  isMarketStudy?: boolean;
  cellClass?: (params: ICellEditorParams | ICellRendererParams) => string;
}

export interface InputValuationStateEditorParams {
  initialValuesCallback?: (value: any) => void;
  executeAfterCopyCell: (params: ICellEditorParams | ICellRendererParams, toFormData: FormGroup, formGroupRowBeforeOrAfter: FormGroup) => any;
}

export interface InputTextAreaEditorParams {
  showButton: boolean;
  buttonName?: string;
  callback?: (params, value?: any) => void;
  executeAfterCopyCell: (params: ICellEditorParams | ICellRendererParams, toFormData: FormGroup, formGroupRowBeforeOrAfter: FormGroup) => any;
}

export interface InputTextEditorParams {
  callback?: (params, value?: any) => void;
}

export interface InputNumberRefactorColDefModel extends ColDef {
  unit: InternationalizationTypes;
  enableFormat: boolean;
  digits: string;
  suffix: string;
  digitsFn: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellRefactorParams) => string;
  executeAfterCopyCell: (params: ICellEditorParams | ICellRendererParams, toFormData: FormGroup, formGroupRowBeforeOrAfter: FormGroup) => any;
  checkPreviousFieldsEqualsValues?: string[];
  fieldsToCopy?: string[];
  showRenderer: (params: any) => boolean;

  //MODAL
  openDynamicModalButtonIcon?: string;
  openDynamicModalButtonIconMobile?: string;
  openDynamicModal?: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellRefactorParams) => boolean;
  openModalFn?: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellRefactorParams) => any;
  dynamicModalTitle?: string;
  dynamicModalSubTitle?: string;
  dynamicModalForm?: FormModel[];
  dynamicModalRequired?: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellRefactorParams) => boolean;
  dynamicModalConfig?: (params) => IFormDataModel[][] | DynamicFormModel;
  dynamicModalSubscribeToFields?: { name: string; callback: (params, value) => void }[];
  executeBeforeOpenModal?: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellRefactorParams) => any;
  executeOnConfirmModal?: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellRefactorParams) => any;
  executeOnCloseModal?: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellRefactorParams, data) => any;
}

export interface InputNumberCellRefactorParams {
  unit: InternationalizationTypes;
  enableFormat: boolean;
  digits: string;
  suffix: string;
  digitsFn: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellRefactorParams) => string;
  executeAfterCopyCell: (params: ICellEditorParams | ICellRendererParams, toFormData: FormGroup, formGroupRowBeforeOrAfter: FormGroup) => any;
  checkPreviousFieldsEqualsValues?: string[];
  fieldsToCopy?: string[];
  showRenderer: (params: any) => boolean;

  //MODAL
  openDynamicModalButtonIcon?: string;
  openDynamicModalButtonIconMobile?: string;
  openDynamicModal?: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellRefactorParams) => boolean;
  openModalFn?: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellRefactorParams) => any;
  dynamicModalTitle?: string;
  dynamicModalSubTitle?: string;
  dynamicModalForm?: FormModel[];
  dynamicModalRequired?: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellRefactorParams) => boolean;
  dynamicModalConfig?: (params) => IFormDataModel[][] | DynamicFormModel;
  dynamicModalSubscribeToFields?: { name: string; callback: (params, value) => void }[];
  executeBeforeOpenModal?: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellRefactorParams) => any;
  executeOnConfirmModal?: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellRefactorParams) => any;
  executeOnCloseModal?: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellRefactorParams, data) => any;
}
export interface InputNumberCellEditorParams {
  hideButtonCancel?: boolean;
  dependingToValidationForm?: string;
  recalculateRow: (data: any) => void;
  fieldDependingSuffix: string;
  subscribeToChanges?: boolean;
  callback?: (params, value?: any) => void;
  suffix: string;
  showUnit: InternationalizationTypes;
  digitsInfo: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => string;
  enableFormat: boolean;
  showRenderer: (params: any) => boolean;

  // Modal
  // openModalFn: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => any;
  // openDynamicModalButtonIcon: string;
  // openDynamicModalButtonIconMobile: string;
  // openDynamicModal: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => boolean;
  // dynamicModalTitle: string;
  // dynamicModalSubTitle: string;
  // dynamicModalConfig: (params) => IFormDataModel[][] | DynamicFormModel;
  // dynamicModalForm: FormModel[];
  // dynamicModalRequired: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => boolean;
  // dynamicModalSubscribeToFormChanges: boolean;
  // dynamicModalSubscribeToFields: { name: string; callback: (params, value) => void }[];
  // executeBeforeOpenModal: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => any;
  // executeOnConfirmModal: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => any;
  // executeOnCloseModal: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams, data) => any;
  // executeWhenCopyCell: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams, formGroupRowBeforeOrAfter: FormGroup) => any;
}

export interface InputButtonUploadCellEditorParams {
  dependingFields?: string[];
  callback?: (params, value?: any) => void;
}

export interface InputDateCellEditorParams {
  canReset: boolean;
  minDate: any;
  maxDate: any;

  /* WEB PURPOSE */
  showTime: boolean;
  showLabel: boolean;
  dataType: string;

  /* MOBILE PURPOSE */
  displayFormat: string;
  pickerFormat: string;
}

export interface InputDateCellRendererParams {
  canReset: boolean;
  minDate: any;
  maxDate: any;

  /* WEB PURPOSE */
  showTime: boolean;
  showLabel: boolean;
  dataType: string;

  /* MOBILE PURPOSE */
  displayFormat: string;
  pickerFormat: string;
}

export interface InputModalItemsCellEditorParams {
  nameItems: string;
  formItemModel: IFormDataModel[][] | DynamicFormModel;
  formItemConfig: FormModel[];
}

export interface InputDropdownCellEditorParams extends InputDropdownBaseParams {
  dependingFields?: string[];
  valorationMethod?: number;
  recalculateRow: (data: any) => void;
  controlNameOriginal: string;
}

export type InputDropdownCellRendererParams = InputDropdownBaseParams;

export interface InputModalItemsCellEditorParams {
  nameItems: string;
  formItemModel: IFormDataModel[][] | DynamicFormModel;
  formItemConfig: FormModel[];
}

export interface IdColDefModel {
  id: string;
  errors?: string[];
}

export interface InputValuationStateColDefModel extends ColDef {
  initialValuesCallback?: (value: any) => void;
  executeAfterCopyCell: (params: ICellEditorParams | ICellRendererParams, toFormData: FormGroup, formGroupRowBeforeOrAfter: FormGroup) => any;
}

export interface InputSurfacesDropdownColDefModel extends ColDef {
  callback?: (params, value?) => void;
  recalculateRow: (data: any) => void;
  controlNameOriginal?: string;
  executeAfterCopyCell: (params: ICellEditorParams | ICellRendererParams, toFormData: FormGroup, formGroupRowBeforeOrAfter: FormGroup) => any;

  // executeWhenCopyCell: (params: (ICellEditorParams | ICellRendererParams) & any, formGroupRowBeforeOrAfter: FormGroup) => any;
}

export interface BaseInputColDefModel extends ColDef {
  options?: any;
  forceCopy?: boolean;
}

export interface InputNumberColDefModel extends ColDef {
  hideButtonCancel?: boolean;
  recalculateRow: (data: any) => void;
  subscribeToChanges?: boolean;
  callback?: (params, value?: any) => void;
  suffix: string;
  showUnit: InternationalizationTypes;
  digitsInfo: string;
  enableFormat: boolean;
  fieldDependingSuffix: string;
  showRenderer: (params: any) => boolean;

  // Modal
  // openModalFn: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => any;
  // openDynamicModalButtonIcon: string;
  // openDynamicModalButtonIconMobile: string;
  // openDynamicModal: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => boolean;
  // dynamicModalTitle: string;
  // dynamicModalSubTitle: string;
  // dynamicModalConfig: (params) => IFormDataModel[][] | DynamicFormModel;
  // dynamicModalForm: FormModel[];
  // dynamicModalRequired: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => boolean;
  // dynamicModalSubscribeToFormChanges: boolean;
  // dynamicModalSubscribeToFields: { name: string; callback: (params, value) => void }[];
  // executeBeforeOpenModal: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => any;
  // executeOnConfirmModal: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => any;
  // executeOnCloseModal: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams, data) => any;
  // executeWhenCopyCell: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams, formGroupRowBeforeOrAfter: FormGroup) => any;

  // REFACTOR
  unit: InternationalizationTypes;
  digits: string;
  digitsFn: string;
}

export interface InputTextColDefModel extends ColDef {
  callback?: (params, value?: any) => void;
}

export interface InputTextAreaColDefModel extends ColDef {
  showButton: boolean;
  buttonName: string;
  callback?: (params, value?: any) => void;
  executeAfterCopyCell: (params: ICellEditorParams | ICellRendererParams, toFormData: FormGroup, formGroupRowBeforeOrAfter: FormGroup) => any;
}

export interface InputModalItemsColDefModel extends ColDef {
  // callback?: (value: any) => void;
  nameItems: string;
  formItemModel: IFormDataModel[][] | DynamicFormModel;
  formItemConfig: FormModel[];
  defaultItemValue: (params: (ICellEditorParams | ICellRendererParams) & InputModalItemsCellEditorParams) => any;
  execAfterConfirm: (params: (ICellEditorParams | ICellRendererParams) & InputModalItemsCellEditorParams) => any;
  execAfterCopyItems: (params: FillOperationParams) => any;
}

export interface InputDateColDefModel extends ColDef {
  canReset: boolean;
  minDate: any;
  maxDate: any;

  /* WEB PURPOSE */
  showTime: boolean;
  showLabel: boolean;
  dataType: string;

  /* MOBILE PURPOSE */
  displayFormat: string;
  pickerFormat: string;
}

export interface InputDropdownColDefModel extends BaseInputColDefModel {
  options: (params: (ICellEditorParams | ICellRendererParams) & InputDropdownCellEditorParams) => Observable<any[]> | any[] | null;
  enumName?: string;
  dependingFields?: string[];
  activeFilter?: boolean | undefined;
  activeVirtualScroll?: boolean;
  replaceDropdownWithText?: (params: (ICellEditorParams | ICellRendererParams) & InputDropdownCellEditorParams, fromFillOperation?: boolean) => boolean;
  controlNameOriginal?: string;
  controlNameToReplace?: string;
  checkStatusFrom?: string[];
  callback?: (params, value?) => void;
  filterValueFormatter?: (params: (ICellEditorParams | ICellRendererParams) & InputDropdownCellEditorParams) => void;
  showConfirmModal?: boolean;
  confirmModalTitle?: string;
  recalculateRow: (data: any) => void;
  valorationMethod?: number;
  isMarketStudy?: boolean;
  executeAfterCopyCell: (params: ICellEditorParams | ICellRendererParams, toFormData: FormGroup, formGroupRowBeforeOrAfter: FormGroup) => any;
}

export interface InputDropdownControlColDefModel extends BaseInputColDefModel {
  options: (params: (ICellEditorParams | ICellRendererParams) & InputDropdownCellEditorParams) => Observable<any[]> | any[] | null;
  enumName?: string;
  dependingFields?: string[];
  activeFilter?: boolean | undefined;
  activeVirtualScroll?: boolean;
  replaceDropdownWithText?: (params: (ICellEditorParams | ICellRendererParams) & InputDropdownCellEditorParams, fromFillOperation?: boolean) => boolean;
  controlNameOriginal?: string;
  controlNameToReplace?: string;
  checkStatusFrom?: string[];
  callback?: (params, value?: any) => void;
  filterValueFormatter?: (params: (ICellEditorParams | ICellRendererParams) & InputDropdownCellEditorParams) => void;
  recalculateRow: (data: any) => void;
  executeAfterCopyCell: (params: ICellEditorParams | ICellRendererParams, toFormData: FormGroup, formGroupRowBeforeOrAfter: FormGroup) => any;
}

export enum ColumnType {
  INPUT_VALUATION_STATE,
  INPUT_TEXT,
  INPUT_TEXT_AREA,
  INPUT_NUMBER,
  INPUT_NUMBER_REFACTOR,
  INPUT_DROPDOWN,
  INPUT_CHECKBOX,
  INPUT_SWITCH,
  INPUT_TRIPLE_SWITCH,
  INPUT_SEARCHER,
  INPUT_STREETNAME,
  INPUT_DATE,
  INPUT_MODAL_ITEMS,
  INPUT_SURFACES_DROPDOWN,
  INPUT_BUTTON_UPLOAD,
  INPUT_DROPDOWN_CONTROL,
}

export enum ColumnDataType {
  STRING,
  INT,
  FLOAT,
  BOOLEAN,
}

export interface ColumnDataTypeConfig {
  column: string;
  columnDataType: ColumnDataType;
}

export interface ColumnConfig
  extends Partial<InputDropdownColDefModel>,
    Partial<InputValuationStateColDefModel>,
    Partial<InputNumberCellEditorParams>,
    Partial<InputNumberCellRefactorParams>,
    Partial<InputModalItemsColDefModel>,
    Partial<InputTextAreaEditorParams>,
    Partial<InputTextEditorParams>,
    Partial<InputDateCellEditorParams>,
    Partial<InputSurfacesDropdownParams> {
  colType?: ColumnType | ((params: ICellEditorParams | ICellRendererParams) => ColumnType);
  field?: string;
  headerName: string;
  children?: ColumnConfig[];
}
