import {
  AssetSurfaceIds,
  AssetTypologies,
  AssetUses,
  BuildingUses,
  DynamicResidualFreeRateTypes,
  EconomicExploitationTypes,
  LegalSituationTypes,
  PeriodTypes,
  ResidenceTypes,
} from '../enums';
import { FoxeetKeyValueModel } from './extra-information.model';
import { ValorationCashflowPeriodDto } from './valoration-dynamic-residual-method.model';

export interface ValorationEconomicExploitationMethodModel {
  id: number;
  assetId: number;
  clusterId: number;
  buildingElementId: number;
  totalAdoptedSurface: number;
  assetSurfaceId: AssetSurfaceIds;
  depreciationFunctionalAdoptedTotalValue: number;
  depreciationFunctionalAdoptedUnitValue: number;
  groundRepercussionAdoptedUnitValue: number;
  groundRepercussionAdoptedTotalValue: number;
  observations: string;
  isFutureValoration: boolean;
  incomesObservations: string;
  expensesObservations: string;
  buildingElementsObservations: string;
  incomes: EconomicExploitationIncomeDto[];
  expenses: EconomicExploitationExpenseDto[];
  riskFreePercent: number;
  riskPercentage: number;
  appliedPremiumPercent: number;
  appliedPremiumPercentage: number;
  ipcPercent: number;
  ipcPercentage: number;
  freeRateType: DynamicResidualFreeRateTypes;
  freeRateConstantPercent: number;
  riskPremiumPercent: number;
  externalFinancingIncreasePercent: number;
  externalFinancingIncreaseJustification: string;
  updateRatePercent: number;
  updateRatePercentage: number;
  updateMonthlyRatePercent: number;
  updateMonthlyRatePercentage: number;
  defaultStartDatetime: any;
  startDatetime: any;
  finishDatetime: any;
  assetUse: AssetUses;
  legalSituation: LegalSituationTypes;

  //Periods
  periods: ValorationCashflowPeriodDto[];
  semesterPeriods: ValorationCashflowPeriodDto[];
  annualPeriods: ValorationCashflowPeriodDto[];
  periodModificated: PeriodTypes;

  // Totals summary table
  periodsTotalNetIncome: number;
  periodsTotalNetExpense: number;
  periodsTotalIncomeExpenseDiff: number;
  totalUpdateIncome: number;
  totalUpdateExpense: number;
  totalUpdateIncomeExpenseDiff: number;

  // Totals incomes and expenses tables
  totalNetIncome: number;
  totalNetExpense: number;
  totalIncomesUpdatedValue: number;
  totalExpensesUpdatedValue: number;

  isWorkOnHold: boolean;
  costMethod_ConstructionTotalCost: number;
  costMethod_NecessaryConstructionExpensesTotalPrice: number;
  costMethod_GroundTotalPrice: number;
  incomeImputedToPropertyPercent: number;
  capitalGainPercent: number;
  reversionValue: number;
  updatedReversionValue: number;
  totalValue: number;
  updatedIncomeTotalValue: number;
  buildingConstructionYearToUse: number;
  buildingUses: BuildingUses;
  isEconomicExploitation: boolean;

  isStartDateTimeEdited: boolean;
  isFinishDateTimeEdited: boolean;
  datesObservations: string;
  exceedsMaxUsefulLife: boolean;
  maxUsefulDateTime: any;
  isIncomeImputedToPropertyPercentEdited: boolean;
  incomeImputedToPropertyObservations: string;
  isCapitalGainPercentEdited: boolean;
  capitalGainObservations: string;
  isReversionValueEdited: boolean;
  reversionValueObservations: string;
  residenceType: ResidenceTypes;
  assetTypology: AssetTypologies;
  economicExploitationType: EconomicExploitationTypes;

  periodsWithValuesImputedToProperty: ValorationCashflowPeriodDto[];
  annualPeriodsWithValuesImputedToProperty: ValorationCashflowPeriodDto[];
  semesterPeriodsWithValuesImputedToProperty: ValorationCashflowPeriodDto[];
  isMigrationValoration: boolean;
  keyValues: FoxeetKeyValueModel[];
}

export interface EconomicExploitationIncomeDto {
  id: string;
  isRecurrent: boolean;
  value: number;
  updatedValue: number;
  description: string;
  periodicity: number;
  startDatetime: any;
  finishDatetime: any;
  cashflowPercentTotalValue: number;
  cashflowUnitTotalValue: number;
  totalValue: number;
  iterator: number;
}

export interface EconomicExploitationExpenseDto {
  id: string;
  isRecurrent: boolean;
  value: number;
  description: string;
  periodicity: number;
  startDatetime: any;
  finishDatetime: any;
  cashflowPercentTotalValue: number;
  cashflowUnitTotalValue: number;
  totalValue: number;
  iterator: number;
}
